import React, { useState, useRef, useEffect, useContext } from 'react';
import { usePopper } from 'react-popper';
import { createPortal } from 'react-dom';
import { ExternalLink, FileText, CircleX, SendHorizontal, Calendar } from 'lucide-react';
import AssignModel from './AssignModel';
import { DataContext } from '../../context/DataContext';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import axios from 'axios';
import {notifyError} from '../../helpers';

const AssignDropdown = (props) => {
    const [show, setShow] = useState(false);
    const [dropdownElement, setDropdownElement] = useState(null);
    const [mode, setMode] = useState('add');
    const buttonRef = useRef(null);
    const { styles, attributes } = usePopper(buttonRef.current, dropdownElement, {
        placement: 'bottom-start',
    });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const ids = useContext(DataContext);
    
    const handleToggle = () => {
        setShow(!show);
    };
    
    const handleDocumentClick = (e) => {
        if (buttonRef.current && buttonRef.current.contains(e.target)) {
            return;
        }
        if (dropdownElement && dropdownElement.contains(e.target)) {
            return;
        }
        setShow(false);
    };

    useEffect(() => {
        if (show) {
            document.addEventListener('click', handleDocumentClick);
        } else {
            document.removeEventListener('click', handleDocumentClick);
        }
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, [show]);


    const ResendDropofCode = async() => {
        axios({
            method: 'POST',
            url: '/asset/resend-code',
            data: {asset_id : ids}, 
        })
        .then(function(response) {
            if(response.data.success === true){
                withReactContent(Swal).fire({
                    text: "Dropoff code sent successfully for selected asset.",
                    icon: "success"
                });
            }
            else{
                notifyError(response.data.message);
            }
        })
        .catch(function (error){
            notifyError('Something went wrong');
        });   
    }

    const extendExpiry = async() => {
        axios({
            method: 'POST',
            url: '/asset/extend-expiry',
            data: {asset_id : ids}, 
        })
        .then(function(response) {
            if(response.data.success === true){
                withReactContent(Swal).fire({
                    text: "Expiry Extended successfully.",
                    icon: "success"
                });
            }
        })
        .catch(function (error){
            notifyError('Something went wrong');
        });   
    }

    const changeStatus = async(type) => {

        console.log(type);

        var status = '';
        if(type === 'cancel'){
            status = 5;
        }else if(type === 'cancel_return'){
            status = 9;
        }else if(type === 'unassigned'){
            status = 2;
        }else if(type === 'return_created'){
            status = 6;
        }
        console.log(status)
        axios({
            method: 'POST',
            url: '/asset/change-asset-status',
            data: {
                asset_id : ids,
                asset_status : status
            }, 
        })
        .then(function(response) {
            var text = '';

            if(type === 'cancel'){
                text = 'Asset Cancel successfully.';
            }else if(type === 'cancel_return'){
                text = 'Return asset Cancel successfully.';
            }else if(type === 'unassigned'){
                text = 'Assets are now unassigned state';
            }else if(type === 'return_created'){
                text = 'Assets are changed to return';
            }

            if(response.data.success === true){
                withReactContent(Swal).fire({
                    text: text,
                    icon: "success"
                });

                
                props.fetchData('status',status);
                props.handleStatusChange(status)
                
            }
            else{
                notifyError(response.data.message);
            }
        })
        .catch(function (error){
            
        });   
    }

    const openModal = (type) => {
        if(type === 'add'){
            setMode('add')
        }else{
            setMode('edit');
        }
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div data-tw-merge data-tw-placement="bottom-end" className="dropdown relative">
            <button
                ref={buttonRef}
                onClick={handleToggle}
                aria-expanded={show}
                className="transition duration-200 border inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-primary border-primary text-white dark:border-primary mr-2 shadow-md"
            >
                Select
            </button>
            ({ ids.length } Selected)
            {show && createPortal(
                <div
                    ref={setDropdownElement}
                    data-transition
                    data-selector=".show"
                    data-enter="transition-all ease-linear duration-150"
                    data-enter-from="absolute !mt-5 invisible opacity-0 translate-y-1"
                    data-enter-to="!mt-1 visible opacity-100 translate-y-0"
                    data-leave="transition-all ease-linear duration-150"
                    data-leave-from="!mt-1 visible opacity-100 translate-y-0"
                    data-leave-to="absolute !mt-5 invisible opacity-0 translate-y-1"
                    className="dropdown-menu absolute z-[9999]"
                    style={styles.popper}
                    {...attributes.popper}
                >
                    <div data-tw-merge className="dropdown-content rounded-md border-transparent bg-white p-2 shadow-[0px_3px_10px_#00000017] dark:border-transparent dark:bg-darkmode-600">
                        
                        {(props.assetType === 'return_created') && (
                            <div>
                                <Link onClick={() => changeStatus('cancel_return')} className="cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md hover:bg-slate-200/60 dark:bg-darkmode-600 dark:hover:bg-darkmode-400 dropdown-item">
                                    <CircleX className="stroke-1.5 w-5 h-5 mr-2" /> Cancel Return
                                </Link>

                                <Link onClick={() => ResendDropofCode()}  className="cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md hover:bg-slate-200/60 dark:bg-darkmode-600 dark:hover:bg-darkmode-400 dropdown-item">
                                    <SendHorizontal className="stroke-1.5 w-5 h-5 mr-2" /> Resend Drop of Code
                                </Link>
                            </div>
                        )}

                        {(props.assetType === 'return_unassigned_dropped') && (
                            <div>
                                <Link onClick={() => changeStatus('unassigned')} className="cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md hover:bg-slate-200/60 dark:bg-darkmode-600 dark:hover:bg-darkmode-400 dropdown-item">
                                    <CircleX className="stroke-1.5 w-5 h-5 mr-2" /> Change to Unassigned Dropped
                                </Link>

                            </div>
                        )}

                        {(props.assetType === 'unassigned') && (
                            <div>
                                <Link onClick={() => openModal('add')}  className="cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md hover:bg-slate-200/60 dark:bg-darkmode-600 dark:hover:bg-darkmode-400 dropdown-item">
                                    <ExternalLink className="stroke-1.5 w-5 h-5 mr-2" /> Assign to Employee
                                </Link>
                                <Link onClick={() => changeStatus('return_created')} className="cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md hover:bg-slate-200/60 dark:bg-darkmode-600 dark:hover:bg-darkmode-400 dropdown-item">
                                    <FileText className="stroke-1.5 w-5 h-5 mr-2" /> Change to return
                                </Link>
                            </div>
                        )}

                        {(props.assetType === 'assigned') && (
                            <div>
                                <Link onClick={() => openModal('edit')}  className="cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md hover:bg-slate-200/60 dark:bg-darkmode-600 dark:hover:bg-darkmode-400 dropdown-item">
                                    <ExternalLink className="stroke-1.5 w-5 h-5 mr-2" /> Change the Assignee(Employee)
                                </Link>
                                <Link onClick={() => extendExpiry()} className="cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md hover:bg-slate-200/60 dark:bg-darkmode-600 dark:hover:bg-darkmode-400 dropdown-item">
                                    <Calendar className="stroke-1.5 w-5 h-5 mr-2" /> Extend Expiry
                                </Link>
                                <Link onClick={() => ResendDropofCode()} className="cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md hover:bg-slate-200/60 dark:bg-darkmode-600 dark:hover:bg-darkmode-400 dropdown-item">
                                    <SendHorizontal className="stroke-1.5 w-5 h-5 mr-2" /> Resend Pickup Code
                                </Link>
                                <Link onClick={() => changeStatus('unassigned')} className="cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md hover:bg-slate-200/60 dark:bg-darkmode-600 dark:hover:bg-darkmode-400 dropdown-item">
                                    <FileText className="stroke-1.5 w-5 h-5 mr-2" /> Unassign
                                </Link>
                            </div>
                        )}
                    </div>
                </div>,
                document.body
            )}
            

            <div >
            
                {isModalOpen && (
                    <div className="swal-with-modal-overlay">
                        <div className="swal-with-modal-modal">
                            <AssignModel 
                                show={isModalOpen} 
                                closeModal={closeModal} 
                                mode = {mode}
                            />
                        </div>
                    </div>
                )}
        
            </div>
        </div>
    );
};

export default AssignDropdown;
