import React, {useState, useEffect} from 'react'
import { Building} from 'lucide-react';
import placeholderImage from '../../assets/images/images.png';
import defaultImage from '../../assets/images/default-profile.jpg';
import axios from 'axios';
import { notifyError,notifySuccess } from '../../helpers';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';
import { selectUserRole } from '../../store';
import { usePermission } from '../../hooks/usePermission';

const Profile = () => {

    const [selectedFile, setSelectedFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(placeholderImage);
    const [organization,setOrganization] = useState(null); 
    const [newImage, setNewImage] = useState(defaultImage);
    const [userInfo, setUserInfo] = useState([]);

    const role = useSelector(selectUserRole);

    const validationSchema = Yup.object().shape({
        email: Yup.string().required('Email is required').email(),
        first_name: Yup.string().required('Admin First name is required'),
        last_name: Yup.string().required('Admin Last name is required'),
        user_name: Yup.string().required('Username is required'),
        mobile_number: Yup.string().required('Contact number is required').matches(/^[0-9]{10}$/, 'Please enter a valid 10-digit Contact Number'),
        
    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, setValue, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    const isAccess = {
        profile : {
            editOrg : usePermission('profile', 'edit-organization'),
        }
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];

        setSelectedFile(file);
        setPreviewUrl(URL.createObjectURL(file));
        setNewImage(URL.createObjectURL(file));
    };

    const onSubmit = async (data) => {
        const payload = { ...data };
        
        const formData = new FormData();
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                formData.append(key, data[key]);
            }
        }

        if (selectedFile) {
            formData.append('profile_img', selectedFile);
        }

        await axios({
            method: 'PUT',
            url: '/user/update-user/'+userInfo.id,
            data: formData,  
        })
        .then(function(response) {
            if(response.data.success === true){
                notifySuccess('Profile Data Updated Sucsessfully');
                setTimeout(()=>{
                    window.location.reload();
                  },4000)
                
            }else{
                notifyError(response.data.message);
            }
        })
        .catch(function (error){
            notifyError(error.message);
        });      
    }
    

    useEffect(() => {
        const fetchOrganisationData = async() => {
            try {
                const response = await axios.get('/organization/list-organization');
                if(response.data.success === true){
                    setOrganization(response.data.data[0]);
                    fetchProfile();
                }
                
            } catch (err) {
                notifyError(err.message);
            }
        }

        const fetchProfile = async () => {
            try {
                const response = await axios.get(`/user/get-profile-detail`);
                if (response.data.success) {
                    const fields = ['first_name','last_name','user_name','email','mobile_number','img_url'];
                    fields.forEach(field => setValue(field, response.data.result[field]));
                    setUserInfo(response.data.result);
                    if(response.data.result.img_url){
                        setPreviewUrl(response.data.result.img_url ? (response.data.result.img_url !=='null' ? response.data.result.img_url : placeholderImage  ) : placeholderImage);
                        setNewImage(response.data.result.img_url ? (response.data.result.img_url !=='null' ? response.data.result.img_url : defaultImage  ) : defaultImage);
                    }
                    
                }
            } catch (err) {
                notifyError(err.message);
            }
        };

        fetchOrganisationData();
    },[]);

    return (
       
        <div className="grid grid-cols-12 gap-6">
              
            <div className="col-span-12 flex flex-col-reverse lg:col-span-4 lg:block 2xl:col-span-4">
                <div className="intro-y box mt-5">
                    <div className="relative flex items-center p-5">
                        <div className="image-fit h-12 w-12">
                            <img className="rounded-full" src={newImage} alt="profile" />
                        </div>
                        <div className="ml-4 mr-auto">
                            <div className="text-base font-medium">
                                { userInfo.first_name +' '+ userInfo.last_name }
                            </div>
                            <div className="text-slate-500">
                                {role === 'superadmin' ? 'Super Admin' : (role === 'admin' ? 'Admin': (role === 'sde' ? 'SDE' : ''))}
                            </div>
                        </div>
                    </div>

                    {role !== 'superadmin' && (   
                    <div className="border-t border-slate-200/60 p-5 dark:border-darkmode-400">
                        <Link className="flex items-center font-medium text-primary">
                            <Building size={20} className='mr-2' />
                            Organization Information
                        </Link>

                        <div className='mt-5 grid grid-cols-12 gap-6 p-5'>
                            
                            <div className='col-span-12'>
                                <div className="preview relative [&.hide]:overflow-hidden [&.hide]:h-0">
                                    <div data-tw-merge="" className="block sm:flex items-center group form-inline">
                                        <label data-tw-merge="" htmlFor="horizontal-form-1" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 sm:w-20">
                                            Name 
                                        </label>
                                        <input id="name" type="text" value={organization ? organization.name : ''}   placeholder="Input text" disabled="disabled" className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&amp;:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"></input>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className='col-span-12'>
                                <div className="preview relative [&.hide]:overflow-hidden [&.hide]:h-0">
                                    <div data-tw-merge="" className="block sm:flex items-center group form-inline">
                                        <label data-tw-merge="" htmlFor="horizontal-form-1" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 sm:w-20">
                                            Email 
                                        </label>
                                        <input id="email" type="text" value={organization ? organization.email : ''}  placeholder="Input text" disabled="disabled" className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&amp;:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"></input>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className='col-span-12'>
                                <div className="preview relative [&.hide]:overflow-hidden [&.hide]:h-0">
                                    <div data-tw-merge="" className="block sm:flex items-center group form-inline">
                                        <label data-tw-merge="" htmlFor="horizontal-form-1" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 sm:w-20">
                                            Contact No. 
                                        </label>
                                        <input id="contact" type="text" value={organization ? organization.contact : ''}  placeholder="Input text" disabled="disabled" className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&amp;:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"></input>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className='col-span-12'>
                                <div className="preview relative [&.hide]:overflow-hidden [&.hide]:h-0">
                                    <div data-tw-merge="" className="block sm:flex items-center group form-inline">
                                        <label data-tw-merge="" htmlFor="horizontal-form-1" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 sm:w-20">
                                            City 
                                        </label>
                                        <input id="city" type="text" value={organization ? organization.city : ''}  placeholder="Input text" disabled="disabled" className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&amp;:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"></input>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        {isAccess.profile.editOrg && (
                            <div className="flex border-t border-slate-200/60 p-5 dark:border-darkmode-400">
                                <Link to={ organization? '/accounts/edit/' + organization.id : '' } type="button" className="transition duration-200 border shadow-sm inline-flex items-center justify-center rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&amp;:hover:not(:disabled)]:bg-opacity-90 [&amp;:hover:not(:disabled)]:border-opacity-90 [&amp;:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-primary border-primary text-white dark:border-primary dark:text-slate-300 [&amp;:hover:not(:disabled)]:dark:bg-darkmode-100/10 ml-auto px-2 py-1">Edit Organization Info</Link>
                            </div>
                        )}
                        
                    </div>
                    )}
                </div>
            </div>
            
            
                <div className="col-span-12 lg:col-span-8 2xl:col-span-8">
                    <div className="intro-y box lg:mt-5">
                        <div className="flex items-center border-b border-slate-200/60 p-5 dark:border-darkmode-400">
                            <h2 className="mr-auto text-base font-medium">
                                Profile Information
                            </h2>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="p-5">
                                <div className="flex flex-col xl:flex-row">
                                    <div className="mt-6 flex-1 xl:mt-0">
                                        <div className="grid grid-cols-12 gap-x-5">
                                            <div className="col-span-12 2xl:col-span-6">
                                                <div>
                                                    <label className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right">
                                                        First Name
                                                    </label>
                                                    <input id="first_name" type="text" placeholder="Input text" {...register('first_name')} className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&amp;:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10" />
                                                </div>
                                                <div className="text-danger text-right mt-2">{errors.first_name?.message}</div>
                                            </div>
                                            <div className="col-span-12 2xl:col-span-6">
                                                <div>
                                                    <label className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right">
                                                        Last Name
                                                    </label>
                                                    <input id="last_name" type="text" {...register('last_name')} placeholder="Input text" className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&amp;:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10" />
                                                </div>
                                                <div className="text-danger text-right mt-2">{errors.last_name?.message}</div>
                                            </div>
                                            <div className="col-span-12 2xl:col-span-6 mt-5">
                                                <div>
                                                    <label className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right">
                                                        Email
                                                    </label>
                                                    <input id="email" type="text" {...register('email')} placeholder="Input text" className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&amp;:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10" />
                                                </div>
                                                <div className="text-danger text-right mt-2">{errors.email?.message}</div>
                                            </div>
                                            <div className="col-span-12 2xl:col-span-6 mt-5">
                                                <div>
                                                    <label className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right">
                                                        Contact No.
                                                    </label>
                                                    <input id="mobile_number" type="text" {...register('mobile_number')} placeholder="Input text" className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&amp;:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10" />
                                                </div>
                                                <div className="text-danger text-right mt-2">{errors.mobile_number?.message}</div>
                                            </div>
                                            <div className="col-span-12 2xl:col-span-6 mt-5">
                                                <div>
                                                    <label className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right">
                                                        Username
                                                    </label>
                                                    <input id="user_name" type="text" {...register('user_name')} placeholder="Input text" className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&amp;:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10" />
                                                </div>
                                                <div className="text-danger text-right mt-2">{errors.user_name?.message}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mx-auto w-52 xl:ml-6 xl:mr-0">
                                        <div className="rounded-md border-2 border-dashed border-slate-200/60 p-5 shadow-sm dark:border-darkmode-400">
                                            <div className="image-fit zoom-in relative mx-auto h-40 cursor-pointer">
                                                <img className="rounded-md" src={previewUrl} alt="profile-info" />
                                                
                                            </div>
                                            <div className="relative mx-auto mt-5 cursor-pointer">
                                                <button type="button" className="transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&amp;:hover:not(:disabled)]:bg-opacity-90 [&amp;:hover:not(:disabled)]:border-opacity-90 [&amp;:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-primary border-primary text-white dark:border-primary w-full">Change Photo</button>
                                                <input type="file" onChange={handleFileChange} className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&amp;:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10 absolute left-0 top-0 h-full opacity-0" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" disabled={isSubmitting} className="mt-5 transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&amp;:hover:not(:disabled)]:bg-opacity-90 [&amp;:hover:not(:disabled)]:border-opacity-90 [&amp;:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-primary border-primary text-white dark:border-primary w-24">
                                    {isSubmitting && <span className="spinner-border spinner-border-sm me-1"></span>}
                                    Save
                                </button>
                            </div>
                            
                        </form>
                    </div>
                </div>
            
        </div>

        
    )
}

export default Profile
