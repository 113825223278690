import Sidebar from "./Sidebar"
import Topbar from "./Topbar"
import BodyContent from "./BodyContent"
import MobileMenu from "./MobileMenu"

import React from 'react'

function Layout() {
	return (

		<div className="rubick px-5 sm:px-8 py-5 before:content-[''] before:bg-gradient-to-b before:from-theme-1 before:to-theme-2 dark:before:from-darkmode-800 dark:before:to-darkmode-800 before:fixed before:inset-0 before:z-[-1]">
			{/* BEGIN: Mobile Menu */}
			<MobileMenu />
			<div className="mt-[4.7rem] flex md:mt-0">
				{/* BEGIN: Side Menu */}

				<Sidebar />
				<div className="md:max-w-auto min-h-screen min-w-0 max-w-full flex-1 rounded-[30px] bg-slate-100 px-4 pb-10 before:block before:h-px before:w-full before:content-[''] dark:bg-darkmode-700 md:px-[22px]">
					{/* BEGIN: Top Bar */}
					<Topbar />

					<BodyContent />
				</div>
			</div>
		</div>

	)
}

export default Layout
