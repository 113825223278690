export const formatDateTime = (date) => {

    date = new Date(date);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
};

export const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1
    const day = String(date.getDate()).padStart(2, '0'); // Pad single digit days with leading zero
    return `${year}-${month}-${day}`;
};


export const calculateTimeDifference = (lastAckTime) => {
    const now = new Date();
    const lastAckDate = new Date(lastAckTime);

    let diffInSeconds = Math.floor((now - lastAckDate) / 1000);
    const days = Math.floor(diffInSeconds / (24 * 60 * 60));
    diffInSeconds -= days * 24 * 60 * 60;
    const hours = Math.floor(diffInSeconds / 3600);
    diffInSeconds -= hours * 3600;
    const minutes = Math.floor(diffInSeconds / 60);
    const seconds = diffInSeconds % 60;

    if (days > 0) {
        return `${days} days`;
    } else if (hours > 0) {
        return `${hours} hrs`;
    } else {
        return  `${minutes} min`;
    }
};