import React, { useEffect, useState } from 'react';
import { ChevronsLeft, ChevronLeft, ChevronRight, ChevronsRight , Search, Plus, FileDown } from 'lucide-react';
import axios from 'axios';
import { notifyError, notifySuccess, notifyWarning } from '../../helpers';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Dropdown from '../../Components/common/ExportDropdown';
import { formatDateTime } from '../../utils/DateUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import MultiSearchFilter from '../../Components/common/MultiSearchFilter';
import ActionDropdown from './ActionDropDown';

function List() {

    const columns = [
        { title: "User Id", field: "emp_id", subField: "", sort: true, default: true, filter:true },
        { title: "First Name", field: "first_name", subField: "", sort: true, default: true, filter:true },
        { title: "last Name", field: "last_name", subField: "", sort: true, default: true, filter:true },
        { title: "Email", field: "email", subField: "", sort: true, default: true, filter:true },
        { title: "Contact", field: "contact", subField: "", sort: true, default: true, filter:true },
        { title: "RFID", field: "rf_id", subField: "", sort: true, default: true, filter:true },
        { title: "Created At", field: "createdAt", subField: "", sort: true, default: true, filter:false },  
    ];

    const [employeeData, setData] = useState([]);
    const [allemployeeData, setAllemployeeData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortColumn, setSortColumn] = useState('');
    const [sortOrder, setSortOrder] = useState('desc');
    const [selectedFilterColumns, setSelectedFilterColumns] = useState([]);
    const [selectedFileterValue, setSelectedFilterValue] = useState('');

    useEffect(() => {
        fetchData();
    },[itemsPerPage]);

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
        fetchData('search',event.target.value);
        
    };

    const showSwal = (id) => {
        withReactContent(Swal).fire({
            title: "Are you sure",
            text: "You want to Delete the Users",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#1E3A9E",
            confirmButtonText: "Yes",
            CancelButton: "#000"
        }).then((result) => {
            if (result.isConfirmed) {
                deleteEmployee(id)
            } 
        });
    }

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const totalPages = allemployeeData.totalPages;


    const deleteEmployee = async(id) => {
        try {
            axios({
                method: 'delete',
                url: '/employee/delete-employee/'+id
            })
            .then(function(response) {
                if(response.data.success === true){
                    notifySuccess('Users Deleted Successfully');
                    fetchData()
                }else{
                    notifyWarning(response.data.message);
                }
            })
            .catch(function (error){
                notifyError(error.message);
            });      
            
        } catch (err) {
            notifyError(err.message);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            let page = currentPage - 1;
            setCurrentPage(page);
            fetchData('pagination',page);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            let page = currentPage + 1;
            setCurrentPage(page);
            fetchData('pagination',page);
        }
    };

    const handleFirstPage = () => {
        setCurrentPage(1);
        fetchData('pagination',1);
    };

    const handleLastPage = () => {
        setCurrentPage(totalPages);
        fetchData('pagination',totalPages);
    };

    const handleItemsPerPageChange = (event) => {
        setItemsPerPage(parseInt(event.target.value));
        setCurrentPage(1);
        fetchData('pagination-page',event.target.value);
    };

    const handleSort = (column) => {
        setSortColumn(column);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        fetchData('sort',column);
    };
    
    const fetchData = async (mode,status) => {
        var url ='';
        if(mode === 'pagination'){
            url = '/employee/list-employee?page='+status+'&page_size='+itemsPerPage;
        }else{
            url = '/employee/list-employee?page='+currentPage+'&page_size='+itemsPerPage;
        }
        
        if (mode === 'status' && status !== undefined) {
            url = url + '&status=' + status;
        }
        
        if (mode === 'search') {
            url = url + '&search=' + status;
        }else{
            url = url + '&search=' + searchTerm;
        } 
        
        if (mode === 'sort'){
            url = url + '&sort_type='+sortOrder+'&sort_field='+status;
        }

        if(mode === 'filter'){
            url = url + '&'+status;
        }else{
            url = url + '&' + selectedFileterValue;
        }

        try {
            const response = await axios.get(url);
            if(response.data.success === true){
                setData(response.data.data);
                setAllemployeeData(response.data);
            }
            
        } catch (err) {
            // notifyError(err.message);
        }

    };

    const renderSortIcon = (column,sort) => {

        if(sort){
            if (sortColumn === column) {
                if(sortOrder === 'asc'){
                    return faSortUp;
                }else if(sortOrder === 'desc'){
                    return faSortDown;
                }
            }
            return faSort;
        }
    };

    useEffect(() => {
        const storedFilterColumns = JSON.parse(localStorage.getItem('filterColumns'));
        if (storedFilterColumns) {
            setSelectedFilterColumns(storedFilterColumns);
        } else {
            const defaultColumns = columns.filter(col => col.filter);
            setSelectedFilterColumns(defaultColumns);
        }
        

    }, []);


    const toUrlParams = (array) => {
        const filterString = JSON.stringify(array);

        return `filter=${encodeURIComponent(filterString)}`;
    };

    const handleApplyFilters = async (filters) => {
       
        const formattedArray = Object.entries(filters).map(([key, value]) => ({
            field: key,
            value: value
        }));
        
        const urlParams = toUrlParams(formattedArray);

        setSelectedFilterValue(urlParams);
        fetchData('filter',urlParams)

    };

    return (
        <div>
            <h2 className=" mt-10 text-lg font-medium text-left">Users</h2>
            <div className="mt-5 grid grid-cols-12 gap-6">
                <div className=" col-span-12 mt-2 flex flex-wrap items-center sm:flex-nowrap">
                    <Link to="/users/create" data-tw-merge="" className="transition duration-200 border inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-primary border-primary text-white dark:border-primary mr-2 shadow-md"><Plus size={20} className='mr-2'/>Add Users</Link>

                    
                    
                    <div className="flex mt-3 w-full sm:ml-auto sm:mt-0 sm:w-auto">
                    <Link to="/users/import" data-tw-merge="" className="transition duration-200 border inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-primary border-primary text-white dark:border-primary mr-2 shadow-md"><FileDown size={20} className='mr-2' />Import Users</Link>
                        <Dropdown  
                            data='employee'
                        />
                        <MultiSearchFilter 
                            selectedFilterColumns={selectedFilterColumns}    
                            onApplyFilters={handleApplyFilters} 
                        />
                        <div className="relative w-56 text-slate-500">
                            {/* <input data-tw-merge="" type="text" placeholder="Search..." className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10 !box w-56 pr-10" /> */}
                            <input
                                type="text"
                                placeholder="Search by name..."
                                value={searchTerm}
                                onChange={handleSearch}
                                className="transition duration-200 ease-in-out text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10 !box w-56 pr-10"
                            />
                            <Search size={15} color="#475569" className='stroke-1.5 absolute inset-y-0 right-0 my-auto mr-3 h-4 w-4'/>
                        </div>
                    </div>
                    
                </div>
                

                <div className="col-span-12 overflow-auto  2xl:overflow-visible">
                    <table data-tw-merge="" className="w-full text-left -mt-2 border-separate border-spacing-y-[10px]">
                        <thead data-tw-merge="" className="">
                            <tr data-tw-merge="" className="">
                                {
                                    columns.map((col) => (
                                        <th
                                            key={col.field}
                                            className="font-medium px-5 py-3 dark:border-darkmode-300 whitespace-nowrap border-b-0 text-uppar"
                                            onClick={() => handleSort(col.field)}
                                        >
                                            <div className='flex justify-space-between'>
                                                {col.title}
                                                {col.sort && (
                                                    <FontAwesomeIcon icon={renderSortIcon(col.field,col.sort)} className="m-2 light-gray" />
                                                )}
                                                </div>
                                        </th>
                                    ))
                                }
                                <th className="font-medium px-5 py-3 dark:border-darkmode-300 border-b-0 whitespace-nowrap text-uppar">
                                    <div className='flex justify-space-between'>Action</div>
                                </th>
                            </tr>
                        </thead>
                        <tbody className=''>
                            {employeeData.map((employee,index) =>
                                <tr data-tw-merge="" className="" key={`employee_${index}`}>
                                    
                                    <td data-tw-merge="" className="px-5 py-3 border-b dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600 capitilize">
                                        { employee.emp_id }
                                    </td>
                                    <td data-tw-merge="" className="px-5 py-3 border-b dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600 capitilize">
                                        {(employee.first_name)}
                                    </td>
                                    <td data-tw-merge="" className="px-5 py-3 border-b dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600 capitilize">
                                        {(employee.last_name)}
                                    </td>
                                    <td data-tw-merge="" className="px-5 py-3 border-b dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600">
                                        {(employee.email)}
                                        
                                    </td>
                                    <td data-tw-merge="" className="px-5 py-3 border-b dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600 capitilize">
                                        {(employee.contact)}
                                    </td>
                                    <td data-tw-merge="" className="px-5 py-3 border-b dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600 capitilize">
                                        {(employee.rf_id)}
                                    </td>
                                    
                                
                                    <td data-tw-merge="" className="px-5 py-3 border-b dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0  shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600 capitilize">
                                        {(employee.createdAt ? formatDateTime(employee.createdAt) : '-')}
                                        
                                    </td>
                                    <td data-tw-merge="" className="px-5 py-3 border-b dark:border-darkmode-300 box w-10 rounded-l-none rounded-r-none border-x-0 shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600 before:absolute before:inset-y-0 before:left-0 before:my-auto before:block before:h-8 before:w-px before:bg-slate-200 before:dark:bg-darkmode-400">
                                        <div className="flex items-center justify-center">

                                            <ActionDropdown 
                                                employee = {employee}
                                                showSwal = {showSwal}
                                            />

                                            {/* <div data-tw-merge="" data-tw-placement="bottom-end" className="dropdown relative">
                                                <button data-tw-merge="" 
                                                    className="transition duration-200"
                                                    onClick={() => toggleActionDropdown(index)} 
                                                    ref={(el) => setDropdownRef(el, index)}
                                                >
                                                <span className="flex h-5 w-5 items-center justify-center">
                                                    <Ellipsis color="#475569" />
                                                </span>
                                                </button>
                                                {actionDropdownOpen[index] && (
                                                    
                                                    <div data-transition="" data-selector=".show" data-enter="transition-all ease-linear duration-150" data-enter-from="absolute !mt-5 invisible opacity-0 translate-y-1" data-enter-to="!mt-1 visible opacity-100 translate-y-0" data-leave="transition-all ease-linear duration-150" data-leave-from="!mt-1 visible opacity-100 translate-y-0" data-leave-to="absolute !mt-5 invisible opacity-0 translate-y-1" className="dropdown-menu absolute z-[9999]">
                                                        <div data-tw-merge="" className="dropdown-content rounded-md border-transparent bg-white p-2 shadow-[0px_3px_10px_#00000017] dark:border-transparent dark:bg-darkmode-600 w-40 action-dropdown">
                                                            <Link to={'/employee/edit/'+employee.id } className="cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md hover:bg-slate-200/60 dark:bg-darkmode-600 dark:hover:bg-darkmode-400 dropdown-item">
                                                                <SquareCheckBig size={15} color="#475569" className='mr-2' /> Edit
                                                            </Link>
                                                            
        
                                                           <hr />
                                                            <Link onClick={() => showSwal(employee.id)}  className="text-danger cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md hover:bg-slate-200/60 dark:bg-darkmode-600 dark:hover:bg-darkmode-400 dropdown-item">
                                                                <Archive size={15} color="#dc2626" className='mr-2' /> Delete
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    
                                                )}
                                            </div> */}
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

                <div className=" col-span-12 flex flex-wrap items-center sm:flex-row sm:flex-nowrap">
                    <nav className="w-full sm:mr-auto sm:w-auto">
                        <ul className="flex w-full mr-0 sm:mr-auto sm:w-auto">
                            <li className="flex-1 sm:flex-initial">
                                <button
                                    onClick={handleFirstPage}
                                    disabled={currentPage === 1}
                                    className="transition duration-200 border items-center justify-center py-2 rounded-md cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed min-w-0 sm:min-w-[40px] shadow-none font-normal flex border-transparent text-slate-800 sm:mr-2 dark:text-slate-300 px-1 sm:px-3"
                                >
                                    <ChevronsLeft className="w-4 h-4" />
                                </button>
                            </li>
                            <li className="flex-1 sm:flex-initial">
                                <button
                                    onClick={handlePreviousPage}
                                    disabled={currentPage === 1}
                                    className="transition duration-200 border items-center justify-center py-2 rounded-md cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed min-w-0 sm:min-w-[40px] shadow-none font-normal flex border-transparent text-slate-800 sm:mr-2 dark:text-slate-300 px-1 sm:px-3"
                                >
                                    <ChevronLeft className="w-4 h-4" />
                                </button>
                            </li>
                            <li data-tw-merge="" className="page-item">
                                <span className="transition duration-200 border items-center justify-center py-2 rounded-md cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed min-w-0 sm:min-w-[40px] shadow-none font-normal flex border-transparent text-slate-800 sm:mr-2 dark:text-slate-300 px-1 sm:px-3">{currentPage}</span>
                            </li>
                            <li className="flex-1 sm:flex-initial">
                                <button
                                    onClick={handleNextPage}
                                    disabled={currentPage === totalPages}
                                    className="transition duration-200 border items-center justify-center py-2 rounded-md cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed min-w-0 sm:min-w-[40px] shadow-none font-normal flex border-transparent text-slate-800 sm:mr-2 dark:text-slate-300 px-1 sm:px-3"
                                >
                                    <ChevronRight className="w-4 h-4" />
                                </button>
                            </li>
                            <li className="flex-1 sm:flex-initial">
                                <button
                                    onClick={handleLastPage}
                                    disabled={currentPage === totalPages}
                                    className="transition duration-200 border items-center justify-center py-2 rounded-md cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed min-w-0 sm:min-w-[40px] shadow-none font-normal flex border-transparent text-slate-800 sm:mr-2 dark:text-slate-300 px-1 sm:px-3"
                                >
                                    <ChevronsRight className="w-4 h-4" />
                                </button>
                            </li>
                        </ul>
                    </nav>
                    <div className="text-slate-500 mr-2">
                        Showing {indexOfFirstItem + 1} to {Math.min(indexOfLastItem, employeeData.length)} of {allemployeeData.totalCount} entries
                    </div>
                    <select
                            value={itemsPerPage}
                            onChange={handleItemsPerPageChange}
                            className="disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 transition duration-200 ease-in-out text-sm border-slate-200 shadow-sm rounded-md py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 group-[.form-inline]:flex-1 !box mt-3 w-20 sm:mt-0"
                        >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                </div>

            </div>

            
        </div>
    )
}

export default List
