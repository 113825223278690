import React, { useState, useEffect } from 'react'
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { notifyError,notifySuccess } from '../../helpers';
import axios from 'axios';
import { useNavigate, useParams } from "react-router-dom";
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Loader from '../../Components/Layouts/Spinner';
import { Country, State, City } from 'country-state-city';
import Select from 'react-select';

function Create() {

    // all constants
    const navigate = useNavigate();
    const { id } = useParams(); // Get the terminal ID from the URL params
    const isEditMode = !!id; // Determine if the form is in edit mode
    
     // Validation
     const validationSchema = Yup.object().shape({
        term_name: Yup.string().required('Device id required'),
        org_id: Yup.string().required('Select Organisation'),
        city: Yup.string().required('City is required'),
        country: Yup.string().required('Country is required'),
        state: Yup.string().required('State is required'),
        address:Yup.string().required('Address is Required'),
        layout:Yup.string().required('Layout field is required'),
        // hw_version:Yup.string().required('Hardware Version field required'),
        // sw_version:Yup.string().required('Software Version field Required'),
        status:Yup.string().required('Status  field Required'),
        mac_address:Yup.string().required('Enter your Device MAC address').matches(/^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/, 'Invalid MAC address format')
        .required('MAC Address is required'),
    });


    // const formOptions = { resolver: yupResolver(validationSchema) };
    const { control, handleSubmit, register, isSubmitting, setError, watch, setValue, formState: { errors } } = useForm({
        defaultValues: {
            country: null,
            state: null,
            city: null,
        },
        resolver: yupResolver(validationSchema),
    });
    // const { register, handleSubmit, setValue, formState, setError } = useForm(formOptions);
    // const { isSubmitting } = formState;
    const [organisationOptions, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [statusDisabled, setStatusDisabled] = useState(false);
    
    // start country code
    
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);

    const selectedCountry = watch('country');
    const selectedState = watch('state');
    const [initialData, setInitialData] = useState({});

    useEffect(() => {
        const allCountries = Country.getAllCountries().map((country) => ({
            value: country.isoCode,
            label: country.name,
        }));
        setCountries(allCountries);

        if (initialData) {
            const countryData = allCountries.find(c => c.label === initialData.country);
            if (countryData) {
                setValue('country', countryData.value);
            }
        }
    }, [initialData,setValue]);

    useEffect(() => {
        if (selectedCountry) {
            const countryIsoCode = selectedCountry;
            const allStates = State.getStatesOfCountry(countryIsoCode).map((state) => ({
                value: state.isoCode,
                label: state.name,
            }));
            setStates(allStates);
            if (initialData) {
                const stateData = allStates.find(s => s.label === initialData.state);
                if (stateData) {
                    setValue('state', stateData.value);
                }
            }
            setValue('city', null);   // Reset city value when country changes
            setCities([]);            // Clear cities when country changes
        } else {
            setStates([]);
            setValue('state', null);  // Reset state value when country is cleared
            setValue('city', null);   // Reset city value when country is cleared
            setCities([]);            // Clear cities when country is cleared
        }
    }, [selectedCountry, setValue]);

    useEffect(() => {
        if (selectedState && selectedCountry) {
            const stateIsoCode = selectedState;
            const countryIsoCode = selectedCountry;
            const allCities = City.getCitiesOfState(countryIsoCode, stateIsoCode).map((city) => ({
                value: city.name,
                label: city.name,
            }));
            setCities(allCities);
            if (initialData) {

                const cityData = allCities.find(c => c.label === initialData.city);
                if (cityData) {
                    setValue('city', cityData.value);
                }
            }
        } else {
            setCities([]);
            setValue('city', null);   // Reset city value when state is cleared
        }
    }, [selectedState, selectedCountry, setValue]);

    // end country code

    const showSwal = () => {
        withReactContent(Swal).fire({
        
            title: "Are you sure",
            text: "You want to change the status",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Yes",
        }).then((result) => {
            if (result.isConfirmed) {
                setStatusDisabled(false)
            } 
        });
    }

    useEffect(() => {
        // Fectching organisation data for dropdown
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await axios.get('/organization/list-organization');
                if(response.data.success === true){
                    const formattedObject = response.data.data.map(option =>({
                        id: option.id,
                        name: option.name
                    }));
                    
                    setOptions(formattedObject);

                    // Fetch terminal data after options are set
                    if (isEditMode) {
                        await fetchTerminal();
                    }
                }
                
            } catch (err) {
                notifyError(err.message);
            }
            setLoading(false);
        };

        const fetchTerminal = async () => {
            if (isEditMode) {
                try {
                    const response = await axios.get(`/terminus/get-terminal-detail/${id}`);
                    if (response.data.success) {
                        const fields = ['term_name', 'org_id', 'city', 'state', 'address', 'layout', 'hw_version', 'sw_version', 'status','mac_address'];
                        fields.forEach(field => setValue(field, response.data.result[field]));
                       
                        setInitialData({
                            country: response.data.result['country'],  
                            state: response.data.result['state'],    
                            city: response.data.result['city'],
                        })
                    }
                } catch (err) {
                    notifyError(err.message);
                }
            }
        };

        fetchData();
        
    },[id, isEditMode, setValue]);

    useEffect(() => {
        
        isEditMode ? setStatusDisabled(true) : setStatusDisabled(false);

    },[]);

    // function onSubmit({ term_name,org_id,address,city,state,hw_version,sw_version,layout,status}) {
    //     var payload = {
    //         "term_name": term_name,
    //         "org_id": org_id,
    //         "city":city,
    //         "state": state,
    //         "hw_version":hw_version,
    //         "address":address,
    //         "status" : status,
    //         "sw_version" : sw_version,
    //         "layout":layout
    //     };
    const onSubmit = async (data) => {
        const payload = { ...data };    
        
        const selectedCountryLabel = countries.find(c => c.value === data.country)?.label;
        const selectedStateLabel = states.find(s => s.value === data.state)?.label;
        const selectedCityLabel = cities.find(c => c.value === data.city)?.label;

        payload.country = selectedCountryLabel;
        payload.state = selectedStateLabel;
        payload.city = selectedCityLabel;

        if(isEditMode){
            delete payload.mac_address;
        }

        var url = '/terminus/create-terminal';
        var method = 'post';
        if (isEditMode) {
            url = `/terminus/update-terminal-detail/${id}`;
            method = 'put';
        }

        axios({
            method: method,
            url: url,
            data: payload, 
        })
        .then(function(response) {
            if(response.data.success === true){
                if (isEditMode) {
                    notifySuccess('Smart Device Updated Sucsessfully');
                }else{
                    notifySuccess('Smart Device Created Sucsessfully');
                }
                
                navigate('/smart-device')
            }
        })
        .catch(function (error){
            error.response.data.details.forEach(innerArray => {
                innerArray.forEach(err => {
                    setError(err['field'], {
                        type: err['field'],
                        message: err['message']
                    });
                });
            });
            notifyError('Something Went wrong');
        });      
    }

  return (
    <div>   
        {loading && <Loader />}
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="preview-component intro-y box mt-5">
                <div className="flex flex-col items-center border-b border-slate-200/60 p-5 dark:border-darkmode-400 sm:flex-row">
                    <h2 className="mr-auto text-base font-medium">
                        {isEditMode ? 'Edit Device Info' : 'Add Device Info'}
                    </h2>
                </div>

                <div className="p-5" >
                    <div className='mt-5 grid grid-cols-12 gap-6'>
                        <div className='col-span-12 lg:col-span-6'>
                            <div className="preview relative [&.hide]:overflow-hidden [&.hide]:h-0">
                                <div data-tw-merge="" className="block sm:flex items-center group form-inline">
                                    <label data-tw-merge="" htmlFor="horizontal-form-1" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-20">
                                        MAC address *
                                    </label>
                                    <input id="horizontal-form-1" disabled={statusDisabled} name="mac_address" type="text" {...register('mac_address')} placeholder="Enter MAC address ex. 00:0a:95:9d:68:16" className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10" />
                                </div>
                                <div className="text-danger text-right mt-2">{errors.mac_address?.message}</div>
                            </div>
                        </div>
                        <div className='col-span-12 lg:col-span-6'>
                            <div className="preview relative [&.hide]:overflow-hidden [&.hide]:h-0">
                                <div data-tw-merge="" className="block sm:flex items-center group form-inline">
                                    <label data-tw-merge="" htmlFor="horizontal-form-1" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-20">
                                        Terminl Name *
                                    </label>
                                    <input id="horizontal-form-1" name="term_name" type="text" {...register('term_name')} placeholder="Enter Device name" className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10" />
                                </div>
                                <div className="text-danger text-right mt-2">{errors.term_name?.message}</div>
                            </div>
                        </div>
                        <div className='col-span-12 lg:col-span-6'>
                            <div className="preview relative [&.hide]:overflow-hidden [&.hide]:h-0">
                                <div data-tw-merge="" className="block sm:flex items-center group form-inline">
                                    <label data-tw-merge="" htmlFor="horizontal-form-1" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-20">
                                        Organisation*
                                    </label>
                                    <select id='org_id' name='org_id' {...register("org_id")}  aria-label="Default select example"   className="disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 group-[.form-inline]:flex-1 mt-2 sm:mr-2 mt-2 sm:mr-2">
                                        <option value="">Select an Organisation</option>
                                        {organisationOptions.map((option) => (
                                            <option key={option.id} value={option.id}>{option.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="text-danger text-right mt-2">{errors.org_id?.message}</div>
                            </div>
                        </div>
                        <div className='intro-y col-span-12 lg:col-span-6'>
                            <div className="preview relative [&.hide]:overflow-hidden [&.hide]:h-0">
                                <div data-tw-merge="" className="block sm:flex items-center group form-inline">
                                    <label data-tw-merge="" htmlFor="horizontal-form-1" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-20">
                                        Layout *
                                    </label>
                                    <select id='layout' name='layout' {...register("layout", { required: true })} data-tw-merge aria-label="Default select example" className="disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 group-[.form-inline]:flex-1 mt-2 sm:mr-2 mt-2 sm:mr-2">
                                        <option value="">Choose Layout</option>
                                        <option value="1">8 Locker</option>
                                        <option value="2">16 Locker</option>
                                    </select>
                                </div>
                                <div className="text-danger text-right mt-2">{errors.layout?.message}</div>
                            </div>
                        </div>
                        <div className='intro-y col-span-12 lg:col-span-6'>
                            <div className="preview relative [&.hide]:overflow-hidden [&.hide]:h-0">
                                <div data-tw-merge="" className="block sm:flex items-center group form-inline">
                                    <label data-tw-merge="" htmlFor="horizontal-form-1" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-20">
                                        Device Status *
                                    </label>
                                    <select id='status' disabled={statusDisabled} name='status' {...register('status')} data-tw-merge aria-label="Default select example" {...register('status')} className="disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 group-[.form-inline]:flex-1 mt-2 sm:mr-2 mt-2 sm:mr-2">
                                        <option value="">Select Status</option>
                                        <option value="1">Active</option>
                                        <option value="2">Inactive</option>
                                        <option value="3">Repair</option>
                                        <option value="4">Unassigned</option>
                                    </select>

                                    <div className=''>
                                        { statusDisabled === true ?
                                            <div className='intro-y col-span-12 lg:col-span-6'>
                                                <div className="preview relative [&.hide]:overflow-hidden [&.hide]:h-0">
                                                    <div data-tw-merge="" className="block sm:flex items-center group form-inline">
                                                        <button type='button' onClick={showSwal} className='text-primary font-medium'> Change Device Status</button>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            ''
                                        }
                                    </div>
                                </div>
                                <div className="text-danger text-right mt-2">{errors.status?.message}</div>
                            </div>
                            
                        </div>
                        
                        <div className='intro-y col-span-12 lg:col-span-6'>
                            <div className="preview relative [&.hide]:overflow-hidden [&.hide]:h-0">
                                <div data-tw-merge="" className="block sm:flex items-center group form-inline">
                                    <label data-tw-merge="" htmlFor="horizontal-form-1" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-20">
                                        Addess*
                                    </label>
                                    <textarea id="validation-form-6" name="address" placeholder="Enter Address" {...register('address')} className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&amp;:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10 form-control"></textarea>
                                </div>
                                <div className="text-danger text-right mt-2">{errors.address?.message}</div>
                            </div>
                        </div>
                        <div className='intro-y col-span-12 lg:col-span-6'>
                            <div className="preview relative [&.hide]:overflow-hidden [&.hide]:h-0">
                                <div data-tw-merge="" className="block sm:flex items-center group form-inline">
                                    <label data-tw-merge="" htmlFor="horizontal-form-1" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-20">
                                        Country*
                                    </label>
                                    <Controller
                                        name="country"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                id="country"
                                                {...field}
                                                options={countries}
                                                placeholder="Select Country"
                                                className='disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 group-[.form-inline]:flex-1 mt-2 sm:mr-2 mt-2 sm:mr-2'
                                                isSearchable={true}
                                                onChange={(option) => {
                                                    field.onChange(option ? option.value : null);
                                                    setValue('country', option ? option.value : null);
                                                }}
                                                value={countries.find(option => option.value === field.value)}
                                            />
                                        )}
                                    />
                                    
                                    {/* <input  id="horizontal-form-1" name="country" type="text" {...register('country')} placeholder="Enter Country" className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10" /> */}
                                </div>
                                <div className="text-danger text-right mt-2">{errors.country?.message}</div>
                            </div>
                        </div>
                        <div className='intro-y col-span-12 lg:col-span-6'>
                            <div className="preview relative [&.hide]:overflow-hidden [&.hide]:h-0">
                                <div data-tw-merge="" className="block sm:flex items-center group form-inline">
                                    <label data-tw-merge="" htmlFor="horizontal-form-1" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-20">
                                        State*
                                    </label>
                                    
                                    <Controller
                                        name="state"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                id="state"
                                                {...field}
                                                options={states}
                                                placeholder="Select State"
                                                className='disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 group-[.form-inline]:flex-1 mt-2 sm:mr-2 mt-2 sm:mr-2'
                                                isSearchable={true}
                                                onChange={(option) => {
                                                    field.onChange(option ? option.value : null);
                                                    setValue('state', option ? option.value : null);
                                                }}
                                                value={states.find(option => option.value === field.value)}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="text-danger text-right mt-2">{errors.state?.message}</div>
                            </div>
                        </div>
                        <div className='intro-y col-span-12 lg:col-span-6'>
                            <div className="preview relative [&.hide]:overflow-hidden [&.hide]:h-0">
                                <div data-tw-merge="" className="block sm:flex items-center group form-inline">
                                    <label data-tw-merge="" htmlFor="horizontal-form-1" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-20">
                                        City*
                                    </label>
                                    <Controller
                                        name="city"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                id="city"
                                                {...field}
                                                options={cities}
                                                placeholder="Select City"
                                                className='disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 group-[.form-inline]:flex-1 mt-2 sm:mr-2 mt-2 sm:mr-2'
                                                isSearchable={true}
                                                onChange={(option) => {
                                                    field.onChange(option ? option.value : null);
                                                    setValue('city', option ? option.value : null);
                                                }}
                                                value={cities.find(option => option.value === field.value)}
                                            />
                                        )}
                                    />
                                    
                                </div>
                                <div className="text-danger text-right mt-2">{errors.city?.message}</div>
                            </div>
                        </div>
                    </div>

                    <div className='mt-5 grid grid-cols-12 gap-6'>
                        {/* <div className='intro-y col-span-12 lg:col-span-6'>
                            <div className="preview relative [&.hide]:overflow-hidden [&.hide]:h-0">
                                <div data-tw-merge="" className="block sm:flex items-center group form-inline">
                                    <label data-tw-merge="" htmlFor="horizontal-form-1" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-20">
                                        Hardware Version *
                                    </label>
                                    <select id="hw_version" name="hw_version" {...register("hw_version", { required: true })} aria-label="Default select example" className="disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 group-[.form-inline]:flex-1 mt-2 sm:mr-2 mt-2 sm:mr-2">
                                        <option value="">Select Hardware Version</option>
                                        <option value="1.1.1">1.1.1</option>
                                    </select>
                                </div>
                                <div className="text-danger text-right mt-2">{errors.hw_version?.message}</div>
                            </div>
                        </div>
                        <div className='intro-y col-span-12 lg:col-span-6'>
                            <div className="preview relative [&.hide]:overflow-hidden [&.hide]:h-0">
                                <div data-tw-merge="" className="block sm:flex items-center group form-inline">
                                    <label data-tw-merge="" htmlFor="horizontal-form-1" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-20">
                                        Software Version *
                                    </label>
                                    <select id='sw_version' name="sw_version" {...register("sw_version", { required: true })} className="disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 group-[.form-inline]:flex-1 mt-2 sm:mr-2 mt-2 sm:mr-2">
                                        <option value="">Select Software Version</option>
                                        <option value="1.1.1">1.1.1</option>
                                    </select>
                                </div>
                                <div className="text-danger text-right mt-2">{errors.sw_version?.message}</div>
                            </div>
                        </div> */}
                        
                        
                            
                    </div>

                    <div className="mt-5 text-right">
                        <Link to="/smart-device"  type="button" className="transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&amp;:hover:not(:disabled)]:bg-opacity-90 [&amp;:hover:not(:disabled)]:border-opacity-90 [&amp;:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed border-secondary text-slate-500 dark:border-darkmode-100/40 dark:text-slate-300 [&amp;:hover:not(:disabled)]:bg-secondary/20 [&amp;:hover:not(:disabled)]:dark:bg-darkmode-100/10 mr-1 w-24 mr-2">Cancel</Link>
                        <button disabled={isSubmitting} className="transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&amp;:hover:not(:disabled)]:bg-opacity-90 [&amp;:hover:not(:disabled)]:border-opacity-90 [&amp;:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-primary border-primary text-white dark:border-primary w-24">
                        {isSubmitting && <span className="spinner-border spinner-border-sm me-1"></span>}
                        {isEditMode ? 'Update' : 'Save'}</button>
                    </div>
                </div>
            </div>
        </form>
        
    </div>
  )
}

export default Create
