import React, { Fragment, useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';
import { notifyError } from '../../helpers';
import { RefreshCcw, SquareTerminal,Building, Users, UserCog, CircleArrowRight } from 'lucide-react';
import BarChart from './BarChart';
import HorizontalBarChart from './HorizontalBarChart';
import DoughnutChart from './DoughnutChart';
import TerminalLocationData from './TerminalLocationData';
import { usePermission } from '../../hooks/usePermission';

const Dashbaord = () => {

    const [dashboardData,setDashboardData] = useState(null); 
    const [terminalGraphData, setTerminalGraphData] = useState(null); 
    const [assetGraphData, setassetGraphData] = useState(null); 
    const [terminalCities, setTerminalCities] = useState([]);

    useEffect(() => {
        const fetchDashboardData = async() => {
            try {
                const response = await axios.get('/dashboard/get-dashboard-data');
                
                if(response){
                    setDashboardData(response.data);
                    setTerminalGraphData(response.data.terminal_data.graphdata);
                    setassetGraphData(response.data.asset_data.graphdata);

                    var tempData = response.data.terminal_data.terminals;

                    tempData = tempData.map((city,index) => {
                        return city.city;
                    })

                    setTerminalCities(tempData);
                }
                
            } catch (err) {
                notifyError(err.message);
            }
        }

        fetchDashboardData();
    },[]);

    return (
        <Fragment>

            <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12">
                    <div className="grid grid-cols-12 gap-6">
                        
                        <div className="col-span-12 mt-8">
                            <div className="intro-y flex h-10 items-center">
                                <h2 className="mr-5 truncate text-lg font-medium">General Report</h2>
                                <Link className="ml-auto flex items-center text-primary">
                                    <RefreshCcw size={15} className='mr-2'/>
                                    Reload Data
                                </Link>
                            </div>
                            <div className="mt-5 grid grid-cols-12 gap-6">

                                <div className="intro-y col-span-12 sm:col-span-6 xl:col-span-3">
                                    <div className="relative zoom-in before:box before:absolute before:inset-x-3 before:mt-3 before:h-full before:bg-slate-50 before:content-['']">
                                        <div className="box p-5">
                                            <div className='flex justify-space-between'>
                                                <div>
                                                    <SquareTerminal size={75} color='#1e3b8a' />
                                                </div>
                                                <div>
                                                    <div className="mt-3 text-3xl font-medium leading-8">{ dashboardData ?dashboardData.terminal_data.totalTerminals : 0}</div>
                                                    <div className="mt-1 text-base text-slate-500">Devices</div>
                                                </div>
                                            </div>
                                            <div className='mt-3'>
                                                <hr/>
                                            </div>
                                            <div className='mt-3 text-center flex justify-center common-color'>
                                                <Link to="/smart-device">Click here</Link> <CircleArrowRight className='m-2' size={17} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="intro-y col-span-12 sm:col-span-6 xl:col-span-3">
                                    <div className="relative zoom-in before:box before:absolute before:inset-x-3 before:mt-3 before:h-full before:bg-slate-50 before:content-['']">
                                        <div className="box p-5">
                                            <div className="flex justify-space-between">
                                                <div>
                                                    <Building size={75} color='#F99B16'/>
                                                </div>
                                                <div>
                                                    <div className="mt-3 text-3xl font-medium leading-8">{ dashboardData ? dashboardData.organization_data.totalOrganization : 0}</div>
                                                    <div className="mt-1 text-base text-slate-500">Accounts</div>
                                                </div>
                                            </div>
                                            <div className='mt-3'>
                                                <hr/>
                                            </div>
                                            <div className='mt-3 text-center flex justify-center common-color'>
                                                <Link to="/accounts">Click here</Link> <CircleArrowRight className='m-2' size={17} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {usePermission('dashboard', 'employee-link') && (
                                <div className="intro-y col-span-12 sm:col-span-6 xl:col-span-3">
                                    <div className="relative zoom-in before:box before:absolute before:inset-x-3 before:mt-3 before:h-full before:bg-slate-50 before:content-['']">
                                        <div className="box p-5">
                                            <div className="flex justify-space-between">
                                                <div>
                                                    <Users size={75} color='#0D9488'/>
                                                </div>
                                                <div>
                                                    <div className="mt-6 text-3xl font-medium leading-8">{ dashboardData ? dashboardData.employee_data.totalEmployee : 0}</div>
                                                    <div className="mt-1 text-base text-slate-500">Users</div>
                                                </div>
                                            </div>
                                            
                                            <div className='mt-3'>
                                                <hr/> 
                                            </div>
                                        
                                            <div className='mt-3 text-center flex justify-center common-color'>
                                                <Link to="/users">Click here</Link> <CircleArrowRight className='m-2' size={17} />
                                            </div>
                                    
                                        </div>
                                    </div>
                                </div>
                                )}

                                {usePermission('dashboard', 'ose-link') && (
                                <div className="intro-y col-span-12 sm:col-span-6 xl:col-span-3">
                                    <div className="relative zoom-in before:box before:absolute before:inset-x-3 before:mt-3 before:h-full before:bg-slate-50 before:content-['']">
                                        <div className="box p-5">
                                            <div className="flex justify-space-between">
                                                <div>
                                                    <UserCog size={75} color='#FACC15' />
                                                </div>
                                                <div>
                                                    <div className="mt-6 text-3xl font-medium leading-8">{ dashboardData ? dashboardData.ose_data.totalOse : 0}</div>
                                                    <div className="mt-1 text-base text-slate-500">Onsite Engineers</div>
                                                </div>
                                            </div>
                                
                                            <div className='mt-3'>
                                                <hr/>
                                            </div>
                                        
                                            <div className='mt-3 text-center flex justify-center common-color'>
                                                <Link to='/onsite-engineer'>Click here</Link> <CircleArrowRight className='m-2' size={17} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                )}
                            </div>
                        </div>

                        <div className="col-span-12 lg:col-span-6">
                            <div className="intro-y box">
                                <div className='flex flex-col items-center p-5 border-b sm:flex-row border-slate-200/60 dark:border-darkmode-400'>
                                    <h2 className="mr-auto text-base font-medium">Terminal Report</h2>
                                </div>
                                <div className='p-5'> 
                                    <BarChart 
                                        graphData={terminalGraphData ? terminalGraphData : 0} 
                                        type='Terminal Data'
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-span-12 lg:col-span-6">
                            <div className="intro-y box">
                                <div className='flex flex-col items-center p-5 border-b sm:flex-row border-slate-200/60 dark:border-darkmode-400'>
                                    <h2 className="mr-auto text-base font-medium">Assets Report</h2>
                                </div>
                                <div className='p-5'> 
                                    <BarChart 
                                        graphData={assetGraphData ? assetGraphData : 0} 
                                        type='Assets Data'
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-span-12 lg:col-span-6">
                            <div className="intro-y box">
                                <div className='flex flex-col items-center p-5 border-b sm:flex-row border-slate-200/60 dark:border-darkmode-400'>
                                    <h2 className="mr-auto text-base font-medium">Assets Report</h2>
                                </div>
                                <div className='p-5'> 
                                    <DoughnutChart 
                                        graphData={assetGraphData ? assetGraphData : 0} 
                                        type='Assets Data'
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-span-12 lg:col-span-6">
                            <div className="intro-y box">
                                <div className='flex flex-col items-center p-5 border-b sm:flex-row border-slate-200/60 dark:border-darkmode-400'>
                                    <h2 className="mr-auto text-base font-medium">Terminal Location Report</h2>
                                </div>
                                <div className='p-5'> 
                                    <TerminalLocationData 
                                        terminalsData={terminalCities ? terminalCities : ''}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </Fragment>
    )
}

export default Dashbaord
