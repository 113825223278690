import { store, authActions } from '../store';
import axios from 'axios';

export const fetchWrapper = {
    get: request('GET'),
    post: request('POST'),
    put: request('PUT'),
    delete: request('DELETE')
};

function request(method) {
    return async (url, body) => {
        const requestOptions = {
            method,
            url,
            headers: authHeader(url),
            data: body ? JSON.stringify(body) : null,
        };

        if (body) {
            requestOptions.headers['Content-Type'] = 'application/json';
        }

        try {
            const response = await axios(requestOptions);
            return handleResponse(response);
        } catch (error) {
            throw error;
        }
          
    }
}

// helper functions

function authHeader(url) {
    // return auth header with jwt if user is logged in and request is to the api url
    const token = authToken();
    const isLoggedIn = !!token;
    const isApiUrl = url.startsWith(process.env.REACT_APP_API_URL);
    if (isLoggedIn && isApiUrl) {
        return { 
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`
         };
    } else {
        return {};
    }
}

function authToken() {
    return store.getState().auth.value?.token;
}

async function handleResponse(response) {
    // const isJson = response.headers?.get('content-type')?.includes('application/json');
    const data = response ? await response : null;

    // check for error response
    if (!response) {
        if ([401, 403].includes(response.status) && authToken()) {
            // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
            const logout = () => store.dispatch(authActions.logout());
            logout();
        }

        // get error message from body or default to response status
        const error = (data && data.message) || response.status;
        return Promise.reject(error);
    }
    
    return data.data;
}