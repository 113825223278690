import React from 'react'
import axios from 'axios';
import { notifyError,notifySuccess } from '../../helpers';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';

const Profile = () => {

    const validationSchema = Yup.object().shape({
        current_password: Yup.string().required('Current Password field is required'),
        new_password: Yup.string()
            .required('New Password field is required')
            .min(8, 'Password must be at least 8 characters long')
            .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
            .matches(/\d/, 'Password must contain at least one digit')
            .matches(/[@$!%*?&]/, 'Password must contain at least one symbol'),
        confirm_new_password: Yup.string().oneOf([Yup.ref('new_password'), null], 'Confirm password must match with new password field').required('Confirm new Password field is required'),
    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, setValue, formState, reset, setError } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    const handleReset = () => {
        reset();
    };

    const onSubmit = async (data) => {
        const payload = { ...data };
        
        await axios({
            method: 'PUT',
            url: 'user/change-user-password',
            data: payload,  
        })
        .then(function(response) {
            if(response.data.success === true){
                notifySuccess('Password Change Sucsessfully');
                reset();
            }else{
                notifyError(response.data.message);
            }
        })
        .catch(function (errors){
            errors.response.data.details.map(err =>{
                setError(err['field'], {
                    type: err['field'],
                    message:err['message']
                });
            })

            notifyError('Something went wrong');
        });      
    }

    return (
        <div className='mt-5 grid grid-cols-12 gap-6'>
            <div className="col-span-12 lg:col-span-7 2xl:col-span-7">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="intro-y box lg:mt-5">
                        <div className="flex items-center border-b border-slate-200/60 p-5 dark:border-darkmode-400">
                            <h2 className="mr-auto text-base font-medium">Change Password</h2>
                        </div>
                        <div className="p-5">
                            <div>
                                <div>
                                    <label className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right">
                                        Old Password
                                    </label>
                                    <input id="change-password-form-1" type="password" {...register('current_password')} placeholder="Input text" className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&amp;:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10" />
                                </div>
                                <div className="text-danger text-right mt-2">{errors.current_password?.message}</div>
                            </div>

                            <div className="mt-3">
                                <div>
                                    <label className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right">
                                        New Password
                                    </label>
                                    <input id="change-password-form-2" type="password" {...register('new_password')} placeholder="Input text" className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&amp;:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10" />
                                </div>
                                <div className="text-danger text-right mt-2">{errors.new_password?.message}</div>
                            </div>

                            <div className="mt-3">
                                <div>
                                    <label className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right">
                                        Confirm New Password
                                    </label>
                                    <input id="change-password-form-3" type="password" {...register('confirm_new_password')} placeholder="Input text" className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&amp;:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10" />
                                </div>
                                <div className="text-danger text-right mt-2">{errors.confirm_new_password?.message}</div>
                            </div>
                            <div className="mt-5 text-right">
                                <button type="button" onClick={handleReset} className="transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&amp;:hover:not(:disabled)]:bg-opacity-90 [&amp;:hover:not(:disabled)]:border-opacity-90 [&amp;:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed border-secondary text-slate-500 dark:border-darkmode-100/40 dark:text-slate-300 [&amp;:hover:not(:disabled)]:bg-secondary/20 [&amp;:hover:not(:disabled)]:dark:bg-darkmode-100/10 mr-1 w-24 mr-2">Reset</button>
                                <button type="submit" disabled={isSubmitting} className="mt-5 transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&amp;:hover:not(:disabled)]:bg-opacity-90 [&amp;:hover:not(:disabled)]:border-opacity-90 [&amp;:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-primary border-primary text-white dark:border-primary">
                                    {isSubmitting && <span className="spinner-border spinner-border-sm me-1"></span>}
                                    Change Password
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Profile
