import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router-dom';
import { notifyError } from '../../helpers';
import axios from 'axios';
import { TERMINAL_STATUS } from '../../helpers/Constants';
import { formatDateTime } from '../../utils/DateUtils';
import { Link, useLocation } from 'react-router-dom';
import {CircleArrowLeft} from 'lucide-react';

const ShowDetail = () => {
    const { id } = useParams();
    const [data, setData] = useState([]);
    var pathname = useLocation().pathname.split('/');
    pathname = pathname[1];
    
    useEffect(() => {

        const fetchOrganization = async () => {
            
            try {
                const response = await axios.get(`/terminus/get-terminal-detail/${id}`);
                if (response.data.success) {
                    setData(response.data.result);
                }
            } catch (err) {
                notifyError(err.message);
            }
            
        };

        fetchOrganization()
    },[]);

    

    return (
        <div className=" justify-center items-center min-h-screen">
            <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12 ">
                    <div className="intro-y box lg:mt-5">
                        {/* <div className="flex items-center border-b border-slate-200/60 p-5 dark:border-darkmode-400">
                            <h2 className="mr-auto text-base font-medium">
                                Detail Information of Organization
                            </h2>
                        </div> */}

                        <div className="flex items-center px-5 py-5 sm:py-3">
                            <h2 className="mr-auto text-base font-medium">Smart Device Info</h2>
                            
                            <Link to={'/smart-device'} className="transition duration-200 border shadow-sm items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&amp;:hover:not(:disabled)]:bg-opacity-90 [&amp;:hover:not(:disabled)]:border-opacity-90 [&amp;:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed border-secondary text-slate-500 dark:border-darkmode-100/40 dark:text-slate-300 [&amp;:hover:not(:disabled)]:bg-secondary/20 [&amp;:hover:not(:disabled)]:dark:bg-darkmode-100/10 hidden sm:flex">
                                <CircleArrowLeft className='w-4 h-4 mr-2' size={20} color="#475569" />
                                Back to Devices
                            </Link>
                        </div>
                        
                        <div className="p-5 text-center">
                            <div className="flex flex-col">
                                <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                                    <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                                        <div className="overflow-hidden">
                                            <table className="min-w-full text-left text-sm font-light text-surface dark:text-white">
                                                <thead
                                                    className="border-b border-neutral-200 bg-white font-medium dark:border-white/10 dark:bg-body-dark">
                                                </thead>
                                                <tbody>
                                                    <tr data-tw-merge className="[&:nth-of-type(odd)_td]:bg-slate-100 [&:nth-of-type(odd)_td]:dark:bg-darkmode-300 [&:nth-of-type(odd)_td]:dark:bg-opacity-50">
                                                        <td data-tw-merge className=" py-3 border-b dark:border-darkmode-300 p-20">
                                                            Device Name
                                                        </td>
                                                        <td data-tw-merge className=" py-3 border-b dark:border-darkmode-300 p-20">
                                                            {data.term_name ? data.term_name :''}
                                                        </td>
                                                    </tr>
                                                    <tr data-tw-merge className="[&:nth-of-type(odd)_td]:bg-slate-100 [&:nth-of-type(odd)_td]:dark:bg-darkmode-300 [&:nth-of-type(odd)_td]:dark:bg-opacity-50">
                                                        <td data-tw-merge className="px-5 py-3 border-b dark:border-darkmode-300 p-20">
                                                            Mac Address
                                                        </td>
                                                        <td data-tw-merge className="px-5 py-3 border-b dark:border-darkmode-300 p-20">
                                                            {data.mac_address}
                                                        </td>
                                                    </tr>
                                                    <tr data-tw-merge className="[&:nth-of-type(odd)_td]:bg-slate-100 [&:nth-of-type(odd)_td]:dark:bg-darkmode-300 [&:nth-of-type(odd)_td]:dark:bg-opacity-50">
                                                        <td data-tw-merge className="px-5 py-3 border-b dark:border-darkmode-300 p-20">
                                                            Reserved Number
                                                        </td>
                                                        <td data-tw-merge className="px-5 py-3 border-b dark:border-darkmode-300 p-20">
                                                            {data.reserved_number}
                                                        </td>
                                                    </tr>
                                                    {/* <tr data-tw-merge className="[&:nth-of-type(odd)_td]:bg-slate-100 [&:nth-of-type(odd)_td]:dark:bg-darkmode-300 [&:nth-of-type(odd)_td]:dark:bg-opacity-50">
                                                        <td data-tw-merge className="px-5 py-3 border-b dark:border-darkmode-300 p-20">
                                                            Software Version
                                                        </td>
                                                        <td data-tw-merge className="px-5 py-3 border-b dark:border-darkmode-300 p-20">
                                                            {data.sw_version}
                                                        </td>
                                                    </tr>
                                                    <tr data-tw-merge className="[&:nth-of-type(odd)_td]:bg-slate-100 [&:nth-of-type(odd)_td]:dark:bg-darkmode-300 [&:nth-of-type(odd)_td]:dark:bg-opacity-50">
                                                        <td data-tw-merge className="px-5 py-3 border-b dark:border-darkmode-300 p-20">
                                                            Hardware Version
                                                        </td>
                                                        <td data-tw-merge className="px-5 py-3 border-b dark:border-darkmode-300 p-20">
                                                            {data.hw_version}
                                                        </td>
                                                    </tr> */}
                                                    <tr data-tw-merge className="[&:nth-of-type(odd)_td]:bg-slate-100 [&:nth-of-type(odd)_td]:dark:bg-darkmode-300 [&:nth-of-type(odd)_td]:dark:bg-opacity-50">
                                                        <td data-tw-merge className="px-5 py-3 border-b dark:border-darkmode-300 p-20">
                                                            Organization Name
                                                        </td>
                                                        <td data-tw-merge className="px-5 py-3 border-b dark:border-darkmode-300 p-20">
                                                            {data.organization ? data.organization.name : '-'}
                                                        </td>
                                                    </tr>
                                                    <tr data-tw-merge className="[&:nth-of-type(odd)_td]:bg-slate-100 [&:nth-of-type(odd)_td]:dark:bg-darkmode-300 [&:nth-of-type(odd)_td]:dark:bg-opacity-50">
                                                        <td data-tw-merge className="px-5 py-3 border-b dark:border-darkmode-300 p-20">
                                                            Address
                                                        </td>
                                                        <td data-tw-merge className="px-5 py-3 border-b dark:border-darkmode-300 p-20">
                                                            {data.address}
                                                        </td>
                                                    </tr>
                                                    <tr data-tw-merge className="[&:nth-of-type(odd)_td]:bg-slate-100 [&:nth-of-type(odd)_td]:dark:bg-darkmode-300 [&:nth-of-type(odd)_td]:dark:bg-opacity-50">
                                                        <td data-tw-merge className="px-5 py-3 border-b dark:border-darkmode-300 p-20">
                                                            City
                                                        </td>
                                                        <td data-tw-merge className="px-5 py-3 border-b dark:border-darkmode-300 p-20">
                                                            {data.city}
                                                        </td>
                                                    </tr>
                                                    <tr data-tw-merge className="[&:nth-of-type(odd)_td]:bg-slate-100 [&:nth-of-type(odd)_td]:dark:bg-darkmode-300 [&:nth-of-type(odd)_td]:dark:bg-opacity-50">
                                                        <td data-tw-merge className="px-5 py-3 border-b dark:border-darkmode-300 p-20">
                                                            State
                                                        </td>
                                                        <td data-tw-merge className="px-5 py-3 border-b dark:border-darkmode-300 p-20">
                                                            {data.state}
                                                        </td>
                                                    </tr>
                                                    <tr data-tw-merge className="[&:nth-of-type(odd)_td]:bg-slate-100 [&:nth-of-type(odd)_td]:dark:bg-darkmode-300 [&:nth-of-type(odd)_td]:dark:bg-opacity-50">
                                                        <td data-tw-merge className="px-5 py-3 border-b dark:border-darkmode-300 p-20">
                                                            Country
                                                        </td>
                                                        <td data-tw-merge className="px-5 py-3 border-b dark:border-darkmode-300 p-20">
                                                            {data.country}
                                                        </td>
                                                    </tr>
                                                    <tr data-tw-merge className="[&:nth-of-type(odd)_td]:bg-slate-100 [&:nth-of-type(odd)_td]:dark:bg-darkmode-300 [&:nth-of-type(odd)_td]:dark:bg-opacity-50">
                                                        <td data-tw-merge className="px-5 py-3 border-b dark:border-darkmode-300 p-20">
                                                            Status
                                                        </td>
                                                        <td data-tw-merge className="px-5 py-3 border-b dark:border-darkmode-300 p-20">
                                                            { TERMINAL_STATUS[data.status] }
                                                        </td>
                                                    </tr>
                                                    <tr data-tw-merge className="[&:nth-of-type(odd)_td]:bg-slate-100 [&:nth-of-type(odd)_td]:dark:bg-darkmode-300 [&:nth-of-type(odd)_td]:dark:bg-opacity-50">
                                                        <td data-tw-merge className="px-5 py-3 border-b dark:border-darkmode-300 p-20">
                                                            Created At
                                                        </td>
                                                        <td data-tw-merge className="px-5 py-3 border-b dark:border-darkmode-300 p-20">
                                                            {(data.createdAt ? formatDateTime(data.createdAt) : '-')}
                                                        </td>
                                                    </tr>
                                                </tbody>

                                            </table>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ShowDetail
