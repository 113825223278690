import React, { useState, useEffect } from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { notifyError,notifySuccess } from '../../helpers';
import axios from 'axios';
import { useNavigate, useParams } from "react-router-dom";
import { Link } from 'react-router-dom';
import { OSE_STATUS } from '../../helpers/Constants';
import 'tom-select/dist/css/tom-select.default.css';
import MultiSelectDropdown from '../../Components/common/MultiselectDropdown';

function Create() {

    // all constants
    const navigate = useNavigate();
    const { id } = useParams(); // Get the terminal ID from the URL params
    const isEditMode = !!id; // Determine if the form is in edit mode
    
     // Validation
     const validationSchema = Yup.object().shape({
        email: Yup.string().required('Email is required').email(),
        user_name: Yup.string().required('User name is required'),
        contact: Yup.string().required('Contact number is required').matches(/^[0-9]{10}$/, 'Please enter a valid 10-digit Contact Number'),
        access_code: Yup.string().required('Access Code is required').matches(/^[0-9]{6}$/, 'Please enter a valid 6-digit Number'),
        cmp_name: Yup.string().required('Comapny name is required'),
        sites: Yup.string().required('Site is required'),
        rf_id: Yup.string().required('RFID is required'),
    });

    
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, setValue, formState, setError } = useForm(formOptions);
    const { errors, isSubmitting } = formState;
    const [terminalOptions, setTerminalOptions] = useState([]);
    const [selectedTerminal, setSelectedTerminal] = useState([]);
    const [selectedTerminalIds, setSelectedTerminalIds] = useState([]);

    useEffect(() => {
        
        const fetchTerminal = async () => {
            try {
                var response = await axios.get('/terminus/list-terminal');
                if(response.data.success === true){
                    var formattedObject = response.data.data.map(option =>({
                        value: option.id,
                        label: option.term_name
                    }));
                    
                    setTerminalOptions(formattedObject);
                    if (isEditMode) {
                        await fetchOse();
                    }

                }
            } catch (err) {
                notifyError(err.message);
            }
        };

        const fetchOse = async () => {
            if (isEditMode) {
                try {
                    const response = await axios.get(`/ose/get-onsite-engineer-detail/${id}`);
                    if (response.data.success) {
                        const fields = ['user_name', 'contact', 'email', 'access_code', 'cmp_name', 'sites', 'status','rf_id'];
                        fields.forEach(field => setValue(field, response.data.result[field]));

                        if(response.data.result.terminals){
                            let terminalData = response.data.result.terminals
                            let terminalIds = terminalData.map(item => item.terminal_id);
                            
                            setSelectedTerminalIds(terminalIds);
                        }
                        
                    }
                } catch (err) {
                    console.log(err.message);
                }
            }
        };

        fetchTerminal();
        
    },[id, isEditMode, setValue]);

    
    const onSubmit = async (data) => {
        const payload = { ...data };

        payload.terminal_ids = selectedTerminal;

        var url = '/ose/create-onsite-engineer';
        var method = 'post';
        if (isEditMode) {
            url = `/ose/update-onsite-engineer/${id}`;
            method = 'put';
        }

        axios({
            method: method,
            url: url,
            data: payload, 
        })
        .then(function(response) {
            if(response.data.success === true){
                if (isEditMode) {
                    notifySuccess('On-site Service Engineer Updated Sucsessfully');
                }else{
                    notifySuccess('On-site Service Engineer Added Sucsessfully');
                }
                
                navigate('/onsite-engineer')
            }
        })
        .catch(function (error){
            error.response.data.details.forEach(innerArray => {
                innerArray.forEach(err => {
                    setError(err['field'], {
                        type: err['field'],
                        message: err['message']
                    });
                });
            });
            
            notifyError('Something went wrong');
        });      
    }

  return (
    <div>   
         <form onSubmit={handleSubmit(onSubmit)}>
            <div className="preview-component intro-y box mt-5">
                <div className="flex flex-col items-center border-b border-slate-200/60 p-5 dark:border-darkmode-400 sm:flex-row">
                    <h2 className="mr-auto text-base font-medium">
                        {isEditMode ? 'Edit OSE Info' : 'Add OSE Info'}
                    </h2>
                </div>

                <div className="p-5" >
                <p className="text-left">Note : * Denotes manadatory information</p>
                    <br />
                    <div className='mt-5 grid grid-cols-12 gap-6'>
                        
                        <div className='col-span-12 lg:col-span-6'>
                            <div className="preview relative [&.hide]:overflow-hidden [&.hide]:h-0">
                                <div data-tw-merge="" className="block sm:flex items-center group form-inline">
                                    <label data-tw-merge="" htmlFor="horizontal-form-1" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-20">
                                        User Name *
                                    </label>
                                    <input  id="horizontal-form-1" name="user_name" type="text" {...register('user_name')} placeholder="Enter User name" className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10" />
                                </div>
                                <div className="text-danger text-right mt-2">{errors.user_name?.message}</div>
                            </div>
                        </div>

                        <div className='intro-y col-span-12 lg:col-span-6'>
                            <div className="preview relative [&.hide]:overflow-hidden [&.hide]:h-0">
                                <div data-tw-merge="" className="block sm:flex items-center group form-inline">
                                    <label data-tw-merge="" htmlFor="horizontal-form-1" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-20">
                                        Contact No.*
                                    </label>
                                   
                                    <input  id="horizontal-form-1" name='contact' type="text" {...register("contact")} placeholder="Enter Contact Number" className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10" />
                                </div>
                                <div className="text-danger text-right mt-2">{errors.contact?.message}</div>
                            </div>
                        </div>

                        <div className='intro-y col-span-12 lg:col-span-6'>
                            <div className="preview relative [&.hide]:overflow-hidden [&.hide]:h-0">
                                <div data-tw-merge="" className="block sm:flex items-center group form-inline">
                                    <label data-tw-merge="" htmlFor="horizontal-form-1" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-20">
                                        Email *
                                    </label>
                                    <input  id="horizontal-form-1" name="email" {...register('email')} type="text" placeholder="Enter email" className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10" />
                                </div>
                                <div className="text-danger text-right mt-2">{errors.email?.message}</div>
                            </div>
                        </div>
                        <div className='intro-y col-span-12 lg:col-span-6'>
                            <div className="preview relative [&.hide]:overflow-hidden [&.hide]:h-0">
                                <div data-tw-merge="" className="block sm:flex items-center group form-inline">
                                    <label data-tw-merge="" htmlFor="horizontal-form-1" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-20">
                                        Access Code *
                                    </label>
                                    <input  id="horizontal-form-1" name="access_code" type="text" {...register('access_code')} placeholder="Enter Access Code" className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10" />
                                </div>
                                <div className="text-danger text-right mt-2">{errors.access_code?.message}</div>
                            </div>
                        </div>
                        <div className='intro-y col-span-12 lg:col-span-6'>
                            <div className="preview relative [&.hide]:overflow-hidden [&.hide]:h-0">
                                <div data-tw-merge="" className="block sm:flex items-center group form-inline">
                                    <label data-tw-merge="" htmlFor="horizontal-form-1" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-20">
                                        RFID *
                                    </label>
                                    <input  id="horizontal-form-1" name="rf_id" type="text" {...register('rf_id')} placeholder="Enter RFID" className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10" />
                                </div>
                                <div className="text-danger text-right mt-2">{errors.rf_id?.message}</div>
                            </div>
                        </div>
                        <div className='col-span-12 lg:col-span-6'>
                            <div className="preview relative [&.hide]:overflow-hidden [&.hide]:h-0">
                                <div data-tw-merge="" className="block sm:flex items-center group form-inline">
                                    <label data-tw-merge="" htmlFor="horizontal-form-1" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-20">
                                        Company Name*
                                    </label>
                                    <input  id="horizontal-form-1" name="cmp_name" type="text" {...register('cmp_name')} placeholder="Enter Company Name" className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10" />
                                </div>
                                <div className="text-danger text-right mt-2">{errors.cmp_name?.message}</div>
                            </div>
                        </div>

                        <div className='col-span-12 lg:col-span-6'>
                            <div className="preview relative [&.hide]:overflow-hidden [&.hide]:h-0">
                                <div data-tw-merge="" className="block sm:flex items-center group form-inline">
                                    <label data-tw-merge="" htmlFor="horizontal-form-1" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-20">
                                        Site*
                                    </label>
                                    <input  id="horizontal-form-1" name="sites" type="text" {...register('sites')} placeholder="Enter Site" className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10" />
                                </div>
                                <div className="text-danger text-right mt-2">{errors.sites?.message}</div>
                            </div>
                        </div>
                        <div className='intro-y col-span-12 lg:col-span-6'>
                            <div className="preview relative [&.hide]:overflow-hidden [&.hide]:h-0">
                                <div data-tw-merge="" className="block sm:flex items-center group form-inline">
                                    <label data-tw-merge="" htmlFor="horizontal-form-1" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-20">
                                        Status *
                                    </label>
                                    <select id='status' name='status' {...register('status')} data-tw-merge aria-label="Default select example" {...register('status')} className="disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 group-[.form-inline]:flex-1 mt-2 sm:mr-2 mt-2 sm:mr-2">
                                        <option value="">Select Status</option>
                                        {Object.entries(OSE_STATUS).map(([key, value]) => (
                                            <option key={key} value={key}>{value}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="text-danger text-right mt-2">{errors.status?.message}</div>
                            </div>
                        </div>
                        <div className='intro-y col-span-12 lg:col-span-6'>
                            <div className="preview relative [&.hide]:overflow-hidden [&.hide]:h-0">
                                <div data-tw-merge="" className="block sm:flex items-center group form-inline">
                                    <label data-tw-merge="" htmlFor="horizontal-form-1" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-20">
                                        Terminal *
                                    </label>
                                        
                                        { terminalOptions.length > 0 && (
                                        <MultiSelectDropdown
                                            options={terminalOptions}
                                            placeholder="Select Terminals..."
                                            onChange={setSelectedTerminal}
                                            selectedValues={isEditMode ? selectedTerminalIds : []}
                                        />
                                        )}

                                

                                </div>
                                <div className="text-danger text-right mt-2">{errors.status?.message}</div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-5 text-right">
                        <Link to="/onsite-engineer"  type="button" className="transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&amp;:hover:not(:disabled)]:bg-opacity-90 [&amp;:hover:not(:disabled)]:border-opacity-90 [&amp;:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed border-secondary text-slate-500 dark:border-darkmode-100/40 dark:text-slate-300 [&amp;:hover:not(:disabled)]:bg-secondary/20 [&amp;:hover:not(:disabled)]:dark:bg-darkmode-100/10 mr-1 w-24 mr-2">Cancel</Link>
                        <button disabled={isSubmitting} className="transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&amp;:hover:not(:disabled)]:bg-opacity-90 [&amp;:hover:not(:disabled)]:border-opacity-90 [&amp;:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-primary border-primary text-white dark:border-primary w-24">
                        {isSubmitting && <span className="spinner-border spinner-border-sm me-1"></span>}
                        {isEditMode ? 'Update' : 'Save'}</button>
                    </div>
                </div>
            </div>
        </form>
        
    </div>
  )
}

export default Create
