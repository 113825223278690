import React, { useState, useEffect } from 'react'
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { notifyError,notifySuccess } from '../../helpers';
import axios from 'axios';
import { useNavigate, useParams } from "react-router-dom";
import { Link } from 'react-router-dom';
import { ASSETS_TYPE } from '../../helpers/Constants';
import Select, { components } from 'react-select';
import ModelComponenet from '../../Components/ModelComponenet';
import DynamicSearchDropdown from '../../Components/common/SearchableDropdown';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

function Create() {

    // all constants
    const navigate = useNavigate();
    const { id } = useParams(); // Get the terminal ID from the URL params
    const isEditMode = !!id; // Determine if the form is in edit mode
    const [isType, SetIsType] = useState(1);
    
     // Validation
     const validationSchema = Yup.object().shape({
        type: Yup.string().required('Type field is required'),
        asset_id: Yup.string().required('Asset Id field is required'),
        serial_number: Yup.string().required('Serial Number is required'),
        category_id: Yup.string().required('Category is required'),
        make_id: Yup.string().required('Make field is required'),
        model: Yup.string().required('Model field is required'),
        description: Yup.string().required('Description field is required'),
        
        terminal_id: Yup.string().when("type", {
            is: (type) => type === '2' || type === '3',
            then: () => Yup.string().required("Terminal field is required")
        }),

        emp_id: Yup.string().when("type", {
            is: (type) => type === '2' || type === '3',
            then: () => Yup.string().required("employee field is required")
        }),

        locker_size: Yup.string().when("type", {
            is: (type) => type === '2' || type === '3',
            then: () => Yup.string().required("Locker size field is required")
        }),
       
    });

    
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { control, register, handleSubmit, setValue, formState, setError } = useForm(formOptions);
    const { errors, isSubmitting } = formState;
    const [terminalOptions, setTerminalOptions] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [breakAssetsOptions, setBreakAssetsOptions] = useState([]);
    const [makeOptions, setMakeOptions] = useState([]);
    const [employeeData, setEmployeeData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedOption, setSelectedOption] = useState(null);
    const [lockerSize, setlockerSize] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedLockerSize, setSelectedLockerSize] = useState('');
    const [selectedEmployeeData, setSelectedEmployeeData] = useState([]);
    const [selectedBreakFix, setSelectedBreakFix] = useState([]);
    const [status,setStatus] = useState(0);
    const [brikFixDropDownStatus,setBrikFixDropDownStatus] = useState(0);

    const handleChange = (event) => {
        SetIsType(event.target.value);
    }

    const CustomOption = (props) => (
        <components.Option {...props}>
            <div>
                <div className="font-bold">{props.data.label} ({props.data.email})</div>
                
            </div>
        </components.Option>
    );

    const CustomSingleValue = (props) => {
        return (
            <components.SingleValue {...props}>
                <div>
                    <div className="font-bold">{props.data.label} ({props.data.email})</div>
                    
                </div>
            </components.SingleValue>
        );
    };


    useEffect(() => {

        const fetchTerminal = async () => {
            try {
                var response = await axios.get('/terminus/list-terminal');
                if(response.data.success === true){
                    var formattedObject = response.data.data.map(option =>({
                        id: option.id,
                        name: option.term_name
                    }));
                    
                    setTerminalOptions(formattedObject);

                }
            } catch (err) {
                notifyError(err.message);
            }
        };

        const fetchAsset = async () => {
            if (isEditMode) {
                try {
                    const response = await axios.get(`/asset/get-asset-detail/${id}`);
                    if (response.data.success) {
                        const fields = ['type', 'asset_id', 'serial_number', 'category_id', 'make_id', 'description','terminal_id', 'asset_status','locker_size','model'];
                        fields.forEach(field => setValue(field, response.data.result[field]));

                        handleTerminalChange(response.data.result.terminal_id);
                        setSelectedLockerSize(response.data.result.locker_size);
                        SetIsType(response.data.result.type);
                        setSelectedEmployeeData(response.data.result.employee);
                        setSelectedBreakFix(response.data.result.break_fix_asset);

                        if(response.data.result.break_fix_asset){
                            setBrikFixDropDownStatus(1);
                        }else{
                            setBrikFixDropDownStatus(0);
                        }   
                        

                    }
                } catch (err) {
                    notifyError(err.message);
                }
            }
        };

        const fetchCategory = async () => {
            try {
                const response = await axios.get('/asset/get-asset-category');
                if(response.data.success === true){
                    setCategoryOptions(response.data.category);
                }
                
            } catch (err) {
                notifyError(err.message);
            }
        }

        const fetchMake = async () => {
            try {
                const response = await axios.get('/asset/get-asset-make');
                if(response.data.success === true){
                    setMakeOptions(response.data.make);
                }
                
            } catch (err) {
                notifyError(err.message);
            }
        }

        const fetchEmployeeData = async() =>{
        
            try {
                const response = await axios.get('/employee/list-employee');
                if (response.data.success === true) {
                    var employeeDetails = response.data.data;
    
                    var transformedEmployeeData = employeeDetails.map(emp =>({
                        value : emp.id,
                        label : emp.first_name+' '+ emp.last_name,
                        email : emp.email,
                        phone : emp.contact
                    }));
                    setEmployeeData(transformedEmployeeData);
                }
    
            } catch (err) {
                notifyError(err.message);
            }
            
        }

        

        fetchTerminal();
        fetchCategory();
        fetchMake();
        fetchBreakAssets();
        fetchEmployeeData();
        if (isEditMode) {
            fetchAsset();
        }
        
    },[id, isEditMode, setValue, brikFixDropDownStatus]);

    const fetchBreakAssets = async () => {
        try {
            const response = await axios.get('/asset/list-asset?page_size=-1&asset_status=2');
          
            if(response.data.success === true){
                setBreakAssetsOptions(response.data.data);
            }
            
        } catch (err) {
            notifyError(err.message);
        }
    }

    const onSubmit = async (data) => {
        // const payload = { ...data };
        
        const payload = {
            ...data,
            // emp_id: data.emp_id?emp_id:, // Extract only the value property
        };

        if(status === 0){
            delete payload.break_fix_asset_id;
        }

        var url = '/asset/create-asset';
        var method = 'post';
        if (isEditMode) {
            url = `/asset/update-asset/${id}`;
            method = 'put';
        }

        axios({
            method: method,
            url: url,
            data: payload, 
        })
        .then(function(response) {
            if(response.data.success === true){
                if (isEditMode) {
                    notifySuccess('Inventory Updated Sucsessfully');
                }else{
                    notifySuccess('Inventory Added Sucsessfully');
                }
                
                navigate('/inventory')
            }else{
                notifyError(response.data.message);
            }
        })
        .catch(function (error){
            error.response.data.details.map(error =>{
                error.map(err =>{
                    setError(err['field'], {
                        type: err['field'],
                        message:err['message']
                    });
                })
            })
            
            notifyError('Something went wrong');
        });      
    }

    const handleTerminalChange = async(event) => {
       
        let term_id = '';

        if(isEditMode){
            if(event.target){
                term_id = event.target.value;
            }else{
                term_id = event;
            }
        }else{
            term_id = event.target.value;
        }

        if(term_id){
            try {
                const response = await axios.get('terminus/show-terminal-bin-availability/'+term_id);
                if(response.data.success === true){
                    setlockerSize(response.data.available_locker);
                    setSelectedOption(term_id)
                }
                
            } catch (err) {
                notifyError(err.message);
            }
        }
        
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    // Handle selected value from the dropdown
    const handleSelect = (value) => {
        setValue("emp_id", value); // Set the value for react-hook-form
    };

    const handleChangeBreak = (event) => {
        console.log(event.target.value);
        
        var selectedValue = event.target.value;
        console.log(selectedBreakFix);
        console.log(selectedValue);
        if( selectedBreakFix && (selectedBreakFix.id !== selectedValue)){
            setStatus(1);
        }else if(selectedBreakFix == null){
            setStatus(1);
        }
        else{
            setStatus(0);
        }

        setBrikFixDropDownStatus(0);
    }

    const showSwal = () => {
        withReactContent(Swal).fire({
        
            title: "Are you sure",
            text: "You want to change the Assets",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#1e3b8a",
            confirmButtonText: "Yes",
        }).then((result) => {
            if (result.isConfirmed) {
                changeStatus()
            } 
        });
    }

    const changeStatus =() => {

        axios({
            method: 'POST',
            url: '/asset/change-asset-status?type=break_fix',
            data: {
                asset_id : [selectedBreakFix.id],
                asset_status : 2,
            }, 
        })
        .then(function(response) {
            if(response.data.success === true){
                setBrikFixDropDownStatus(0);
                fetchBreakAssets()
            }
            else{
                notifyError(response.data.message);
            }
        })
        .catch(function (error){
            
        });   
    }

  return (
    <div>   
         <form onSubmit={handleSubmit(onSubmit)}>
            <div className="preview-component intro-y box mt-5">
                <div className="flex flex-col items-center border-b border-slate-200/60 p-5 dark:border-darkmode-400 sm:flex-row">
                    <h2 className="mr-auto text-base font-medium">
                        Add Inventory Info
                    </h2>
                </div>

                <div className="p-5" >
                <p className="text-left">Note : * Denotes manadatory information</p>
                    <br />
                    <div className='mt-5 grid grid-cols-12 gap-6'>

                        <div className='col-span-12 lg:col-span-8'>
                            <div className="preview relative [&.hide]:overflow-hidden [&.hide]:h-0">
                                <div data-tw-merge="" className="block sm:flex items-center group form-inline">
                                    <label data-tw-merge="" htmlFor="horizontal-form-1" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-52">
                                        Type*
                                    </label>
                                    <select id='type' name='type' {...register("type")} onChange={handleChange} aria-label="Default select example"   className="disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 group-[.form-inline]:flex-1 mt-2 sm:mr-2 mt-2 sm:mr-2">
                                        <option value="">Select an Type</option>
                                        {Object.entries(ASSETS_TYPE).map(([key, value]) => (
                                            <option key={key} value={key}>{value}</option>
                                        ))}
                                        
                                    </select>
                                </div>
                                <div className="text-danger text-right mt-2">{errors.type?.message}</div>
                            </div>
                        </div>

                        <div className='col-span-12 lg:col-span-8'>
                            <div className="preview relative [&.hide]:overflow-hidden [&.hide]:h-0">
                                <div data-tw-merge="" className="block sm:flex items-center group form-inline">
                                    <label data-tw-merge="" htmlFor="horizontal-form-1" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-52">
                                        Asset Id *
                                    </label>
                                    <input  id="horizontal-form-1" name="asset_id" type="text" {...register('asset_id')} placeholder="Enter Asset id" className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10" />
                                </div>
                                <div className="text-danger text-right mt-2">{errors.asset_id?.message}</div>
                            </div>
                        </div>

                        <div className='intro-y col-span-12 lg:col-span-8'>
                            <div className="preview relative [&.hide]:overflow-hidden [&.hide]:h-0">
                                <div data-tw-merge="" className="block sm:flex items-center group form-inline">
                                    <label data-tw-merge="" htmlFor="horizontal-form-1" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-52">
                                        Serial No.*
                                    </label>
                                   
                                    <input  id="horizontal-form-1" name='serial_number' type="text" {...register("serial_number")} placeholder="Enter Serial Number" className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10" />
                                </div>
                                <div className="text-danger text-right mt-2">{errors.serial_number?.message}</div>
                            </div>
                        </div>

                        <div className='col-span-12 lg:col-span-8'>
                            <div className="preview relative [&.hide]:overflow-hidden [&.hide]:h-0">
                                <div data-tw-merge="" className="block sm:flex items-center group form-inline">
                                    <label data-tw-merge="" htmlFor="horizontal-form-1" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-52">
                                        Category*
                                    </label>
                                    <select id='category_id' name='category_id' {...register("category_id")}  aria-label="Default select example"   className="disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 group-[.form-inline]:flex-1 mt-2 sm:mr-2 mt-2 sm:mr-2">
                                        <option value="">Select an Category</option>
                                        {categoryOptions.map((option) => (
                                            <option key={option.id} value={option.id}>{option.category_name}</option>
                                        ))}
                                       
                                    </select>
                                </div>
                                <div className="text-danger text-right mt-2">{errors.category_id?.message}</div>
                            </div>
                        </div>

                        <div className='col-span-12 lg:col-span-8'>
                            <div className="preview relative [&.hide]:overflow-hidden [&.hide]:h-0">
                                <div data-tw-merge="" className="block sm:flex items-center group form-inline">
                                    <label data-tw-merge="" htmlFor="horizontal-form-1" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-52">
                                        Make*
                                    </label>
                                    <select id='make_id' name='make_id' {...register("make_id")}  aria-label="Default select example"   className="disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 group-[.form-inline]:flex-1 mt-2 sm:mr-2 mt-2 sm:mr-2">
                                        <option value="">Select an Make</option>
                                        {makeOptions.map((option) => (
                                            <option key={option.id} value={option.id}>{option.make_name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="text-danger text-right mt-2">{errors.make_id?.message}</div>
                            </div>
                        </div>

                        <div className='intro-y col-span-12 lg:col-span-8'>
                            <div className="preview relative [&.hide]:overflow-hidden [&.hide]:h-0">
                                <div data-tw-merge="" className="block sm:flex items-center group form-inline">
                                    <label data-tw-merge="" htmlFor="horizontal-form-1" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-52">
                                        Model*
                                    </label>
                                    <input  id="horizontal-form-1" name="model" {...register('model')} type="text" placeholder="Enter Model" className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10" />
                                </div>
                                <div className="text-danger text-right mt-2">{errors.model?.message}</div>
                            </div>
                        </div>

                        <div className='intro-y col-span-12 lg:col-span-8'>
                            <div className="preview relative [&.hide]:overflow-hidden [&.hide]:h-0">
                                <div data-tw-merge="" className="block sm:flex items-center group form-inline">
                                    <label data-tw-merge="" htmlFor="horizontal-form-1" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-52">
                                        Description *
                                    </label>
                                    <input  id="horizontal-form-1" name="description" type="text" {...register('description')} placeholder="Enter Description" 
                                    className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10" />
                                </div>
                                <div className="text-danger text-right mt-2">{errors.description?.message}</div>
                            </div>
                        </div>

                        <div className='col-span-12 lg:col-span-8'>
                            <div className="preview relative [&.hide]:overflow-hidden [&.hide]:h-0">
                                <div data-tw-merge="" className="block sm:flex items-center group form-inline">
                                    <label data-tw-merge="" htmlFor="horizontal-form-1" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-52">
                                        Terminal {isType == 2 || isType == 3 ?'*':''}
                                    </label>
                                    <Controller
                                        name="terminal_id"
                                        control={control}
                                        
                                        render={({ field, fieldState }) => (
                                            <>
                                            <select
                                                {...field}
                                                className="disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 group-[.form-inline]:flex-1 mt-2 sm:mr-2 mt-2 sm:mr-2"
                                                onChange={(e) => {
                                                field.onChange(e); // Update the form state
                                                handleTerminalChange(e); // Execute any additional logic
                                                }}
                                                value={field.value} // Ensure the value is bound to the form state
                                            >
                                                <option value="">Select Terminal</option>
                                                {terminalOptions.map((option) => (
                                                <option key={option.id} value={option.id}>{option.name}</option>
                                                ))}
                                            </select>
                                            
                                            </>
                                        )}
                                    />
                                </div>
                                <div className="text-danger text-right mt-2">{errors.terminal_id?.message}</div>
                            </div>
                        </div>
                        
                        { selectedOption &&
                            <div className=''>
                                <button onClick={openModal} type='button' className='transition duration-200 border shadow-sm inline-flex items-center justify-center rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed text-xs py-1.5 px-2 bg-primary border-primary text-white dark:border-primary mt-3 mr-1 w-40'>Show Terminal View</button>
                            </div>
                        }
                        

                        <div className='col-span-12 lg:col-span-8'>
                            <div className="preview relative [&.hide]:overflow-hidden [&.hide]:h-0">
                                <div data-tw-merge="" className="block sm:flex items-center group form-inline">
                                    <label data-tw-merge="" htmlFor="horizontal-form-1" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-52">
                                        Locker Size{isType == 2 || isType == 3 ?'*':''}
                                    </label>
                            
                                    <Controller
                                        name="locker_size"
                                        control={control}
                                        
                                        render={({ field, fieldState }) => (
                                            <>
                                            <select
                                                {...field}
                                                className="disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 group-[.form-inline]:flex-1 mt-2 sm:mr-2 mt-2 sm:mr-2"
                                                onChange={(e) => {
                                                field.onChange(e); // Update the form state
                                                
                                                }}
                                                value={field.value} // Ensure the value is bound to the form state
                                            >
                                                <option value="">Select Locker Size</option>
                                                {Object.entries(lockerSize).map(([key, value]) => (
                                                <option key={key} value={key}>{key} - {value} Available</option>
                                                ))}
                                            </select>
                                            
                                            </>
                                        )}
                                    />

                                </div>
                                <div className="text-danger text-right mt-2">{errors.locker_size?.message}</div>
                            </div>
                            
                        </div>
                        
                        {(isType == 2 || isType == 3) && (
                        <div className='col-span-12 lg:col-span-8'>
                            <div className="preview relative [&.hide]:overflow-hidden [&.hide]:h-0">
                                <div data-tw-merge="" className="block sm:flex items-center group form-inline">
                                    <label data-tw-merge="" htmlFor="horizontal-form-1" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-52">
                                        Employee*
                                    </label>
                                    {/* <Controller
                                        name="emp_id"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                
                                                options={employeeData}
                                                components={{ Option: CustomOption, SingleValue: CustomSingleValue }}
                                                placeholder="Select or Search an Employee"
                                                classNamePrefix="react-select"
                                                isSearchable
                                                menuPosition="fixed"
                                                onChange={handleEmployeeSearch}
                                                
                                                className='disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md py-2 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 group-[.form-inline]:flex-1 mt-2 sm:mr-2 mt-2 sm:mr-2'
                                            />
                                        )}
                                    /> */}

                                    <DynamicSearchDropdown 
                                        onSelect={handleSelect}
                                        selectedData = {selectedEmployeeData}
                                    />

                                    
                                </div>
                                <div className="text-danger text-right mt-2">{errors.emp_id?.value?.message}</div>
                            </div>
                        </div>
                        )}
                        
                        {(isType == 3 && brikFixDropDownStatus === 0) && ( 
                            <div className='col-span-12 lg:col-span-8'>
                                <div className="preview relative [&.hide]:overflow-hidden [&.hide]:h-0">
                                    <div data-tw-merge="" className="block sm:flex items-center group form-inline">
                                        <label data-tw-merge="" htmlFor="horizontal-form-1" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-52">
                                            Break Fix Assets *
                                        </label>
                                        {/* <select id='break_fix_asset_id' 
                                            onChange={handleChangeBreak}
                                            name='break_fix_asset_id' {...register("break_fix_asset_id")}  aria-label="Default select example"   className="disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 group-[.form-inline]:flex-1 mt-2 sm:mr-2 mt-2 sm:mr-2">
                                            <option value="">Select Assets</option>
                                            {breakAssetsOptions.map((option) => (
                                                <option key={option.id} value={option.id}>{option.asset_id}</option>
                                            ))}
                                        </select> */}

                                        <Controller
                                        name="break_fix_asset_id"
                                        control={control}
                                        {...register("break_fix_asset_id")}
                                        render={({ field, fieldState }) => (
                                            <>
                                            <select
                                                {...field}
                                                className="disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 group-[.form-inline]:flex-1 mt-2 sm:mr-2 mt-2 sm:mr-2"
                                                onChange={(e) => {
                                                field.onChange(e); // Update the form state
                                                handleChangeBreak(e); // Execute any additional logic
                                                }}
                                                value={field.value} // Ensure the value is bound to the form state
                                            >
                                                <option value="">Select Asset</option>
                                                {breakAssetsOptions.map((option) => (
                                                <option key={option.id} value={option.id}>{option.asset_id}</option>
                                            ))}
                                            </select>
                                            
                                            </>
                                        )}
                                    />


                                    </div>
                                    <div className="text-danger text-right mt-2">{errors.break_fix_asset_id?.message}</div>
                                </div>
                            </div>
                        )}

                        {(isType == 3 && brikFixDropDownStatus === 1) && ( 
                            <div className='col-span-12 lg:col-span-8'>
                                <div className="preview relative [&.hide]:overflow-hidden [&.hide]:h-0">
                                    <div data-tw-merge="" className="block sm:flex items-center group form-inline">
                                        <label data-tw-merge="" htmlFor="horizontal-form-1" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-52">
                                            Break Fix Assets *
                                        </label>
                                        {/* <select id='break_fix_asset_id' name='break_fix_asset_id' {...register("break_fix_asset_id")}  aria-label="Default select example"   className="disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 group-[.form-inline]:flex-1 mt-2 sm:mr-2 mt-2 sm:mr-2">
                                            
                                            <option key={selectedBreakFix.id} value={selectedBreakFix.id}>{selectedBreakFix.asset_id}</option>
                                            
                                        </select> */}
                                        <input  id="break_fix_asset_id" name="break_fix_asset_id" type="text" disabled value={selectedBreakFix ? selectedBreakFix.asset_id : ''}  className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10" />
                                    </div>
                                    <div className="text-danger text-right mt-2">{errors.break_fix_asset_id?.message}</div>
                                </div>
                            </div>
                        )}

                        { (isType == 3 && brikFixDropDownStatus === 1) &&
                            <div className=''>
                                <button type='button' onClick={showSwal} className='text-primary font-medium w-40 mtpx-2'> Change Device Status</button>
                            </div>
                        }

                    </div>
                    <div className="mt-5 text-right">
                        <Link to="/inventory"  type="button" className="transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&amp;:hover:not(:disabled)]:bg-opacity-90 [&amp;:hover:not(:disabled)]:border-opacity-90 [&amp;:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed border-secondary text-slate-500 dark:border-darkmode-100/40 dark:text-slate-300 [&amp;:hover:not(:disabled)]:bg-secondary/20 [&amp;:hover:not(:disabled)]:dark:bg-darkmode-100/10 mr-1 w-24 mr-2">Cancel</Link>
                        <button disabled={isSubmitting} className="transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&amp;:hover:not(:disabled)]:bg-opacity-90 [&amp;:hover:not(:disabled)]:border-opacity-90 [&amp;:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-primary border-primary text-white dark:border-primary w-24">
                        {isSubmitting && <span className="spinner-border spinner-border-sm me-1"></span>}
                        {isEditMode ? 'Update' : 'Save'}</button>
                    </div>
                </div>
            </div>
        </form>
        
        <div className="text-center">

                {isModalOpen && (
                    <ModelComponenet
                        show={isModalOpen}
                        data={selectedOption}
                        closeModal={closeModal}
                    />
                )}

        </div>

    </div>
  )
}

export default Create
