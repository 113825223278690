import React from 'react';
import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { usePermission } from '../../hooks/usePermission';
import ErrorPage from './ErrorPage';

const ProtectedRoute = ({ resource, action }) => {
  const hasPermission = usePermission(resource, action);
  const location = useLocation();

  return hasPermission ? <Outlet /> : <Navigate to="/error" state={{ from: location }} />;
};

export default ProtectedRoute;