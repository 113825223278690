import React, { useState, useRef, useEffect } from 'react';
import { usePopper } from 'react-popper';
import { createPortal } from 'react-dom';
import { ExternalLink, Search, Filter } from 'lucide-react';
import axios from 'axios';

const MultiSearchFilter = ({selectedFilterColumns, onApplyFilters, fetchData}) => {
    const [show, setShow] = useState(false);
    const [dropdownElement, setDropdownElement] = useState(null);
    const buttonRef = useRef(null);
    const { styles, attributes } = usePopper(buttonRef.current, dropdownElement, {
        placement: 'bottom-end',
    });
    const [checkedState, setCheckedState] = useState({});
    const [filterValues, setFilterValues] = useState({});
    
    const handleToggle = () => {
        setShow(!show);
    };

    const handleCheckboxChange = (field) => {
        setCheckedState(prevState => ({
            ...prevState,
            [field]: !prevState[field]
        }));
    };

    const handleInputChange = (field, value) => {
        setFilterValues(prevState => ({
            ...prevState,
            [field]: value
        }));
    };

    const handleApply = () => {
        const filters = Object.keys(checkedState)
            .filter(field => checkedState[field])
            .reduce((acc, field) => {
                if (filterValues[field]) {
                    acc[field] = filterValues[field];
                }
                return acc;
            }, {});
        onApplyFilters(filters);
        setShow(false);
    };

    
    const handleDocumentClick = (e) => {
        if (buttonRef.current && buttonRef.current.contains(e.target)) {
            return;
        }
        if (dropdownElement && dropdownElement.contains(e.target)) {
            return;
        }
        setShow(false);
    };

    const close = () => {
        setCheckedState({})
        setShow(false);
        fetchData();
    }

    // useEffect(() => {
    //     if (show) {
    //         document.addEventListener('click', handleDocumentClick);
    //     } else {
    //         document.removeEventListener('click', handleDocumentClick);
    //     }
    //     return () => {
    //         document.removeEventListener('click', handleDocumentClick);
    //     };
    // }, [show]);


    
    return (
        <div data-tw-merge data-tw-placement="bottom-end" className="dropdown relative">
            <button
                ref={buttonRef}
                onClick={handleToggle}
                aria-expanded={show}
                className="transition duration-200 border inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed text-primary border-primary text-white dark:border-primary mr-2 shadow-md"
            >
                <Filter className="stroke-1.5 w-5 h-5 mr-2" /> Filters
            </button>
            {show && createPortal(
                <div
                    ref={setDropdownElement}
                    data-transition
                    data-selector=".show"
                    data-enter="transition-all ease-linear duration-150"
                    data-enter-from="absolute !mt-5 invisible opacity-0 translate-y-1"
                    data-enter-to="!mt-1 visible opacity-100 translate-y-0"
                    data-leave="transition-all ease-linear duration-150"
                    data-leave-from="!mt-1 visible opacity-100 translate-y-0"
                    data-leave-to="absolute !mt-5 invisible opacity-0 translate-y-1"
                    className="dropdown-menu absolute z-[9999]"
                    style={styles.popper}
                    {...attributes.popper}
                >
                    <div data-tw-merge className="dropdown-content rounded-md border-transparent bg-white p-2 shadow-[0px_3px_10px_#00000017] dark:border-transparent dark:bg-darkmode-600 w-45-vh">

                        <div>
                            <div className="w-full sm:w-auto mt-3">
                                <div className="columnFilter">
                                    {selectedFilterColumns.map((col) => (
                                    <div key={col.field} >
                                        <div className="items-center">
                                            <div className='flex items-center mt-2 p-1'>
                                                <input 
                                                    id={'checkbox-switch-'+col.field}
                                                    type="checkbox" 
                                                    checked={checkedState[col.field] || false}
                                                    onChange={() => handleCheckboxChange(col.field)}
                                                    className="transition-all duration-100 ease-in-out shadow-sm border-slate-200 cursor-pointer rounded focus:ring-4 focus:ring-offset-0 focus:ring-primary focus:ring-opacity-20 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&amp;[type='radio']]:checked:bg-primary [&amp;[type='radio']]:checked:border-primary [&amp;[type='radio']]:checked:border-opacity-10 [&amp;[type='checkbox']]:checked:bg-primary [&amp;[type='checkbox']]:checked:border-primary [&amp;[type='checkbox']]:checked:border-opacity-10 [&amp;:disabled:not(:checked)]:bg-slate-100 [&amp;:disabled:not(:checked)]:cursor-not-allowed [&amp;:disabled:not(:checked)]:dark:bg-darkmode-800/50 [&amp;:disabled:checked]:opacity-70 [&amp;:disabled:checked]:cursor-not-allowed [&amp;:disabled:checked]:dark:bg-darkmode-800/50" value="" />

                                                <label htmlFor={'checkbox-switch-'+col.field} className="cursor-pointer ml-2">
                                                    {col.title}
                                                </label>
                                            </div>
                                            {checkedState[col.field] && (
                                                <div className="relative w-56 text-slate-500 p-4 ml-25">
                                                    <input
                                                        type="text"
                                                        placeholder="Search"
                                                        onChange={(e) => handleInputChange(col.field, e.target.value)}
                                                        className="transition duration-200 ease-in-out text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10 !box w-56 pr-10"
                                                    />
                                                    <Search size={15} color="#475569" className='stroke-1.5 absolute inset-y-0 right-0 my-auto mr-3 h-4 w-4' />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    ))}
                                </div>

                            <hr />
                            <div className="mt-5 text-right">
                                <button onClick={() => close()} type="button" className="transition duration-200 border shadow-sm inline-flex items-center justify-center rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed text-xs py-1.5 px-2 bg-secondary/70 border-secondary/70 text-slate-500 dark:border-darkmode-400 dark:bg-darkmode-400 dark:text-slate-300 [&:hover:not(:disabled)]:bg-slate-100 [&:hover:not(:disabled)]:border-slate-100 [&:hover:not(:disabled)]:dark:border-darkmode-300/80 [&:hover:not(:disabled)]:dark:bg-darkmode-300/80 w-24 mb-2 mr-2">
                                    Close
                                </button>
                                <button onClick={() => handleApply()} className="transition duration-200 border shadow-sm inline-flex items-center justify-center rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed text-xs py-1.5 px-2 bg-primary border-primary text-white dark:border-primary w-24 mb-2 mr-1">
                                    Apply
                                </button>
                            </div>

                            </div>
                            
                        </div>
                    </div>
                </div>,
                document.body
            )}
            
        </div>
    );
};

export default MultiSearchFilter;
