import React, { useEffect, useState, useCallback } from 'react';
import { ChevronsLeft, ChevronLeft, ChevronRight, ChevronsRight ,SquareCheckBig, Search, Plus, } from 'lucide-react';
import axios from 'axios';
import { notifyError } from '../../helpers';
import { Link, useLocation } from 'react-router-dom';
import Dropdown from '../../Components/common/ExportDropdown';
import { formatDateTime } from '../../utils/DateUtils';
import { useSelector } from 'react-redux';
import {selectOrgId} from '../../store';
import { usePermission } from '../../hooks/usePermission';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import MultiSearchFilter from '../../Components/common/MultiSearchFilter';
import ActionDropdown from './ActionDropDown';

function List() {
    var pathname = useLocation().pathname.split('/');
    pathname = pathname[1];

    const org_id = useSelector(selectOrgId);

    const columns = [
        { title: "Name", field: "name", subField: "", sort: true, default: true, filter:true },
        { title: "Email", field: "email", subField: "", sort: true, default: true, filter:true },
        { title: "Contact", field: "contact", subField: "", sort: true, default: true, filter:true },
        { title: "Status", field: "status", subField: "", sort: true, default: true, filter:false },
        { title: "Country", field: "country", subField: "", sort: true, default: true, filter:true },
        { title: "State", field: "state", subField: "", sort: true, default: true, filter:true },
        { title: "City", field: "city", subField: "", sort: true, default: false, filter:true },
        { title: "Created At", field: "createdAt", subField: "", sort: true, default: true, filter:false },  
    ];

    const organisationTabStatus = {
        'all' : 'All',
        'active' : 'Active',
        'inactive' : 'Inactive',
    };

    const isAccess = {
        suborganisation : {
            list : usePermission('subOrganization', 'list'),
            add : usePermission('subOrganization','add'),
            edit : usePermission('subOrganization','edit'),
        },
        organization :{
            add : usePermission('organization','add'),
            edit : usePermission('organization','edit'),
            listAdmin : usePermission('organization','list-admin')
        }
    }

    const [organizationData, setData] = useState([]);
    const [allOrganizationData, setallOrganizationData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortColumn, setSortColumn] = useState('');
    const [sortOrder, setSortOrder] = useState('desc');
    const [selectedFilterColumns, setSelectedFilterColumns] = useState([]);
    const [statusValue, setStatusValue] = useState('');
    const [selectedFileterValue, setSelectedFilterValue] = useState('');

    useEffect(() => {
        fetchData();
    },[pathname]);

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
        fetchData('search',event.target.value);
        
    };
    
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const totalPages = allOrganizationData.totalPages;

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            let page = currentPage - 1;
            setCurrentPage(page);
            fetchData('pagination',page);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            let page = currentPage + 1;
            setCurrentPage(page);
            fetchData('pagination',page);
        }
    };

    const handleFirstPage = () => {
        setCurrentPage(1);
        fetchData('pagination',1);
    };

    const handleLastPage = () => {
        setCurrentPage(totalPages);
        fetchData('pagination',totalPages);
    };

    const handleItemsPerPageChange = (event) => {
        setItemsPerPage(parseInt(event.target.value));
        setCurrentPage(1);
        fetchData('pagination-page',event.target.value);
    };
    
    const handleSort = (column) => {
        setSortColumn(column);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        fetchData('sort',column);
    };

    const fetchData = useCallback(async (mode, status) => {
        var url = '';
        if(pathname === 'sub-accounts'){
            
            if(mode === 'pagination'){
                url = '/organization/list-organization?org_id='+org_id+'&page='+status+'&page_size='+itemsPerPage;
            }else{
                url = '/organization/list-organization?org_id='+org_id+'&page='+currentPage+'&page_size='+itemsPerPage;
            }
            
            if (mode === 'status' && status !== undefined) {
                url = url + '&status=' + status;
            }
            if (mode === 'search') {
                url = url + '&search=' + status;
            }else{
                url = url + '&search=' + searchTerm;
            } 
            
            if (mode === 'sort'){
                url = url + '&sort_type='+sortOrder+'&sort_field='+status;
            }

            if(mode === 'filter'){
                url = url + '&'+status;
            }else{
                url = url + '&' + selectedFileterValue;
            }
    
            
        }else{
        
            if(mode === 'pagination'){
                url = '/organization/list-organization?page='+status+'&page_size='+itemsPerPage;
            }else{
                url = '/organization/list-organization?page='+currentPage+'&page_size='+itemsPerPage;
            }

            if (mode === 'status' && status !== undefined) {
                url = url + '&status=' + status;
            } else{
                url = url + '&status=' + statusValue;
            }
            if (mode === 'search') {
                url = url + '&search=' + status;
            } else{
                url = url + '&search=' + searchTerm;
            }
            
            if (mode === 'sort'){
                url = url + '&sort_type='+sortOrder+'&sort_field='+status;
            }

            if(mode === 'filter'){
                url = url + '&'+status;
            }else{
                url = url + '&' + selectedFileterValue;
            }
    
        }

        try {
            const response = await axios.get(url);
            if(response.data.success === true){
                
                setData(response.data.data);
                setallOrganizationData(response.data);
            }
            
        } catch (err) {
            notifyError(err.message);
        }
    }, [itemsPerPage, sortOrder, currentPage,selectedFileterValue, org_id, pathname, searchTerm, statusValue]);

    const renderSortIcon = (column,sort) => {

        if(sort){
            if (sortColumn === column) {
                if(sortOrder === 'asc'){
                    return faSortUp;
                }else if(sortOrder === 'desc'){
                    return faSortDown;
                }
            }
            return faSort;
        }
    };

    useEffect(() => {
        const storedFilterColumns = JSON.parse(localStorage.getItem('filterColumns'));
        if (storedFilterColumns) {
            setSelectedFilterColumns(storedFilterColumns);
        } else {
            const defaultColumns = columns.filter(col => col.filter);
            setSelectedFilterColumns(defaultColumns);
        }
        

    }, []);

    const toUrlParams = (array) => {
        const filterString = JSON.stringify(array);

        if(pathname === 'sub-accounts'){
            return `&filter=${encodeURIComponent(filterString)}`;
        }else{
            return `filter=${encodeURIComponent(filterString)}`;
        }
    };

    const handleApplyFilters = async (filters) => {
       
        const formattedArray = Object.entries(filters).map(([key, value]) => ({
            field: key,
            value: value
        }));
        
        const urlParams = toUrlParams(formattedArray);

        setSelectedFilterValue(urlParams);
        fetchData('filter',urlParams)
    };
    
    const handleStatusChange = (event) => {
        setCurrentPage(1);
        var status = '';
        let tab = event.target.value;

        if(tab === 'all'){
            status = '';
            fetchData();
        }else{
            if(tab === 'active'){
                status = 1;
            }else if(tab === 'inactive'){
                status = 2;
            }
        }
        setStatusValue(status);
        fetchData('status',status);
    }

    return (
        <div>
            <h2 className=" mt-10 text-lg font-medium text-left">{( pathname === 'sub-accounts'? 'Sub Accounts' : 'Accounts' )} </h2>
            <div className="mt-5 grid grid-cols-12 gap-6">
                <div className=" col-span-12 mt-2 flex flex-wrap items-center sm:flex-nowrap">
                    

                    { pathname === 'sub-accounts' && isAccess.suborganisation.add 
                        ? (<Link to="/sub-accounts/create" data-tw-merge="" className="transition duration-200 border inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-primary border-primary text-white dark:border-primary mr-2 shadow-md"><Plus size={20} className='mr-2'/>Add Sub Accounts</Link>) 
                        : (isAccess.organization.add) 
                            ? (<Link to="/accounts/create" data-tw-merge="" className="transition duration-200 border inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-primary border-primary text-white dark:border-primary mr-2 shadow-md"><Plus size={20} className='mr-2'/>Add Accounts</Link>) 
                            : ''
                    }

                    <div data-tw-merge data-tw-placement="bottom-end" className="dropdown relative">
                        <select 
                            onChange={ handleStatusChange}
                            className="disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 ml-2 !box">
                            {Object.entries(organisationTabStatus).map(([key, value]) => (
                                <option key={key} value={key}>{value}</option>
                                ))}
                        </select>
                    </div>
                    
                    
                    <div className="flex mt-3 w-full sm:ml-auto sm:mt-0 sm:w-auto">
                        <Dropdown  
                            data={pathname === 'sub-accounts'? 'sub-organization' : 'organization'}
                            org_id={org_id}
                            
                        />

                        <MultiSearchFilter 
                            selectedFilterColumns={selectedFilterColumns}    
                            onApplyFilters={handleApplyFilters} 
                            fetchData={fetchData}
                        />
                        <div className="relative w-56 text-slate-500">
                            {/* <input data-tw-merge="" type="text" placeholder="Search..." className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10 !box w-56 pr-10" /> */}
                            <input
                                type="text"
                                placeholder="Search by name..."
                                value={searchTerm}
                                onChange={handleSearch}
                                className="transition duration-200 ease-in-out text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10 !box w-56 pr-10"
                            />
                            <Search size={15} color="#475569" className='stroke-1.5 absolute inset-y-0 right-0 my-auto mr-3 h-4 w-4'/>
                        </div>
                    </div>
                </div>

                {/* <div className=" col-span-12 lg:col-span-6">
                    <ul data-tw-merge role="tablist" className="w-full flex">
                        {Object.entries(organisationTabStatus).map(([key, value]) => (
                            <li id="example-5-tab" key={key} data-tw-merge role="presentation" className="focus-visible:outline-none flex-1">
                                <button onClick={() => handleTabChange(key)} role="tab" className={` ${activeTab === key ? 'active' :'' } cursor-pointer block appearance-none px-5 py-2.5 border border-transparent text-slate-700 dark:text-slate-400 [&.active]:text-slate-800 [&.active]:dark:text-white border-b-2 border-transparent dark:border-transparent [&.active]:border-b-primary [&.active]:font-medium [&.active]:dark:border-b-primary  w-full py-2`}>{ value }</button>
                            </li>
                        ))}
                    </ul>
                </div> */}

                <div className="col-span-12 overflow-auto ">
                    <table data-tw-merge="" className="w-full text-left -mt-2 border-separate border-spacing-y-[10px]">
                        <thead data-tw-merge="" className="">
                            <tr data-tw-merge="" className="">
                                {
                                    columns.map((col) => (
                                        <th
                                            key={col.field}
                                            className="font-medium px-5 py-3 dark:border-darkmode-300 whitespace-nowrap border-b-0 text-uppar"
                                            onClick={() => handleSort(col.field)}
                                        >
                                            <div className='flex justify-space-between'>
                                                {col.title}
                                                {col.sort && (
                                                    <FontAwesomeIcon icon={renderSortIcon(col.field,col.sort)} className="m-2 light-gray" />
                                                )}
                                                </div>
                                        </th>
                                    ))
                                }
                                <th className="font-medium px-5 py-3 dark:border-darkmode-300 border-b-0 whitespace-nowrap text-uppar">
                                    <div className='flex justify-space-between'>Action</div>
                                </th>
                            </tr>
                        </thead>
                        <tbody className=''>
                            {organizationData.map((organisation,index) =>
                                <tr data-tw-merge="" className="" key={`organisation_${index}`}>
                                    
                                    
                                    <td data-tw-merge="" className="px-5 py-3 border-b dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600">
                                        {organisation.name}
                                    </td>
                                    <td data-tw-merge="" className="px-5 py-3 border-b dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600">
                                        {(organisation.email)}
                                    </td>
                                    <td data-tw-merge="" className="px-5 py-3 border-b dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600 capitilize">
                                        {(organisation.contact)}
                                        
                                    </td>

                                    <td data-tw-merge="" className="px-5 py-3 border-b dark:border-darkmode-300 box w-40 rounded-l-none rounded-r-none border-x-0 shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600 capitilize">
                                    
                                        { organisation.status === 1 && 
                                        <div className="flex items-center text-success">
                                            <SquareCheckBig size={15} color="#0d9488" className='mr-2' />Active
                                        </div>
                                        }

                                        { organisation.status === 2 && 
                                        <div className="flex items-center text-danger">
                                            <SquareCheckBig size={15} color="#dc2626" className='mr-2'/>
                                            Inactive
                                        </div>
                                        }
                                        
                                    </td>
                                    
                                    <td data-tw-merge="" className="px-5 py-3 border-b dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600 capitilize">
                                        {(organisation.country)}
                                    </td>

                                    <td data-tw-merge="" className="px-5 py-3 border-b dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600 capitilize">
                                        {(organisation.state)}
                                    </td>

                                    <td data-tw-merge="" className="px-5 py-3 border-b dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600 capitilize">
                                        {(organisation.city)}
                                    </td>
                                    
                                    <td data-tw-merge="" className="px-5 py-3 border-b dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0  shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600 capitilize">
                                        {(organisation.createdAt ? formatDateTime(organisation.createdAt) : '-')}
                                        
                                    </td>
                                    <td data-tw-merge="" className="px-5 py-3 border-b dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600 before:absolute before:inset-y-0 before:left-0 before:my-auto before:block before:h-8 before:w-px before:bg-slate-200 before:dark:bg-darkmode-400 w-10">
                                        <div className="flex items-center justify-center">

                                        <ActionDropdown 
                                            organisation ={organisation}
                                            isAccess = {isAccess}
                                            pathname={pathname}
                                        />

                                            {/* <div data-tw-merge="" data-tw-placement="bottom-end" className="dropdown relative">
                                                <button data-tw-merge="" 
                                                    className="transition duration-200"
                                                    onClick={() => toggleActionDropdown(index)} 
                                                    ref={(el) => setDropdownRef(el, index)}
                                                >
                                                <span className="flex h-5 w-5 items-center justify-center">
                                                    <Ellipsis color="#475569" />
                                                </span>
                                                </button>
                                                {actionDropdownOpen[index] && (
                                                    <div data-transition="" data-selector=".show" data-enter="transition-all ease-linear duration-150" data-enter-from="absolute !mt-5 invisible opacity-0 translate-y-1" data-enter-to="!mt-1 visible opacity-100 translate-y-0" data-leave="transition-all ease-linear duration-150" data-leave-from="!mt-1 visible opacity-100 translate-y-0" data-leave-to="absolute !mt-5 invisible opacity-0 translate-y-1" className="dropdown-menu absolute z-[9999]">

                                                            <ActionDropdown 
                                                                organisation ={organisation}
                                                                isAccess = {isAccess}
                                                                pathname={pathname}
                                                            />
                                                        
                                                        <div data-tw-merge="" className="dropdown-content rounded-md border-transparent bg-white p-2 shadow-[0px_3px_10px_#00000017] dark:border-transparent dark:bg-darkmode-600 w-40 action-dropdown" ref={(el) => setDropdownContentRef(el, index)}>
                                                            { pathname == 'sub-organization' && isAccess.suborganisation.edit 
                                                                ? (<Link to={'/sub-organization/edit/'+organisation.id } className="cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md hover:bg-slate-200/60 dark:bg-darkmode-600 dark:hover:bg-darkmode-400 dropdown-item">
                                                                        <SquareCheckBig size={15} color="#475569" className='mr-2' /> Edit
                                                                    </Link>
                                                                ) 
                                                                : (isAccess.organization.edit) 
                                                                    ? (<Link to={'/organization/edit/'+organisation.id } className="cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md hover:bg-slate-200/60 dark:bg-darkmode-600 dark:hover:bg-darkmode-400 dropdown-item">
                                                                            <SquareCheckBig size={15} color="#475569" className='mr-2' /> Edit
                                                                        </Link>
                                                                    ) 
                                                                : ''
                                                            }
                                                            
                                                            <Link to={pathname == 'organization' ?'/organization/show/'+ organisation.id : '/sub-organization/show/'+ organisation.id } className="cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md hover:bg-slate-200/60 dark:bg-darkmode-600 dark:hover:bg-darkmode-400 dropdown-item">
                                                                <Eye size={15} color="#475569" className='mr-2' /> Show
                                                            </Link>

                                                            {(pathname == 'organization' && isAccess.organization.listAdmin) && (
                                                                <Link to={'/organization/'+ organisation.id +'/admin'} className="cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md hover:bg-slate-200/60 dark:bg-darkmode-600 dark:hover:bg-darkmode-400 dropdown-item">
                                                                    <CircleUserRound size={15} color="#475569" className='mr-2' /> Admin
                                                                </Link>
                                                            )}

                                                            {isAccess.suborganisation.list && (
                                                                <Link to={'/sub-organization/'+ organisation.id +'/sde'} className="cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md hover:bg-slate-200/60 dark:bg-darkmode-600 dark:hover:bg-darkmode-400 dropdown-item">
                                                                    <User size={15} color="#475569" className='mr-2' /> SDE
                                                                </Link>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                            </div> */}
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

                <div className=" col-span-12 flex flex-wrap items-center sm:flex-row sm:flex-nowrap">
                    <nav className="w-full sm:mr-auto sm:w-auto">
                        <ul className="flex w-full mr-0 sm:mr-auto sm:w-auto">
                            <li className="flex-1 sm:flex-initial">
                                <button
                                    onClick={handleFirstPage}
                                    disabled={currentPage === 1}
                                    className="transition duration-200 border items-center justify-center py-2 rounded-md cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed min-w-0 sm:min-w-[40px] shadow-none font-normal flex border-transparent text-slate-800 sm:mr-2 dark:text-slate-300 px-1 sm:px-3"
                                >
                                    <ChevronsLeft className="w-4 h-4" />
                                </button>
                            </li>
                            <li className="flex-1 sm:flex-initial">
                                <button
                                    onClick={handlePreviousPage}
                                    disabled={currentPage === 1}
                                    className="transition duration-200 border items-center justify-center py-2 rounded-md cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed min-w-0 sm:min-w-[40px] shadow-none font-normal flex border-transparent text-slate-800 sm:mr-2 dark:text-slate-300 px-1 sm:px-3"
                                >
                                    <ChevronLeft className="w-4 h-4" />
                                </button>
                            </li>
                            <li data-tw-merge="" className="page-item">
                                <span className="transition duration-200 border items-center justify-center py-2 rounded-md cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed min-w-0 sm:min-w-[40px] shadow-none font-normal flex border-transparent text-slate-800 sm:mr-2 dark:text-slate-300 px-1 sm:px-3">{currentPage}</span>
                            </li>
                            <li className="flex-1 sm:flex-initial">
                                <button
                                    onClick={handleNextPage}
                                    disabled={currentPage === totalPages}
                                    className="transition duration-200 border items-center justify-center py-2 rounded-md cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed min-w-0 sm:min-w-[40px] shadow-none font-normal flex border-transparent text-slate-800 sm:mr-2 dark:text-slate-300 px-1 sm:px-3"
                                >
                                    <ChevronRight className="w-4 h-4" />
                                </button>
                            </li>
                            <li className="flex-1 sm:flex-initial">
                                <button
                                    onClick={handleLastPage}
                                    disabled={currentPage === totalPages}
                                    className="transition duration-200 border items-center justify-center py-2 rounded-md cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed min-w-0 sm:min-w-[40px] shadow-none font-normal flex border-transparent text-slate-800 sm:mr-2 dark:text-slate-300 px-1 sm:px-3"
                                >
                                    <ChevronsRight className="w-4 h-4" />
                                </button>
                            </li>
                        </ul>
                    </nav>
                    <div className="text-slate-500 mr-2">
                        Showing {indexOfFirstItem + 1} to {Math.min(indexOfLastItem, organizationData.length)} of {allOrganizationData.totalCount} entries
                    </div>
                    <select
                            value={itemsPerPage}
                            onChange={handleItemsPerPageChange}
                            className="disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 transition duration-200 ease-in-out text-sm border-slate-200 shadow-sm rounded-md py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 group-[.form-inline]:flex-1 !box mt-3 w-20 sm:mt-0"
                        >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                    </select>
                </div>
            </div>
        </div>
    )
}

export default List
