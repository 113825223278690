import React, { useState, useRef, useEffect } from 'react';
import { usePopper } from 'react-popper';
import { createPortal } from 'react-dom';
import { ExternalLink, FileText, FileUp } from 'lucide-react';
import axios from 'axios';

const Dropdown = ({data,org_id}) => {
    const [show, setShow] = useState(false);
    const [dropdownElement, setDropdownElement] = useState(null);
    const buttonRef = useRef(null);
    const { styles, attributes } = usePopper(buttonRef.current, dropdownElement, {
        placement: 'bottom-end',
    });
    
    const handleToggle = () => {
        setShow(!show);
    };
    
    const handleDocumentClick = (e) => {
        if (buttonRef.current && buttonRef.current.contains(e.target)) {
            return;
        }
        if (dropdownElement && dropdownElement.contains(e.target)) {
            return;
        }
        setShow(false);
    };

    useEffect(() => {
        if (show) {
            document.addEventListener('click', handleDocumentClick);
        } else {
            document.removeEventListener('click', handleDocumentClick);
        }
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, [show]);


    const handleExport = async (extension) => {
        

        var baseUrl = `${process.env.REACT_APP_API_URL}`;
        var url = '';
        var filename = '';

        if (data === 'employee') {
            url = `${baseUrl}/employee/export-employee?export_type=${extension === 'excel' ? 'xlsx' : 'csv'}`;
            extension = extension === 'excel' ? 'xlsx' : 'csv';
            filename = 'users';
        } else if (data === 'terminal') {
            url = `${baseUrl}/terminus/export-terminus?export_type=${extension === 'excel' ? 'xlsx' : 'csv'}`;
            extension = extension === 'excel' ? 'xlsx' : 'csv';
            filename = 'smart_device';
        } else if (data === 'organization') {
            url = `${baseUrl}/organization/export-organization?export_type=${extension === 'excel' ? 'xlsx' : 'csv'}`;
            extension = extension === 'excel' ? 'xlsx' : 'csv';
            filename = 'accounts';
        }else if (data === 'sub-organization') {
            url = `${baseUrl}/organization/export-organization?org_id=${org_id}&export_type=${extension === 'excel' ? 'xlsx' : 'csv'}`;
            extension = extension === 'excel' ? 'xlsx' : 'csv';
            filename = 'sub_accounts';
        }else if (data === 'ose') {
            url = `${baseUrl}/ose/export-onsite-engineer?export_type=${extension === 'excel' ? 'xlsx' : 'csv'}`;
            extension = extension === 'excel' ? 'xlsx' : 'csv';
            filename = 'onsite_engineer';
        } else if (data === 'asset') {
            url = `${baseUrl}/asset/export-asset?export_type=${extension === 'excel' ? 'xlsx' : 'csv'}`;
            extension = extension === 'excel' ? 'xlsx' : 'csv';
            filename = 'inventory';
        } else if (data === 'sde') {
            // url = `${baseUrl}/user/export-sde?org_id=19&export_type=${extension === 'excel' ? 'xlsx' : 'csv'}`;
            url = `${baseUrl}/user/export-sde?org_id=${org_id}&export_type=${extension === 'excel' ? 'xlsx' : 'csv'}`;
            extension = extension === 'excel' ? 'xlsx' : 'csv';
            filename = 'sde';
        }
        
        const actual_url = url;

        try {
            
            const response = await axios({
                url: actual_url,
                method: 'GET',
                responseType: 'blob',
            });
            
            // Generate a unique filename with the current date and time in the desired format
            const currentDateTime = new Date();
            const year = currentDateTime.getFullYear();
            const month = String(currentDateTime.getMonth() + 1).padStart(2, '0');
            const day = String(currentDateTime.getDate()).padStart(2, '0');
            const hours = String(currentDateTime.getHours()).padStart(2, '0');
            const minutes = String(currentDateTime.getMinutes()).padStart(2, '0');
            const seconds = String(currentDateTime.getSeconds()).padStart(2, '0');

            const formattedDateTime = `${year}_${month}_${day}_${hours}_${minutes}_${seconds}`;
            const uniqueFilename = `${filename}_${formattedDateTime}.${extension}`;

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', uniqueFilename);
            document.body.appendChild(link);
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
        } catch (err) {
            // notifyError(err.message);
        }
    }

    return (
        <div data-tw-merge data-tw-placement="bottom-end" className="dropdown relative">
            <button
                ref={buttonRef}
                onClick={handleToggle}
                aria-expanded={show}
                className="transition duration-200 border inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed text-primary border-primary text-white dark:border-primary mr-2 shadow-md"
            >
                <FileUp className="stroke-1.5 w-5 h-5 mr-2" /> Export
            </button>
            {show && createPortal(
                <div
                    ref={setDropdownElement}
                    data-transition
                    data-selector=".show"
                    data-enter="transition-all ease-linear duration-150"
                    data-enter-from="absolute !mt-5 invisible opacity-0 translate-y-1"
                    data-enter-to="!mt-1 visible opacity-100 translate-y-0"
                    data-leave="transition-all ease-linear duration-150"
                    data-leave-from="!mt-1 visible opacity-100 translate-y-0"
                    data-leave-to="absolute !mt-5 invisible opacity-0 translate-y-1"
                    className="dropdown-menu absolute z-[9999]"
                    style={styles.popper}
                    {...attributes.popper}
                >
                    <div data-tw-merge className="dropdown-content rounded-md border-transparent bg-white p-2 shadow-[0px_3px_10px_#00000017] dark:border-transparent dark:bg-darkmode-600 w-40">
                        
                        <button onClick={() => handleExport('excel')}  className="cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md hover:bg-slate-200/60 dark:bg-darkmode-600 dark:hover:bg-darkmode-400 dropdown-item">
                            <ExternalLink className="stroke-1.5 w-5 h-5 mr-2" /> Excel
                        </button>
                        <a onClick={() => handleExport('csv')} className="cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md hover:bg-slate-200/60 dark:bg-darkmode-600 dark:hover:bg-darkmode-400 dropdown-item">
                            <FileText className="stroke-1.5 w-5 h-5 mr-2" /> CSV
                        </a>
                    </div>
                </div>,
                document.body
            )}
            
        </div>
    );
};

export default Dropdown;
