// import React from 'react';
// import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
// import L from 'leaflet';
// import 'leaflet/dist/leaflet.css';

// const stores = [
//     { id: 1, name: 'Store 1', country: 'Country 1', lat: 19.663280, lon: 75.300293 },
//     { id: 2, name: 'Store 2', country: 'Country 2', lat: 22.6708, lon: 71.5724 },
//     { id: 3, name: 'Store 3', country: 'Country 2', lat: 18.5204, lon: 73.8567 },
//     { id: 4, name: 'Store 4', country: 'Country 2', lat: 15.2993, lon: 74.1240 },
// ];

// const countries = ['India', 'United States', 'Brazil', 'France'];

// delete L.Icon.Default.prototype._getIconUrl;
// L.Icon.Default.mergeOptions({
//     iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
//     iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
//     shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png'
// });

// const TerminalLocationData = () => {
//     return (

//         <MapContainer center={[20.5937, 78.9629]} zoom={5} style={{ height: '33vh', width: '100%' }}>
//             <TileLayer
//                 url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//                 attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//             />
//             {stores.map(store => (
//                 <Marker key={store.id} position={[store.lat, store.lon]}>
//                     <Popup>
//                         <div>
//                             <h2>{store.name}</h2>
//                             <p>{store.country}</p>
//                         </div>
//                     </Popup>
//                 </Marker>
//             ))}
//         </MapContainer>

//     )
// }

// export default TerminalLocationData

import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

// Fix icon issue with Leaflet in React
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

// const countries = ['India', 'United States', 'Brazil', 'France']; // Example country names

const TerminalLocationData = ({ terminalsData = [] }) => {
    const [countryMarkers, setCountryMarkers] = useState([]);
    const [mapCenter, setMapCenter] = useState([20, 0]); // Default center
    
    useEffect(() => {
        // Function to fetch coordinates for each country
        
        const fetchCountryCoordinates = async () => {
            const markers = await Promise.all(
                terminalsData.map(async (country) => {
                    const response = await fetch(
                        `https://nominatim.openstreetmap.org/search?city=${country}&format=json&limit=1`
                    );
                    const data = await response.json();
                    if (data && data.length > 0) {
                        const { lat, lon } = data[0];
                        return {
                            country,
                            lat: parseFloat(lat),
                            lon: parseFloat(lon),
                        };
                    }
                    return null;
                })
            );

            const validMarkers = markers.filter((marker) => marker !== null);
            setCountryMarkers(validMarkers); // Set markers

            if (validMarkers.length > 0) {
                // Calculate center dynamically based on fetched coordinates
                const avgLat =
                    validMarkers.reduce((sum, marker) => sum + marker.lat, 0) /
                    validMarkers.length;
                const avgLon =
                    validMarkers.reduce((sum, marker) => sum + marker.lon, 0) /
                    validMarkers.length;

                setMapCenter([avgLat, avgLon]); // Set the new calculated center
            }
        };

        fetchCountryCoordinates();
    }, [terminalsData]);

    return (
        <MapContainer center={mapCenter} zoom={2} style={{ height: '33vh', width: '100%' }}>
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            {countryMarkers.map((marker, index) => (
                <Marker key={index} position={[marker.lat, marker.lon]}>
                    <Popup>{marker.country}</Popup>
                </Marker>
            ))}
        </MapContainer>
    );
};

export default TerminalLocationData;
