import React, { useEffect, useState, useRef } from 'react'
import { X, Monitor, MonitorOff } from "lucide-react";
import axios from 'axios';
import { notifyError } from '../helpers';
import { DOOR_STATUS } from '../helpers/Constants';

const ModelComponenet = ({ show, closeModal, data }) => {

    const [lockerData, setlockerDataData] = useState([]);
    const [binData, setbinDataData] = useState([]);

    useEffect(() => {
        const fetchTerminalLockerData = async() => {
            try {
                const response = await axios.get('/terminus/show-terminal-view/'+data);
                if(response.data.success === true){
                    setbinDataData(response.data.bin);
                    setlockerDataData(response.data);
                }
                
            } catch (err) {
                notifyError(err.message);
            }
        }

        fetchTerminalLockerData();
    },[]);

    return (

        <div aria-hidden="false" className={`modal group bg-black/60 transition-[visibility,opacity] w-screen h-screen fixed left-0 top-0 ${show ? 'visible opacity-100 duration-[0s,0.4s]' : 'invisible opacity-0 delay-[0.2s,0s]'} overflow-y-auto`} style={{ paddingLeft: '0px', marginTop: '0px', marginLeft: '0px', zIndex: '10000' }}>
            <div className="mt-16 w-[90%] mx-auto bg-white relative rounded-md shadow-md transition-[margin-top,transform] duration-[0.4s,0.3s] -mt-16 group-[.show]:mt-16 group-[.modal-static]:scale-[1.05] dark:bg-darkmode-600 sm:w-[600px] lg:w-[900px]  text-center ease-in-out">

                <div className="flex flex-col items-center p-5 border-b sm:flex-row border-slate-200/60 dark:border-darkmode-400">
                    <h2 className="mr-auto text-base font-medium">Locker Info</h2>
                    <div className="flex items-center w-full mt-3 sm:w-auto sm:ml-auto sm:mt-0">
                        <button onClick={closeModal}>
                            <X size={30} color="#475569" strokeWidth={1.5} />
                        </button>
                    </div>
                </div>

                <div className='mt-5 grid grid-cols-12 gap-6 p-5'>
                        
                    <div className='col-span-6'>
                        <div className="preview relative [&.hide]:overflow-hidden [&.hide]:h-0">
                            <div data-tw-merge="" className="block sm:flex items-center group form-inline">
                                <label data-tw-merge="" htmlFor="horizontal-form-1" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5">
                                    Terminal Name 
                                </label>
                                <input id="name" type="text" value={lockerData.locker_data ? lockerData.locker_data.term_name : ''}  disabled="disabled" className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&amp;:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"></input>
                            </div>
                            
                        </div>
                    </div>

                    <div className='col-span-6'>
                        <div className="preview relative [&.hide]:overflow-hidden [&.hide]:h-0">
                            <div data-tw-merge="" className="block sm:flex items-center group form-inline">
                                <label data-tw-merge="" htmlFor="horizontal-form-1" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5">
                                    Total Locker 
                                </label>
                                <input id="name" type="text"  value={lockerData.locker_data ? lockerData.locker_data.total_lock : ''}placeholder="Input text" disabled="disabled" className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&amp;:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"></input>
                            </div>
                            
                        </div>
                    </div>

                    <div className='col-span-6'>
                        <div className="preview relative [&.hide]:overflow-hidden [&.hide]:h-0">
                            <div data-tw-merge="" className="block sm:flex items-center group form-inline">
                                <label data-tw-merge="" htmlFor="horizontal-form-1" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5">
                                    Reserved Bin
                                </label>
                                <input id="name" type="text"  value={lockerData.locker_data ? lockerData.locker_data.reserved_bin : ''}placeholder="Input text" disabled="disabled" className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&amp;:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"></input>
                            </div>
                            
                        </div>
                    </div>

                </div>

                <div className="p-5 text-center">
                    <div className="flex flex-col">
                        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                                <div className="overflow-hidden">
                                <table className="w-full text-left">
                                        <thead className="">
                                            <tr className="[&amp;:hover_td]:bg-slate-100 [&amp;:hover_td]:dark:bg-darkmode-300 [&amp;:hover_td]:dark:bg-opacity-50">
                                                <th className="font-medium px-5 py-3 border-b-2 dark:border-darkmode-300 border-l border-r border-t whitespace-nowrap">Locker Number</th>
                                                <th className="font-medium px-5 py-3 border-b-2 dark:border-darkmode-300 border-l border-r border-t whitespace-nowrap">Door Status</th>
                                                <th className="font-medium px-5 py-3 border-b-2 dark:border-darkmode-300 border-l border-r border-t whitespace-nowrap">Bin</th>
                                                <th className="font-medium px-5 py-3 border-b-2 dark:border-darkmode-300 border-l border-r border-t whitespace-nowrap">Asset</th>
                                                <th className="font-medium px-5 py-3 border-b-2 dark:border-darkmode-300 border-l border-r border-t whitespace-nowrap">Assign</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {binData.map((bin, index) =>
                                                <tr key={`bin${index}`} className="[&amp;:hover_td]:bg-slate-100 [&amp;:hover_td]:dark:bg-darkmode-300 [&amp;:hover_td]:dark:bg-opacity-50">
                                                    <td className="px-5 py-3 border-b dark:border-darkmode-300 border-l border-r border-t w-10">
                                                        {bin.bin_id}
                                                    </td>
                                                    <td className="px-5 py-3 border-b dark:border-darkmode-300 border-l border-r border-t w-10">
                                                        { DOOR_STATUS[bin.door_status] }
        
                                                    </td>
                                                    <td className="px-5 py-3 border-b dark:border-darkmode-300 border-l border-r border-t w-10">
                                                        
                                                        { bin.is_full === 1 &&
                                                            <div className="flex items-center text-success">Full</div>
                                                        }
                                                        { bin.is_full === 0 &&
                                                            <div className="flex items-center text-danger">Empty</div>
                                                        }
                                                    </td>
                                                
                                                    <td className="px-5 py-3 border-b dark:border-darkmode-300 border-l border-r border-t w-10">
                                                        {bin.asset ? bin.asset.asset_id : 'N/A'}
                                                    </td>
                                                    <td className="px-5 py-3 border-b dark:border-darkmode-300 border-l border-r border-t w-10">
                                                        {bin.asset ? bin.asset.employe ?
                                                            (
                                                                <div>
                                                                    <div className="font-medium whitespace-nowrap">{bin.asset.employe.first_name + ' ' + bin.asset.employe.last_name}</div>
                                                                    <div className="text-slate-500 text-xs whitespace-nowrap mt-0.5">{bin.asset.email}</div>
                                                                    <div className="text-slate-500 text-xs whitespace-nowrap mt-0.5">{bin.asset.contact}</div>
                                                                </div>
                                                            )
                                                        :'N/A'

                                                        : 'N/A'}
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModelComponenet
