import React, { useState, useEffect } from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { notifyError,notifySuccess } from '../../helpers';
import axios from 'axios';
import { useNavigate, useParams } from "react-router-dom";
import { Link } from 'react-router-dom';
import { OSE_STATUS } from '../../helpers/Constants';

function Create() {

    // all constants
    const navigate = useNavigate();
    const { id } = useParams(); // Get the terminal ID from the URL params
    const isEditMode = !!id; // Determine if the form is in edit mode
    
     // Validation
     const validationSchema = Yup.object().shape({
        email: Yup.string().required('Email is required').email(),
        first_name: Yup.string().required('First name is required'),
        last_name: Yup.string().required('Last name is required'),
        contact: Yup.string().required('Contact number is required').matches(/^[0-9]{10}$/, 'Please enter a valid 10-digit Contact Number'),
        emp_id: Yup.string().required('User id is required'),
        rf_id: Yup.string().required('RFID is required'),
    });

    
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, setValue, reset, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        // Fectching organisation data for dropdown
    
        const fetchEmployee = async () => {
            if (isEditMode) {
                try {
                    const response = await axios.get(`/employee/get-employee-detail/${id}`);
                    if (response.data.success) {
                        const fields = ['first_name', 'last_name', 'email', 'contact', 'emp_id', 'rf_id'];
                        fields.forEach(field => setValue(field, response.data.result[field]));
                        
                    }
                } catch (err) {
                    notifyError(err.message);
                }
            }
        };

        fetchEmployee();
        
    },[id, isEditMode, setValue]);

    
    const onSubmit = async (data) => {
        data['status'] = 1;
        const payload = { ...data };
        var url = '/employee/create-employee';
        var method = 'post';
        if (isEditMode) {
            url = `/employee/update-employee/${id}`;
            method = 'put';
        }

        axios({
            method: method,
            url: url,
            data: payload, 
        })
        .then(function(response) {
            if(response.data.success === true){
                if (isEditMode) {
                    notifySuccess('User Updated Sucsessfully');
                }else{
                    notifySuccess('User Added Sucsessfully');
                }
                
                navigate('/users')
            }
        })
        .catch(function (error){
            notifyError(error.message);
        });      
    }

  return (
    <div>   
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="preview-component intro-y box mt-5">
                <div className="flex flex-col items-center border-b border-slate-200/60 p-5 dark:border-darkmode-400 sm:flex-row">
                    <h2 className="mr-auto text-base font-medium">
                        {isEditMode ? 'Edit User Info' : 'Add User Info'}
                    </h2>
                </div>

                <div className="p-5" >
                <p className="text-left">Note : * Denotes manadatory information</p>
                    <br />
                    <div className='mt-5 grid grid-cols-12 gap-6'>
                        <div className='intro-y col-span-12 lg:col-span-6'>
                            <div className="preview relative [&.hide]:overflow-hidden [&.hide]:h-0">
                                <div data-tw-merge="" className="block sm:flex items-center group form-inline">
                                    <label data-tw-merge="" htmlFor="horizontal-form-1" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-20">
                                        User Id *
                                    </label>
                                    <input  id="horizontal-form-1" name="emp_id" type="text" {...register('emp_id')} placeholder="Enter User Id" className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10" />
                                </div>
                                <div className="text-danger text-right mt-2">{errors.emp_id?.message}</div>
                            </div>
                        </div>
                        <div className='intro-y col-span-12 lg:col-span-6'>
                            <div className="preview relative [&.hide]:overflow-hidden [&.hide]:h-0">
                                <div data-tw-merge="" className="block sm:flex items-center group form-inline">
                                    <label data-tw-merge="" htmlFor="horizontal-form-1" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-20">
                                        RFID*
                                    </label>
                                    <input  id="horizontal-form-1" name="rf_id" type="text" {...register('rf_id')} placeholder="Enter RFID" className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10" />
                                </div>
                                <div className="text-danger text-right mt-2">{errors.rf_id?.message}</div>
                            </div>
                        </div>
                        <div className='col-span-12 lg:col-span-6'>
                            <div className="preview relative [&.hide]:overflow-hidden [&.hide]:h-0">
                                <div data-tw-merge="" className="block sm:flex items-center group form-inline">
                                    <label data-tw-merge="" htmlFor="horizontal-form-1" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-20">
                                        First Name *
                                    </label>
                                    <input  id="horizontal-form-1" name="first_name" type="text" {...register('first_name')} placeholder="Enter First name" className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10" />
                                </div>
                                <div className="text-danger text-right mt-2">{errors.first_name?.message}</div>
                            </div>
                        </div>

                        <div className='col-span-12 lg:col-span-6'>
                            <div className="preview relative [&.hide]:overflow-hidden [&.hide]:h-0">
                                <div data-tw-merge="" className="block sm:flex items-center group form-inline">
                                    <label data-tw-merge="" htmlFor="horizontal-form-1" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-20">
                                        Last Name *
                                    </label>
                                    <input  id="horizontal-form-1" name="last_name" type="text" {...register('last_name')} placeholder="Enter Last Name" className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10" />
                                </div>
                                <div className="text-danger text-right mt-2">{errors.last_name?.message}</div>
                            </div>
                        </div>

                        <div className='intro-y col-span-12 lg:col-span-6'>
                            <div className="preview relative [&.hide]:overflow-hidden [&.hide]:h-0">
                                <div data-tw-merge="" className="block sm:flex items-center group form-inline">
                                    <label data-tw-merge="" htmlFor="horizontal-form-1" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-20">
                                        Contact No.*
                                    </label>
                                   
                                    <input  id="horizontal-form-1" name='contact' type="text" {...register("contact")} placeholder="Enter Contact Number" className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10" />
                                </div>
                                <div className="text-danger text-right mt-2">{errors.contact?.message}</div>
                            </div>
                        </div>

                        <div className='intro-y col-span-12 lg:col-span-6'>
                            <div className="preview relative [&.hide]:overflow-hidden [&.hide]:h-0">
                                <div data-tw-merge="" className="block sm:flex items-center group form-inline">
                                    <label data-tw-merge="" htmlFor="horizontal-form-1" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-20">
                                        Email*
                                    </label>
                                    <input  id="horizontal-form-1" name="email" {...register('email')} type="text" placeholder="Enter email" className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10" />
                                </div>
                                <div className="text-danger text-right mt-2">{errors.email?.message}</div>
                            </div>
                        </div>

                        
                        
                    </div>
                    <div className="mt-5 text-right">
                        <Link to="/users"  type="button" className="transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&amp;:hover:not(:disabled)]:bg-opacity-90 [&amp;:hover:not(:disabled)]:border-opacity-90 [&amp;:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed border-secondary text-slate-500 dark:border-darkmode-100/40 dark:text-slate-300 [&amp;:hover:not(:disabled)]:bg-secondary/20 [&amp;:hover:not(:disabled)]:dark:bg-darkmode-100/10 mr-1 w-24 mr-2">Cancel</Link>
                        <button disabled={isSubmitting} className="transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&amp;:hover:not(:disabled)]:bg-opacity-90 [&amp;:hover:not(:disabled)]:border-opacity-90 [&amp;:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-primary border-primary text-white dark:border-primary w-24">
                        {isSubmitting && <span className="spinner-border spinner-border-sm me-1"></span>}
                        {isEditMode ? 'Update' : 'Save'}</button>
                    </div>
                </div>
            </div>
        </form>
        
    </div>
  )
}

export default Create
