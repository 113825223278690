import React, {useState, useEffect} from 'react'
import { Link, useLocation } from 'react-router-dom';
import {fetchNameById} from '../../utils/HelperFunction';

const Breadcrumbs = () => {

    const location = useLocation();
    const pathname = location.pathname;
    const pathnames = pathname.split('/').filter(x => x);

    var pathname_array = useLocation().pathname.split('/');
    pathname_array = pathname_array[1];

    const [breadcrumbPaths, setBreadcrumbPaths] = useState([]);

    useEffect(() => {
        
        const generateBreadcrumbs = async () => {
            const paths = location.pathname.split('/').filter(Boolean);
            const newPaths = await Promise.all(
                paths.map(async (segment,index) => {
                    // Check if the segment is a numeric ID and fetch the name
                    if (!isNaN(segment)) {
                        const name = await fetchNameById(pathname_array, segment);
                        return name;
                    }
                    return segment;
                })
            );
            setBreadcrumbPaths(newPaths);
        };

        generateBreadcrumbs();

    }, [location]);

    return (
        <nav aria-label="breadcrumb" className="flex -intro-x mr-auto hidden sm:flex">
            <ol className="flex items-center text-theme-1 dark:text-slate-300">

                {breadcrumbPaths.length > 0 ? (
                    <li>
                        <Link to="/">Home</Link>
                    </li>
                ) : (
                    <li>Home</li>
                )}

                {breadcrumbPaths.map((value, index) => {
                    
                    const to = `/${breadcrumbPaths.slice(0, index + 1).join('/')}`;
                    const isLast = index === breadcrumbPaths.length - 1;
                    return (
                        <li key={to} className="relative ml-5 pl-0.5 before:content-[''] before:w-[14px] before:h-[14px] before:bg-chevron-black before:transform before:rotate-[-90deg] before:bg-[length:100%] before:-ml-[1.125rem] before:absolute before:my-auto before:inset-y-0 dark:before:bg-chevron-white text-slate-800 cursor-text dark:text-slate-400">
                            {isLast ? (
                                value.charAt(0).toUpperCase() + value.slice(1)
                            ) : (
                                 (index== 0) ?
                                    <Link to={to}>
                                        {value.charAt(0).toUpperCase() + value.slice(1)}
                                    </Link>
                                :
                                    <span>{value.charAt(0).toUpperCase() + value.slice(1)}</span>
                                
                            )}
                        </li>
                    );
                })}
                
            </ol>
        </nav> 
    )
    
}

export default Breadcrumbs
