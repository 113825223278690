import React, { useState,useRef,useEffect } from 'react'

import Dropdown from '../common/Dropdown';
import defaultProfile from '../../assets/images/default-profile.jpg'
import axios from 'axios';
import { notifyError } from '../../helpers';
import Breadcrumbs from '../common/Breadcrumbs';

function Header() {

	const [isVisible, setVisible] = useState(false)
	const [logoImage, setLogoImage] = useState(null);
	const [userInfo, setUserInfo] = useState([]);

	let onHideShowClick = () =>{
		setVisible(!isVisible)
	}
	

	const handleClickOutside = (event) => {
        setVisible(false); 
    };

	const fetchProfile = async () => {
        try {
            const response = await axios.get(`/user/get-profile-detail`);
            if (response.data.success) {
                setUserInfo(response.data.result);
                setLogoImage(response.data.result.img_url ? (response.data.result.img_url!='null' ? response.data.result.img_url : defaultProfile  ) : defaultProfile);
            }
        } catch (err) {
            // notifyError(err.message);
        }
    };

    useEffect(() => {
		fetchProfile();

        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [isVisible]);

	return (
		<div>
			<div className="relative z-[51] flex h-[67px] items-center border-b border-slate-200">
				<Breadcrumbs />
				

				<div className="dropdown relative">
					<Dropdown 
						logoImage={logoImage} 
						userInfo={userInfo} 
					/>
				</div>

			</div>
		</div>
	)
}

export default Header
