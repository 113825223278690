import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { PrivateRoute } from '../Components/route/PrivateRoute';
import Layout from '../Components/Layouts/Layout';
import ProtectedRoute from '../Components/common/ProtectedRoute';
import ErrorPage from '../Components/common/ErrorPage';

// common
import Dashboard from '../pages/Dashboard/Dashbaord';
import Login from '../pages/auth/Login';
import ForgotPassword from '../pages/auth/ForgotPassword';
import ResetPassword from '../pages/auth/ResetPassword';

// terminal - Smart Devices
import Terminal from '../pages/Terminal/List';
import TerminalCreate from '../pages/Terminal/Create';
import TerminalShow from '../pages/Terminal/ShowDetail';

// employee
import Employee from '../pages/Employee/List';
import EmployeeCreate from '../pages/Employee/Create';
import EmployeeImport from '../pages/Employee/Import';

// organisation
import Organisation from '../pages/Organisation/List';
import OrganisationCreate from '../pages/Organisation/Create';
import OrganisationShow from '../pages/Organisation/ShowDetail';

// ose
import OseList from '../pages/OSE/List';
import OseCreate from '../pages/OSE/Create';
import OseImport from '../pages/OSE/Import';
import OseShow from '../pages/OSE/ShowDetail';

// ose
import SdeList from '../pages/SDE/List';
import SdeCreate from '../pages/SDE/Create';

// Assets
import AssetList from '../pages/Assets/List';
import AssetCreate from '../pages/Assets/Create';
import AssetImport from '../pages/Assets/Import';
import AssetsShow from '../pages/Assets/ShowDetail';
import AssetAudit from '../pages/Assets/AuditTrail';

// OTA
import OTAList from '../pages/OTA/List';
import OTACreate from '../pages/OTA/Create';

import Test from '../pages/Test';

// Profile
import Profile from '../pages/Profile/Profile';
import ChangePassword from '../pages/Profile/ChangePassword';

function routes() {

	return (
		<Routes>
			<Route element={<PrivateRoute />}>
				<Route path="/" element={<Layout />}>
					<Route index element={<Dashboard />} />
					
					<Route element={<ProtectedRoute resource="smartDevices" action="list" />}>
          				<Route path="/smart-device" element={<Terminal />} />
        			</Route>

					<Route element={<ProtectedRoute resource="smartDevices" action="add" />}>
						<Route path="/smart-device/create" element={<TerminalCreate mode="create" />} />
					</Route>

					<Route element={<ProtectedRoute resource="smartDevices" action="edit" />}>
						<Route path="/smart-device/edit/:id" element={<TerminalCreate mode="edit" />} />
					</Route>

					<Route element={<ProtectedRoute resource="smartDevices" action="show" />}>
						<Route path="/smart-device/show/:id" element={<TerminalShow />} />
					</Route>

					{/* routes for Employee */}
					<Route element={<ProtectedRoute resource="employee" action="list" />}>
						<Route path="/users" element={<Employee />} />
					</Route>

					<Route element={<ProtectedRoute resource="employee" action="add" />}>
						<Route path="/users/create" element={<EmployeeCreate mode="create" />} />
					</Route>

					<Route element={<ProtectedRoute resource="employee" action="edit" />}>
						<Route path="/users/edit/:id" element={<EmployeeCreate mode="edit" />} />
					</Route>

					<Route element={<ProtectedRoute resource="employee" action="import" />}>
						<Route path="/users/import" element={<EmployeeImport />} />
					</Route>

				{/* routes for organisation */}
					<Route element={<ProtectedRoute resource="organization" action="list" />}>
						<Route path="/accounts" element={<Organisation />} />
					</Route>

					<Route element={<ProtectedRoute resource="organization" action="add" />}>
                		<Route path="/accounts/create" element={<OrganisationCreate />} />
					</Route>

					<Route element={<ProtectedRoute resource="organization" action="edit" />}>
						<Route path="/accounts/edit/:id" element={<OrganisationCreate mode="edit" />} />
					</Route>

					<Route element={<ProtectedRoute resource="organization" action="show" />}>
						<Route path="/accounts/show/:id" element={<OrganisationShow />} />
					</Route>

				{/* routes for Admin */}
				<Route element={<ProtectedRoute resource="organizationAdmin" action="list" />}>
						<Route path="/accounts/:org_id/admin" element={<SdeList />} />
					</Route>

					<Route element={<ProtectedRoute resource="organizationAdmin" action="add" />}>
						<Route path="/accounts/:org_id/admin/create" element={<SdeCreate mode="create" />} />
					</Route>

					<Route element={<ProtectedRoute resource="organizationAdmin" action="edit" />}>
						<Route path="/accounts/:org_id/admin/edit/:id" element={<SdeCreate mode="edit" />} />
					</Route>

				{/* routes for sub-organisation */}
					<Route element={<ProtectedRoute resource="subOrganization" action="list" />}>
						<Route path="/sub-accounts" element={<Organisation />} />
					</Route>

					<Route element={<ProtectedRoute resource="subOrganization" action="add" />}>
                		<Route path="/sub-accounts/create" element={<OrganisationCreate />} />
					</Route>

					<Route element={<ProtectedRoute resource="subOrganization" action="edit" />}>
						<Route path="/sub-accounts/edit/:id" element={<OrganisationCreate mode="edit" />} />
					</Route>

					<Route element={<ProtectedRoute resource="subOrganization" action="show" />}>
						<Route path="/sub-accounts/show/:id" element={<OrganisationShow />} />
					</Route>

				{/* routes for OSE */}
					<Route element={<ProtectedRoute resource="ose" action="list" />}>
						<Route path="/onsite-engineer" element={<OseList />} />
					</Route>

					<Route element={<ProtectedRoute resource="ose" action="add" />}>
						<Route path="/onsite-engineer/create" element={<OseCreate mode="create" />} />
					</Route>

					<Route element={<ProtectedRoute resource="ose" action="edit" />}>
						<Route path="/onsite-engineer/edit/:id" element={<OseCreate mode="edit" />} />
					</Route>

					<Route element={<ProtectedRoute resource="ose" action="import" />}>
						<Route path="/onsite-engineer/import" element={<OseImport />} />
					</Route>

					<Route element={<ProtectedRoute resource="ose" action="show" />}>
						<Route path="/onsite-engineer/show/:id" element={<OseShow />} />
					</Route>

				{/* routes for SDE */}
				<Route element={<ProtectedRoute resource="sde" action="list" />}>
						<Route path="/sub-accounts/:org_id/sde" element={<SdeList />} />
					</Route>

					<Route element={<ProtectedRoute resource="sde" action="add" />}>
						<Route path="/sub-accounts/:org_id/sde/create" element={<SdeCreate mode="create" />} />
					</Route>

					<Route element={<ProtectedRoute resource="sde" action="edit" />}>
						<Route path="/sub-accounts/:org_id/sde/edit/:id" element={<SdeCreate mode="edit" />} />
					</Route>
					
				{/* routes for assets */}
					<Route element={<ProtectedRoute resource="assets" action="list" />}>
						<Route path="/inventory" element={<AssetList />} />
					</Route>

					<Route element={<ProtectedRoute resource="assets" action="add" />}>
						<Route path="/inventory/create" element={<AssetCreate mode="create" />} />
					</Route>

					<Route element={<ProtectedRoute resource="assets" action="edit" />}>
						<Route path="/inventory/edit/:id" element={<AssetCreate mode="edit" />} />
					</Route>

					<Route element={<ProtectedRoute resource="assets" action="import" />}>
						<Route path="/inventory/import" element={<AssetImport />} />
					</Route>

					<Route element={<ProtectedRoute resource="assets" action="show" />}>
						<Route path="/inventory/show/:id" element={<AssetsShow />} />
					</Route>

					<Route element={<ProtectedRoute resource="assets" action="audit" />}>
						<Route path="/inventory/audit-report/:id" element={<AssetAudit />} />
					</Route>
					

				{/* routes for OTA */}
					<Route element={<ProtectedRoute resource="ota" action="list" />}>
						<Route path="/ota" element={<OTAList />} />
					</Route>

					<Route element={<ProtectedRoute resource="ota" action="add" />}>
						<Route path="/ota/create" element={<OTACreate mode="add" />} />
        			</Route>

					<Route element={<ProtectedRoute resource="ota" action="edit" />}>
						<Route path="/ota/edit/:id" element={<OTACreate mode="edit" />} />
					</Route>

					<Route path="/test" element={<Test />} />

					<Route path="/profile" element={<Profile />} />
					<Route path="/change-password" element={<ChangePassword />} />

				</Route>
			</Route>
			
			<Route path="/login" element={<Login />}></Route>
			<Route path="/forgot-password" element={<ForgotPassword />}></Route>
			<Route path="/reset-password/:token" element={<ResetPassword />}></Route>
			<Route path="/error" element={<ErrorPage />} />
		</Routes>
	)
}

export default routes
