import React, { useEffect, useState, useRef, useCallback } from 'react';
import { ChevronsLeft, ChevronLeft, ChevronRight, ChevronsRight, SquareCheckBig, Search, Monitor, MonitorOff, Lock } from 'lucide-react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import ModelComponenet from '../../Components/ModelComponenet';
import ExportDropdown from '../../Components/common/ExportDropdown';
import { formatDateTime, calculateTimeDifference } from '../../utils/DateUtils';
import { Plus } from 'lucide-react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import UpgradeModel from './UpgradeModel';
import { usePermission } from '../../hooks/usePermission';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import ColumnFilter from '../../Components/common/ColumnFilter';
import MultiSearchFilter from '../../Components/common/MultiSearchFilter';
import ActionDropdown from './ActionDropDown';
import AssignTerminal from './AssignTerminal';
import {selectOrgId} from '../../store';

function List() {

    const columns = [
        { title: "Device Name", field: "term_name", subField: "", sort: true, default: true, filter:true },
        { title: "Organization", field: "organization", subField: "name", sort: true, default: true, filter:true },
        { title: "Country", field: "country", subField: "", sort: true, default: true, filter:true },
        { title: "State", field: "state", subField: "", sort: true, default: true, filter:true },
        { title: "City", field: "city", subField: "", sort: true, default: true, filter:true },
        { title: "HW Version", field: "hw_version", subField: "", sort: true, default: false, filter:true },
        { title: "Device status", field: "is_connect", subField: "", sort: true, default: true,filter:false },
        { title: "SW Version", field: "sw_version", subField: "", sort: true, default: false, filter:true },
        { title: "Mac Address", field: "mac_address", subField: "", sort: true, default: true, filter:true },
        { title: "Status", field: "status", subField: "", sort: true, default: true,filter:false },
        { title: "Reserved Number", field: "reserved_number", subField: "", sort: true, default: false,filter:true },
        { title: "Address", field: "address", subField: "", sort: true, default: false,filter:true },
        { title: "Layout", field: "layout", subField: "", sort: true, default: false,filter:false },
        { title: "Created At", field: "createdAt", subField: "", sort: true, default: true, filter:false },  
    ];
    
    const terminalTabStatus = {
        'all': 'All',
        'active': 'Active',
        'inactive': 'Inactive',
        'repair': 'Repair',
        'unassigned': 'Unassigned'
    }

    const [terminalData, setData] = useState([]);
    const [allTerminalData, setAllTerminalData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);
    const [isAssignModalOpen, setAssignModalOpen] = useState(false);
    const [modelSelectedData, setModelSelectedData] = useState([]);
    const dropdownRefs = useRef([]);
    // const [activeTab, setActiveTab] = useState('all');
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [sortColumn, setSortColumn] = useState('');
    const [sortOrder, setSortOrder] = useState('desc');
    const [selectedFilterColumns, setSelectedFilterColumns] = useState([]);
    const [statusValue, setStatusValue] = useState('');
    const [selectedFileterValue, setSelectedFilterValue] = useState('');

    useEffect(() => {
        fetchData();
    }, [itemsPerPage]);

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
        fetchData('search', event.target.value);

    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const totalPages = allTerminalData.totalPages;

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            let page = currentPage - 1;
            setCurrentPage(page);
            fetchData('pagination', page);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            let page = currentPage + 1;
            setCurrentPage(page);
            fetchData('pagination', page);
        }
    };

    const handleFirstPage = () => {
        setCurrentPage(1);
        fetchData('pagination', 1);
    };

    const handleLastPage = () => {
        setCurrentPage(totalPages);
        fetchData('pagination', totalPages);
    };

    const handleItemsPerPageChange = (event) => {
        setItemsPerPage(parseInt(event.target.value));
        setCurrentPage(1);
        fetchData('pagination-page', event.target.value);
    };

    const handleSort = (column) => {
        setSortColumn(column);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        fetchData('sort',column);
    };

    // const handleTabChange = (tab) => {
    //     setActiveTab(tab);
    //     setCurrentPage(1);

    //     if (tab === 'all') {
    //         fetchData();
    //     } else {
    //         var status = '';
    //         if (tab === 'active') {
    //             status = 1;
    //         } else if (tab === 'inactive') {
    //             status = 2;
    //         } else if (tab === 'repair') {
    //             status = 3;
    //         } else if (tab === 'unassigned') {
    //             status = 4;
    //         }

    //         fetchData('status', status);
    //     }

    // };

    const handleStatusChange = (event) => {
        console.log(event.target.value);
        let tab = event.target.value;
        setCurrentPage(1);

        var status = '';
        
        if (tab === 'all') {
            status = '';
        } else {
            if (tab === 'active') {
                status = 1;
            } else if (tab === 'inactive') {
                status = 2;
            } else if (tab === 'repair') {
                status = 3;
            } else if (tab === 'unassigned') {
                status = 4;
            }
        }

        setStatusValue(status);
        fetchData('status', status);

    };

    const fetchData = useCallback(async (mode, status) => {
        var url = '';
        if(mode === 'pagination'){
            url = '/terminus/list-terminal?page='+status+'&page_size='+itemsPerPage;
        }else{
            url = '/terminus/list-terminal?page='+currentPage+'&page_size='+itemsPerPage;
        }

        if (mode === 'status' && status !== undefined) {
            url = url + '&status=' + status;
        }else{
            url = url + '&status=' + statusValue;
        } 

        if (mode === 'search') {
            url = url + '&search=' + status;
        }else{
            url = url + '&search=' + searchTerm;
        } 
        
        if (mode === 'sort'){
            url = url + '&sort_type='+sortOrder+'&sort_field='+status;
        }

        if(mode === 'filter'){
            url = url + '&'+status;
        }else{
            url = url + '&' + selectedFileterValue;
        }

        try {
            const response = await axios.get(url);
            if (response.data.success === true) {
                setData(response.data.data);
                setAllTerminalData(response.data);
            }

        } catch (err) {
            // notifyError(err.message);
        }
    },[itemsPerPage, statusValue, sortOrder, currentPage,selectedFileterValue]);

    const isAccess = {
        edit: usePermission('smartDevices', 'edit'),
        upgrade: usePermission('smartDevices', 'upgrade'),
        downloadCertificate : usePermission('smartDevices', 'download-certificate'),
        assignTerminal : usePermission('smartDevices', 'assign-device'),
    }

    const showSwal = (modelData) => {
        withReactContent(Swal).fire({
            title: "Are you sure",
            text: "You want to Upgrade the Device?",
            icon: "warning",
            showCancelButton: true,
            customClass: {
                popup: 'swal-popup', // Apply your custom styling class
            },
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Yes",
        }).then((result) => {
            if (result.isConfirmed) {
                openModal(modelData, 'upgrade');
            }
        });
    }

    const openModal = (modelData, status) => {
        console.log(status);
        if (status === 'show') {
            setIsModalOpen(true);
        } else if(status === 'upgrade') {
            setIsUpgradeModalOpen(true);
        }else if(status === 'assign'){
            setAssignModalOpen(true);
        }
        setModelSelectedData(modelData);
    };

    const closeModal = () => {

        setIsModalOpen(false);
        setIsUpgradeModalOpen(false);
        setAssignModalOpen(false);
    };

    const renderSortIcon = (column,sort) => {

        if(sort){
            if (sortColumn === column) {
                if(sortOrder === 'asc'){
                    return faSortUp;
                }else if(sortOrder === 'desc'){
                    return faSortDown;
                }
            }
            return faSort;
        }
    };

    const handleDownloadCertificate = async (terminal) => {
        try {
            var actual_url = 'terminus/download-certificate/'+terminal.id;
            const response = await axios({
                url: actual_url,
                method: 'GET',
                responseType: 'blob',
            });
            
            // Generate a unique filename with the current date and time in the desired format
            const currentDateTime = new Date();
            const year = currentDateTime.getFullYear();
            const month = String(currentDateTime.getMonth() + 1).padStart(2, '0');
            const day = String(currentDateTime.getDate()).padStart(2, '0');
            const hours = String(currentDateTime.getHours()).padStart(2, '0');
            const minutes = String(currentDateTime.getMinutes()).padStart(2, '0');
            const seconds = String(currentDateTime.getSeconds()).padStart(2, '0');

            const formattedDateTime = `${year}_${month}_${day}_${hours}_${minutes}_${seconds}`;
            const uniqueFilename = `smart_device_${formattedDateTime}.zip`;

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', uniqueFilename);
            document.body.appendChild(link);
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
        } catch (err) {
            // notifyError(err.message);
        }
    }

    useEffect(() => {
        const storedColumns = JSON.parse(localStorage.getItem('TerminalFilterSelectedColumns'));
        if (storedColumns) {
            setSelectedColumns(storedColumns);
        } else {
            const defaultColumns = columns.filter(col => col.default);
            setSelectedColumns(defaultColumns);
        }

        const storedFilterColumns = JSON.parse(localStorage.getItem('TerminalFilterColumns'));
        if (storedFilterColumns) {
            setSelectedFilterColumns(storedFilterColumns);
        } else {
            const defaultColumns = columns.filter(col => col.filter);
            setSelectedFilterColumns(defaultColumns);
        }
        

    }, []);

    const handleSelectedColumns = (selectedColumns) => {
        setSelectedColumns(selectedColumns);
    };

    const toUrlParams = (array) => {
        const filterString = JSON.stringify(array);

        return `filter=${encodeURIComponent(filterString)}`;
    };

    const handleApplyFilters = async (filters) => {
       
        const formattedArray = Object.entries(filters).map(([key, value]) => ({
            field: key,
            value: value
        }));
        
        const urlParams = toUrlParams(formattedArray);

        setSelectedFilterValue(urlParams);
        fetchData('filter',urlParams)
    };

    return (
        <div>
            <h2 className=" mt-10 text-lg font-medium text-left">Smart Devices</h2>
            <div className="mt-5 grid grid-cols-12 gap-6">
                <div className=" col-span-12 mt-2 flex flex-wrap items-center sm:flex-nowrap">
                    {usePermission('smartDevices', 'add') && (
                        <Link to="/smart-device/create" data-tw-merge="" className="transition duration-200 border inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-primary border-primary text-white dark:border-primary mr-2 shadow-md"><Plus size={20} className='mr-2' />Add New Device</Link>
                    )}
                    
                    

                    <div data-tw-merge data-tw-placement="bottom-end" className="dropdown relative">
                        <select 
                            onChange={ handleStatusChange}
                            className="disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 ml-2 !box">
                            {Object.entries(terminalTabStatus).map(([key, value]) => (
                                <option key={key} value={key}>{value}</option>
                                ))}
                        </select>
                    </div>
                    
                    <div className="flex mt-3 w-full sm:ml-auto sm:mt-0 sm:w-auto">

                        <ExportDropdown
                            data='terminal'
                        />
                
                        <MultiSearchFilter 
                            selectedFilterColumns={selectedFilterColumns}    
                            onApplyFilters={handleApplyFilters} 
                            fetchData={fetchData}
                        />

                        <div className="relative w-56 text-slate-500">
                            {/* <input data-tw-merge="" type="text" placeholder="Search..." className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10 !box w-56 pr-10" /> */}
                            <input
                                type="text"
                                placeholder="Search by name..."
                                value={searchTerm}
                                onChange={handleSearch}
                                className="transition duration-200 ease-in-out text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10 !box w-56 pr-10"
                            />
                            <Search size={15} color="#475569" className='stroke-1.5 absolute inset-y-0 right-0 my-auto mr-3 h-4 w-4' />
                        </div>
                        
                    </div>
                </div>

                <div className=" col-span-12 lg:col-span-6 overflow-auto">
                    
                </div>

                <div className="col-span-12 lg:col-span-6 text-right">
                    <ColumnFilter 
                        columnsData={columns} 
                        onSelectedColumnsChange={handleSelectedColumns}
                        selectedColumns={selectedColumns}
                        storageName={'TerminalFilterSelectedColumns'}
                    />  
                </div>

                <div className="col-span-12 overflow-auto ">
                    <table data-tw-merge="" className="w-full text-left -mt-2 border-separate border-spacing-y-[10px]">
                        <thead data-tw-merge="" className="">
                            <tr data-tw-merge="" className="">
                                {
                                    selectedColumns.map((col) => (
                                        <th
                                            key={col.field}
                                            className="font-medium px-5 py-3 dark:border-darkmode-300 whitespace-nowrap border-b-0 text-uppar"
                                            onClick={() => handleSort(col.field)}
                                        >
                                            <div className='flex justify-space-between group'>
                                                {col.title}
                                                {col.sort && (
                                                    <FontAwesomeIcon 
                                                        icon={renderSortIcon(col.field,col.sort)} 
                                                        className="m-2 light-gray" 
                                                    />
                                                )}
                                                </div>
                                        </th>
                                    ))
                                }
                                <th className="font-medium px-5 py-3 dark:border-darkmode-300 border-b-0 whitespace-nowrap text-uppar">
                                    <div className='flex justify-space-between'>Action</div>
                                </th>
                            </tr>
                        </thead>
                        <tbody className=''>
                            {terminalData.map((terminal, index) =>
                                <tr data-tw-merge="" className="" key={`terminal_${index}`}>
                                    {selectedColumns.map((col) => (
                                        (col.field === 'createdAt')
                                        ? (
                                            <td key={col.field} data-tw-merge="" className="px-5 py-3 border-b dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0  shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600 capitilize w-60">
                                                {(terminal.createdAt ? formatDateTime(terminal.createdAt) : '-')}
                                            </td>
                                        ) 
                                        :(col.field === 'is_connect')
                                        ? (
                                            <td key={col.field} data-tw-merge="" className="px-5 py-3 border-b dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0  shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600 capitilize w-60">
                                                {terminal.is_connect === 1 &&
                                                    <div className="flex items-center text-success">
                                                        <Monitor size={15} color="#0d9488" className='mr-2' />Connect
                                                    </div>
                                                }
                                                {terminal.is_connect === 0 &&
                                                    <div>
                                                        <div className="flex items-center text-danger">
                                                            <MonitorOff size={15} color="#dc2626" className='mr-2' />Disconnect
                                                        </div>
                            
                                                            <span className='text-slate-500 text-xs mt-0.5 p-5'>From ({calculateTimeDifference(terminal.last_acknoledge_time)})</span>
                                                        
                                                    </div>
                                                }
                                            </td>
                                        ) 
                                        : (col.field === 'status') 
                                            ? (
                                                <td key={col.field} data-tw-merge="" className="px-5 py-3 border-b dark:border-darkmode-300 box w-40 rounded-l-none rounded-r-none border-x-0 shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600 capitilize">
                                                    {terminal.status === '1' &&
                                                        <div className="flex items-center text-success">
                                                            <SquareCheckBig size={15} color="#0d9488" className='mr-2' />Active
                                                        </div>
                                                    }

                                                    {terminal.status === '2' &&
                                                        <div className="flex items-center text-danger">
                                                            <SquareCheckBig size={15} color="#dc2626" className='mr-2' />
                                                            Inactive
                                                        </div>
                                                    }
                                                    {terminal.status === '3' &&
                                                        <div className="flex items-center text-pending">
                                                            <SquareCheckBig size={15} color="#f97316" className='mr-2' />
                                                            Repair
                                                        </div>
                                                    }
                                                    {terminal.status === '4' &&
                                                        <div className="flex items-center text-info">
                                                            <SquareCheckBig size={15} color="#06b6d4" className='mr-2' />
                                                            Unassigned
                                                        </div>
                                                    }
                                                </td>
                                            ) 

                                            : (col.field === 'layout') 
                                            ? (
                                                <td key={col.field} data-tw-merge="" className="px-5 py-3 border-b dark:border-darkmode-300 box w-40 rounded-l-none rounded-r-none border-x-0 shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600 capitilize">
                                                    {terminal.layout === '1' &&
                                                        <div className="flex items-center">
                                                            <Lock size={15} className='mr-2' />8 Lockers
                                                        </div>
                                                    }

                                                    {terminal.layout === '2' &&
                                                        <div className="flex items-center">
                                                            <Lock size={15} className='mr-2' />
                                                            16 Lockers
                                                        </div>
                                                    }
                                                    
                                                </td>
                                            ) 
                                        : (
                                            <td key={col.field} className="px-5 py-3 border-b dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600 w-60">
                                                
                                                {(() => {
                                                    let value = terminal[col.field];
                                                    
                                                    if (col.subField && typeof value === 'object' && value !== null) {
                                                        value = value[col.subField];
                                                    }
                                            
                                                    if (value === null || value === undefined) {
                                                        value = '-';
                                                    }
                                                    return typeof value === 'object' ? '-' : value;
                                                })()}
                                                
                                            </td>
                                        ) 
                                    ))}
                                    <td data-tw-merge="" className="px-5 py-3 border-b dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600 before:absolute before:inset-y-0 before:left-0 before:my-auto before:block before:h-8 before:w-px before:bg-slate-200 before:dark:bg-darkmode-400 w-10">
                                        <div className="flex items-center justify-center">

                                            <ActionDropdown 
                                                terminal={terminal} 
                                                isAccess={isAccess}
                                                handleDownloadCertificate={handleDownloadCertificate}
                                                openModal={openModal}
                                                showSwal={showSwal}
                                            />

                                            {/* <div data-tw-merge="" data-tw-placement="bottom-end" className="dropdown relative">
                                                <button data-tw-merge=""
                                                    className="transition duration-200"
                                                    onClick={() => toggleActionDropdown(index)}
                                                    ref={(el) => setDropdownRef(el, index)}
                                                >
                                                    <span className="flex h-5 w-5 items-center justify-center">
                                                        <Ellipsis color="#475569" />
                                                    </span>
                                                </button>
                                                {actionDropdownOpen[index] && (

                                                    <div data-transition="" data-selector=".show" data-enter="transition-all ease-linear duration-150" data-enter-from="absolute !mt-5 invisible opacity-0 translate-y-1" data-enter-to="!mt-1 visible opacity-100 translate-y-0" data-leave="transition-all ease-linear duration-150" data-leave-from="!mt-1 visible opacity-100 translate-y-0" data-leave-to="absolute !mt-5 invisible opacity-0 translate-y-1" className="dropdown-menu absolute z-[9999]">
                                                        <div data-tw-merge="" className="dropdown-content rounded-md border-transparent bg-white p-2 shadow-[0px_3px_10px_#00000017] dark:border-transparent dark:bg-darkmode-600 w-55 action-dropdown">
                                                            {isAccess.edit && (
                                                                <Link to={'/terminal/edit/' + terminal.id} className="cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md hover:bg-slate-200/60 dark:bg-darkmode-600 dark:hover:bg-darkmode-400 dropdown-item">
                                                                    <SquareCheckBig size={15} color="#475569" className='mr-2' /> Edit
                                                                </Link>
                                                            )}
                                                            <Link to={'/terminal/show/'+ terminal.id } className="cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md hover:bg-slate-200/60 dark:bg-darkmode-600 dark:hover:bg-darkmode-400 dropdown-item">
                                                                <Eye size={15} color="#475569" className='mr-2' /> Show
                                                            </Link>


                                                            <Link onClick={() => openModal(terminal,'show')} className="cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md hover:bg-slate-200/60 dark:bg-darkmode-600 dark:hover:bg-darkmode-400 dropdown-item">
                                                                <FileLock2 size={15} color="#475569" className='mr-2' /> View Locker
                                                            </Link>
                                                            {isAccess.upgrade && (
                                                                <Link onClick={() => showSwal(terminal)} className="cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md hover:bg-slate-200/60 dark:bg-darkmode-600 dark:hover:bg-darkmode-400 dropdown-item">
                                                                    <Cpu size={15} color="#475569" className='mr-2' /> Upgrade
                                                                </Link>
                                                            )}
                                                            {isAccess.downloadCertificate && (
                                                                <Link onClick={() => handleDownloadCertificate(terminal)}  className="cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md hover:bg-slate-200/60 dark:bg-darkmode-600 dark:hover:bg-darkmode-400 dropdown-item">
                                                                    <Download size={15} color="#475569" className='mr-2' /> Download Certificate
                                                                </Link>
                                                            )}
                                                        </div>
                                                    </div>

                                                )}
                                            </div> */}
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

                <div className=" col-span-12 flex flex-wrap items-center sm:flex-row sm:flex-nowrap">
                    <nav className="w-full sm:mr-auto sm:w-auto">
                        <ul className="flex w-full mr-0 sm:mr-auto sm:w-auto">
                            <li className="flex-1 sm:flex-initial">
                                <button
                                    onClick={handleFirstPage}
                                    disabled={currentPage === 1}
                                    className="transition duration-200 border items-center justify-center py-2 rounded-md cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed min-w-0 sm:min-w-[40px] shadow-none font-normal flex border-transparent text-slate-800 sm:mr-2 dark:text-slate-300 px-1 sm:px-3"
                                >
                                    <ChevronsLeft className="w-4 h-4" />
                                </button>
                            </li>
                            <li className="flex-1 sm:flex-initial">
                                <button
                                    onClick={handlePreviousPage}
                                    disabled={currentPage === 1}
                                    className="transition duration-200 border items-center justify-center py-2 rounded-md cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed min-w-0 sm:min-w-[40px] shadow-none font-normal flex border-transparent text-slate-800 sm:mr-2 dark:text-slate-300 px-1 sm:px-3"
                                >
                                    <ChevronLeft className="w-4 h-4" />
                                </button>
                            </li>
                            <li data-tw-merge="" className="page-item">
                                <span className="transition duration-200 border items-center justify-center py-2 rounded-md cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed min-w-0 sm:min-w-[40px] shadow-none font-normal flex border-transparent text-slate-800 sm:mr-2 dark:text-slate-300 px-1 sm:px-3">{currentPage}</span>
                            </li>
                            <li className="flex-1 sm:flex-initial">
                                <button
                                    onClick={handleNextPage}
                                    disabled={currentPage === totalPages || totalPages === 0}
                                    className="transition duration-200 border items-center justify-center py-2 rounded-md cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed min-w-0 sm:min-w-[40px] shadow-none font-normal flex border-transparent text-slate-800 sm:mr-2 dark:text-slate-300 px-1 sm:px-3"
                                >
                                    <ChevronRight className="w-4 h-4" />
                                </button>
                            </li>
                            <li className="flex-1 sm:flex-initial">
                                <button
                                    onClick={handleLastPage}
                                    disabled={currentPage === totalPages || totalPages === 0}
                                    className="transition duration-200 border items-center justify-center py-2 rounded-md cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed min-w-0 sm:min-w-[40px] shadow-none font-normal flex border-transparent text-slate-800 sm:mr-2 dark:text-slate-300 px-1 sm:px-3"
                                >
                                    <ChevronsRight className="w-4 h-4" />
                                </button>
                            </li>
                        </ul>
                    </nav>
                    <div className="text-slate-500 mr-2">
                        Showing {indexOfFirstItem + 1} to {Math.min(indexOfLastItem, terminalData.length)} of {allTerminalData.totalCount} entries
                    </div>
                    <select
                        value={itemsPerPage}
                        onChange={handleItemsPerPageChange}
                        className="disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 transition duration-200 ease-in-out text-sm border-slate-200 shadow-sm rounded-md py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 group-[.form-inline]:flex-1 !box mt-3 w-20 sm:mt-0"
                    >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                </div>

            </div>

            <div className="text-center">

                {isModalOpen && (
                    <ModelComponenet
                        show={isModalOpen}
                        data={modelSelectedData.id}
                        closeModal={closeModal}
                    />
                )}

                {isUpgradeModalOpen && (
                    <UpgradeModel
                        show={isUpgradeModalOpen}
                        closeModal={closeModal}
                        data={modelSelectedData}
                    />
                )}

                {isAssignModalOpen && (
                    <AssignTerminal
                        show={isAssignModalOpen}
                        closeModal={closeModal}
                        data={modelSelectedData}
                    />
                )}

            </div>
        </div>
    )
}

export default List
