import React from 'react'
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarChart = ({ graphData, type }) => {
    
    const valuesArray = Object.values(graphData);
    const keyArray = Object.keys(graphData);

    const data = {
        labels: keyArray,
        datasets: [
            {
                label: type,
                backgroundColor: "#1e3b8a",
                borderColor: "rgb(255, 99, 132)",
                data: valuesArray,
            },
        ],
    };

    return (
        <div>
            <Bar data={data} />
        </div>
    );
};

export default BarChart
