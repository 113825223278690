import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { store } from './store';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { selectAuthToken } from './store';

axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}`;

const state = store.getState();
const token = selectAuthToken(state);

axios.defaults.headers.common['Authorization'] = 'Bearer '+ token;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	// <React.StrictMode>
	//   <App />
	// </React.StrictMode>

	<Provider store={store}>
		<BrowserRouter>
			<App />
			<ToastContainer />
		</BrowserRouter>
	</Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
