import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { history, fetchWrapper, notifyError } from '../helpers';
import {setProfileImage } from './profileImage.slice';
import axios from 'axios';

// create slice

const name = 'auth-token';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const slice = createSlice({ name, initialState, reducers });


// exports

export const authActions = { ...slice.actions, ...extraActions };
export const authReducer = slice.reducer;

export const selectAuthToken = state => state['auth'].value;
export const selectIsLoading = state => state['auth'].isLoading;

// implementation

function createInitialState() {
    return {
        // initialize state from local storage to enable user to stay logged in
        value: isJSON(localStorage.getItem('auth-token')) ? JSON.parse(localStorage.getItem('auth-token')) : null,
        isLoading: false,
        role: JSON.parse(localStorage.getItem('role')) || null,
        org_id: JSON.parse(localStorage.getItem('org_id')) || null,
    }
}

function isJSON(str) {
    try {
        JSON.parse(str);
        return true;
    } catch (e) {
        return false;
    }
}

function createReducers() {
    return {
        setAuth,
        setLoading,
        setUserRole,
        setOrganisationId
    };

    function setAuth(state, action) {
        state.value = action.payload;
    }

    function setLoading(state, action) {
        state.isLoading = action.payload;
    }

    function setUserRole(state, action) {
        state.role = action.payload;
    }

    function setOrganisationId(state, action) {
        state.org_id = action.payload;
    }
}

function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}`;
    
    return {
        login: login(),
        logout: logout(),
        autoLogin: autoLogin(),
    };

    function login() {
        
        return createAsyncThunk(
            `${name}/login`,
            async function ({ email, password }, { dispatch }) {
                dispatch(authActions.setLoading(true));
                try {
                    const response = await fetchWrapper.post(`${baseUrl}/auth/login`, { email, password });
                    if(response.success === true){
                        // set auth user in redux state
                        dispatch(authActions.setAuth(response.authToken));
                        dispatch(authActions.setUserRole(response.user.role));
                        dispatch(authActions.setOrganisationId(response.user.org_id));
                        dispatch(setProfileImage('https://xperian-users-image.s3.ap-south-1.amazonaws.com/1721126854932-deodar.jpg')); // Dispatch action to update profile image
                        // store user details and jwt token in local storage to keep user logged in between page refreshes
                        
                        localStorage.setItem('auth-token', JSON.stringify(response.authToken));
                        localStorage.setItem('role', JSON.stringify(response.user.role));
                        localStorage.setItem('org_id', JSON.stringify(response.user.org_id));

                        // get return url from location state or default to home page
                        const { from } = history.location.state || { from: { pathname: '/' } };
                        history.navigate(from);
                    }else{
                        notifyError(response.message);
                    }
                } catch (errors) {
                    notifyError(errors.response.data.error[0]['msg']);
                } finally {
                    dispatch(authActions.setLoading(false));
                }
            }
        );
    }

    function logout() {
        return createAsyncThunk(
            `${name}/logout`,
            async function (arg, { dispatch }) {
                dispatch(authActions.setLoading(true));
                try {
                    await fetchWrapper.post(`${baseUrl}/auth/logout`);

                    dispatch(authActions.setAuth(null));
                    dispatch(authActions.setUserRole(null));
                    dispatch(authActions.setOrganisationId(null));
                    localStorage.removeItem('auth-token');
                    localStorage.removeItem('role');
                    localStorage.removeItem('org_id');
                    history.navigate('/login');
                } catch (error) {
                    notifyError(error.message);
                }finally {
                    dispatch(authActions.setLoading(false));
                }
            }
        );
    }

    function autoLogin() {
        return createAsyncThunk(
            `${name}/autoLogin`,
            async function (user_id, { dispatch }) {

                try {
                    const response = await fetchWrapper.get(`${baseUrl}/user/user-auto-login?user_id=${user_id}`);
                    if(response.success === true){
                        // set auth user in redux state
                        dispatch(authActions.setAuth(response.authToken));
                        dispatch(authActions.setUserRole(response.user.role));
                        dispatch(authActions.setOrganisationId(response.user.org_id));
                        dispatch(setProfileImage('https://xperian-users-image.s3.ap-south-1.amazonaws.com/1721126854932-deodar.jpg')); // Dispatch action to update profile image
                        // store user details and jwt token in local storage to keep user logged in between page refreshes
                        
                        localStorage.setItem('auth-token', JSON.stringify(response.authToken));
                        localStorage.setItem('role', JSON.stringify(response.user.role));
                        localStorage.setItem('org_id', JSON.stringify(response.user.org_id));

                        // get return url from location state or default to home page
                       
                        history.navigate('/');
                        window.location.reload()
                    }else{
                        notifyError(response.message);
                    }
                }catch (error) {
                    notifyError(error.message);
                }finally {
                    dispatch(authActions.setLoading(false));
                }

                    

                // axios({
                //     method: 'GET',
                //     url: `${baseUrl}/user/user-auto-login?${user_id}`
                // })
                // .then(function(response) {
                //     if(response.data.success === true){
                //         console.log(response)
                //     }
                // })
                // .catch(function (error){
                //     notifyError('Something went wrong');
                // });   
        
            }
        );
    }
}

