import { configureStore } from '@reduxjs/toolkit';

import { authReducer } from './auth.slice';
import profileImageReducer from './profileImage.slice';


export * from './auth.slice';

export const store = configureStore({
    reducer: {
        auth: authReducer,
        profileImage: profileImageReducer
    },
});

export const selectAuthToken = state => state.auth.value;

export const selectUserRole = (state) => state.auth.role;

export const selectOrgId = (state) => state.auth.org_id;