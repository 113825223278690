import React, { useState, useRef, useEffect } from 'react';
import { usePopper } from 'react-popper';
import { createPortal } from 'react-dom';
import { Search } from 'lucide-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp , faCaretDown  } from '@fortawesome/free-solid-svg-icons';
import { notifySuccess } from '../../helpers';

const ColumnFilter = ({columnsData, onSelectedColumnsChange, selectedColumns,storageName}) => {
    const [show, setShow] = useState(false);
    const [dropdownElement, setDropdownElement] = useState(null);
    const buttonRef = useRef(null);
    const { styles, attributes } = usePopper(buttonRef.current, dropdownElement, {
        placement: 'bottom-end',
    });
    // const [selectedData, setSelectedData] = useState(selectedColumns.map(col => col.field));
    const [searchTerm, setSearchTerm] = useState('');
    
    const handleToggle = () => {
        setShow(!show);
    };
    
    const handleDocumentClick = (e) => {
        if (buttonRef.current && buttonRef.current.contains(e.target)) {
            return;
        }
        if (dropdownElement && dropdownElement.contains(e.target)) {
            return;
        }
        setShow(false);
    };

    // useEffect(() => {
    //     if (show) {
    //         document.addEventListener('click', handleDocumentClick);
    //     } else {
    //         document.removeEventListener('click', handleDocumentClick);
    //     }
    //     return () => {
    //         document.removeEventListener('click', handleDocumentClick);
    //     };
    // }, [show]);

    // const handleSelectAll = (event) => {
    //     console.log('handleSelect all called');
    //     if (event.target.checked) {
    //         const checkbox_ids = columnsData.map(col => col.field);
    //         setSelectedData(checkbox_ids);
    //     } else {
    //         setSelectedData([]);
    //     }
    // };
    useEffect(() => {
        setSelectedData(selectedColumns.map(col => col.field));
    }, [selectedColumns]);

    const handleSelectAll = (event) => {
        if (event.target.checked) {
            const checkbox_ids = columnsData.map(col => col.field);
            setSelectedData(checkbox_ids);
            onSelectedColumnsChange(columnsData);
        } else {
            setSelectedData([]);
            onSelectedColumnsChange([]);
        }
    };

    // const handleSelect = (event, id) => {
    //     if (event.target.checked) {
    //         setSelectedData(prev => [...prev, id]);
    //     } else {
    //         setSelectedData(prev => prev.filter(fieldId => fieldId !== id));
    //     }

    //     onSelectedColumnsChange(
    //         columnsData.filter(column => updatedSelectedData.includes(column.field))
    //     );
    // };    

    const handleSelect = (event, id) => {
        let updatedSelectedData;
        if (event.target.checked) {
            updatedSelectedData = [...selectedData, id];
        } else {
            updatedSelectedData = selectedData.filter(fieldId => fieldId !== id);
        }
        setSelectedData(updatedSelectedData);
        onSelectedColumnsChange(
            columnsData.filter(column => updatedSelectedData.includes(column.field))
        );
    };

    const [selectedData, setSelectedData] = useState(selectedColumns.map(col => col.field));

    const filteredColumns = searchTerm 
        ? columnsData.filter(col =>
            col.title.toLowerCase().includes(searchTerm.toLowerCase())
        ) 
        : columnsData;

    const renderSortIcon = () => {
        return show ? faCaretUp : faCaretDown;
    };

    const close = () => {
        setShow(false);
    }

    const saveView = () => {
        localStorage.setItem(storageName, JSON.stringify(selectedColumns));
        notifySuccess('Column filter view stored successfully.');
        setShow(false);
    }

    return (
        
            <div data-tw-merge data-tw-placement="bottom-end" className="dropdown relative w-full">
                <button
                    ref={buttonRef}
                    onClick={handleToggle}
                    aria-expanded={show}
                    className="transition duration-200 border inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed text-primary border-primary text-white dark:border-primary mr-2 shadow-md"
                >
                    {selectedData.length} Column{selectedData.length !== 1 ? 's' : ''} Selected <FontAwesomeIcon icon={renderSortIcon()} className="pl-2" />
                </button>
                {show && createPortal(
                    <div
                        ref={setDropdownElement}
                        data-transition
                        data-selector=".show"
                        data-enter="transition-all ease-linear duration-150"
                        data-enter-from="absolute !mt-5 invisible opacity-0 translate-y-1"
                        data-enter-to="!mt-1 visible opacity-100 translate-y-0"
                        data-leave="transition-all ease-linear duration-150"
                        data-leave-from="!mt-1 visible opacity-100 translate-y-0"
                        data-leave-to="absolute !mt-5 invisible opacity-0 translate-y-1"
                        className="dropdown-menu absolute z-[9999]"
                        style={styles.popper}
                        {...attributes.popper}
                    >
                        <div data-tw-merge className="dropdown-content rounded-md border-transparent bg-white p-2 shadow-[0px_3px_10px_#00000017] dark:border-transparent dark:bg-darkmode-600 w-45-vh">

                        <div>
                            <div className="flex w-full sm:w-auto mt-3">
                                <div className="flex items-center mt-2 p-1">
                                    <div className='flex items-center mt-2'>
                                        <input 
                                            checked={columnsData.length > 0 && selectedData.length === columnsData.length}
                                            onChange={handleSelectAll} 
                                            type="checkbox" 
                                            className="transition-all duration-100 ease-in-out shadow-sm border-slate-200 cursor-pointer rounded focus:ring-4 focus:ring-offset-0 focus:ring-primary focus:ring-opacity-20 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&amp;[type='radio']]:checked:bg-primary [&amp;[type='radio']]:checked:border-primary [&amp;[type='radio']]:checked:border-opacity-10 [&amp;[type='checkbox']]:checked:bg-primary [&amp;[type='checkbox']]:checked:border-primary [&amp;[type='checkbox']]:checked:border-opacity-10 [&amp;:disabled:not(:checked)]:bg-slate-100 [&amp;:disabled:not(:checked)]:cursor-not-allowed [&amp;:disabled:not(:checked)]:dark:bg-darkmode-800/50 [&amp;:disabled:checked]:opacity-70 [&amp;:disabled:checked]:cursor-not-allowed [&amp;:disabled:checked]:dark:bg-darkmode-800/50" value="" />
                                    </div>
                                    <div className="relative w-56 text-slate-500 p-2">
                                        <input
                                            type="text"
                                            placeholder="Search"
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                            className="transition duration-200 ease-in-out text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10 !box w-56 pr-10"
                                        />
                                        <Search size={15} color="#475569" className='stroke-1.5 absolute inset-y-0 right-0 my-auto mr-3 h-4 w-4' />
                                    </div>
                                </div>
                            </div>
                            
                            <div className="columnFilter">
                                {filteredColumns.map((col) => (
                                <div className="flex items-center mt-2 p-1" key={col.field}>
                                    <input 
                                        id={"checkbox-switch-" + col.field} 
                                        type="checkbox" 
                                        checked={selectedData.includes(col.field)}
                                        onChange={(e) => handleSelect(e, col.field)}
                                        className="transition-all duration-100 ease-in-out shadow-sm border-slate-200 cursor-pointer rounded focus:ring-4 focus:ring-offset-0 focus:ring-primary focus:ring-opacity-20 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&amp;[type='radio']]:checked:bg-primary [&amp;[type='radio']]:checked:border-primary [&amp;[type='radio']]:checked:border-opacity-10 [&amp;[type='checkbox']]:checked:bg-primary [&amp;[type='checkbox']]:checked:border-primary [&amp;[type='checkbox']]:checked:border-opacity-10 [&amp;:disabled:not(:checked)]:bg-slate-100 [&amp;:disabled:not(:checked)]:cursor-not-allowed [&amp;:disabled:not(:checked)]:dark:bg-darkmode-800/50 [&amp;:disabled:checked]:opacity-70 [&amp;:disabled:checked]:cursor-not-allowed [&amp;:disabled:checked]:dark:bg-darkmode-800/50" value="" />
                                    <label htmlFor={"checkbox-switch-" + col.field} className="cursor-pointer ml-2">
                                        {col.title}
                                    </label>
                                </div>
                                ))}
                            </div>
                            
                            <hr />
                            <div className="mt-5 text-right">
                                <button onClick={() => close()} type="button" className="transition duration-200 border shadow-sm inline-flex items-center justify-center rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed text-xs py-1.5 px-2 bg-secondary/70 border-secondary/70 text-slate-500 dark:border-darkmode-400 dark:bg-darkmode-400 dark:text-slate-300 [&:hover:not(:disabled)]:bg-slate-100 [&:hover:not(:disabled)]:border-slate-100 [&:hover:not(:disabled)]:dark:border-darkmode-300/80 [&:hover:not(:disabled)]:dark:bg-darkmode-300/80 w-24 mb-2 mr-2">
                                    Close
                                </button>

                                <button onClick={() => saveView()} className="transition duration-200 border shadow-sm inline-flex items-center justify-center rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed text-xs py-1.5 px-2 bg-primary border-primary text-white dark:border-primary w-24 mb-2 mr-1">
                                    Save View
                                </button>
                            </div>
                        </div>

                        </div>
                    </div>,
                    document.body
                )}
                
            </div>
        
    );
};

export default ColumnFilter;
