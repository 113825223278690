import axios from 'axios';

export const fetchNameById = async (type, id) => {
    
    try {
        // const response = await fetch(`/dashboard/get-breadcrumb?type=${type}&id=${id}`);

        if(type === 'smart-device'){
            type = 'terminal';
        }else if(type === 'accounts' || type === 'sub-accounts'){
            type = 'organization';
        }else if(type === 'users'){
            type = 'employee';
        }else if(type === 'inventory'){
            type = 'assets';
        }else if(type === 'onsite-engineer'){
            type = 'ose';
        }

        const response = await axios({
            url: `/dashboard/get-breadcrumb?type=${type}&id=${id}`,
            method: 'GET',
            responseType: 'json',
        });

        if(response.data.success){
            return response.data.data.name
        }
    } catch (error) {
        console.error(`Error fetching ${type} name for ID ${id}:`, error);
        return id; // Fallback to the ID if there's an error
    }
};

export const capitalizeFirstLetter = async (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}
