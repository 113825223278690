import React from 'react'
import { Home, SquareTerminal, Building, Users, UserCog, CassetteTape,Cpu } from 'lucide-react';
import logoImage from '../../assets/images/logo.svg';
import { Link, useLocation } from 'react-router-dom';
import { usePermission } from '../../hooks/usePermission';
                        
const Sidebar = () => {
    var pathname = useLocation().pathname.split('/');
    pathname = pathname[1];

    return (
        <div>
            <nav className="side-nav hidden w-[80px] overflow-x-hidden pb-16 pr-5 md:block xl:w-[230px]">

                <Link to="/" className="flex items-center pt-4 pl-5 ">
                    <img className="w-6" src={logoImage} alt="Midone - Tailwind Admin Dashboard Template" />
                    <span className="hidden ml-3 text-lg text-white xl:block"> Smart Locker </span>
                </Link>
                <div className="my-6 side-nav__divider"></div>
                <ul>
                    
                    <li>
                        <Link to="/" 
                            className={`${pathname === '' ? 'side-menu side-menu--active' : 'side-menu' }`}
                            >
                            <div className="side-menu__icon">
                                <Home size={20} />
                            </div>
                            <div className="side-menu__title">
                                Home
                            </div>
                        </Link>
                    </li>
                    

                    {usePermission('smartDevices', 'list') && (
                        <li>
                            <Link to="smart-device" className={`${pathname === 'smart-device' ? 'side-menu side-menu--active' : 'side-menu' }`}
                                >
                                <div className="side-menu__icon">
                                    <SquareTerminal size={20} />
                                </div>
                                <div className="side-menu__title">
                                    Smart Devices
                                </div>
                            </Link>
                        </li>
                     )}
                    
                    
                    {usePermission('organization', 'list') && (
                        <li>
                            <Link to="accounts" className={`${pathname === 'accounts' ? 'side-menu side-menu--active' : 'side-menu' }`}>
                                <div className="side-menu__icon">
                                    <Building size={20} />
                                </div>
                                <div className="side-menu__title">
                                Accounts
                                </div>
                            </Link>
                        </li>
                    )}

                    {usePermission('subOrganization', 'list') && (
                        <li>
                            <Link to="sub-accounts" className={`${pathname === 'sub-accounts' ? 'side-menu side-menu--active' : 'side-menu' }`}>
                                <div className="side-menu__icon">
                                    <Building size={20} />
                                </div>
                                <div className="side-menu__title">
                                Sub-Accounts
                                </div>
                            </Link>
                        </li>
                    )}

                    {usePermission('ose', 'list') && (
                        <li>
                            <Link to="onsite-engineer" className={`${pathname === 'onsite-engineer' ? 'side-menu side-menu--active' : 'side-menu' }`}
                                >
                                <div className="side-menu__icon">
                                    <UserCog size={20} />
                                </div>
                                <div className="side-menu__title">
                                    Onsite Engineers
                                </div>
                            </Link>
                        </li>
                    )}

                    {usePermission('employee', 'list') && (
                        <li>
                            <Link to="users" className={`${pathname === 'users' ? 'side-menu side-menu--active' : 'side-menu' }`}
                                >
                                <div className="side-menu__icon">
                                    <Users size={20} />
                                </div>
                                <div className="side-menu__title">
                                Users
                                </div>
                            </Link>
                        </li>
                    )}

                    {usePermission('assets', 'list') && (
                        <li>
                            <Link to="inventory" className={`${pathname === 'inventory' ? 'side-menu side-menu--active' : 'side-menu' }`}
                                >
                                <div className="side-menu__icon">
                                    <CassetteTape size={20} />
                                </div>
                                <div className="side-menu__title">
                                Inventory
                                </div>
                            </Link>
                        </li>
                    )}

                    {usePermission('ota', 'list') && (
                        <li>
                            <Link to="ota" className={`${pathname === 'ota' ? 'side-menu side-menu--active' : 'side-menu' }`}
                                >
                                <div className="side-menu__icon">
                                    <Cpu size={20} />
                                </div>
                                <div className="side-menu__title">
                                OTA Firmware
                                </div>
                            </Link>
                        </li>
                    )}

                    
                    
                </ul>
            </nav>
        </div>
    )
}

export default Sidebar
