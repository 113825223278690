import React, { useState, useEffect } from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { notifyError,notifySuccess } from '../../helpers';
import axios from 'axios';
import { useNavigate, useParams } from "react-router-dom";
import { Link } from 'react-router-dom';


const Create = () => {

    // all constants
    const navigate = useNavigate();
    const { id } = useParams(); // Get the terminal ID from the URL params
    const isEditMode = !!id; // Determine if the form is in edit mode
    
     // Validation
     const validationSchema = Yup.object().shape({
        sw_version: Yup.string()
            .required('Version number is required')
            .matches(/^\d+\.\d+\.\d+$/, 'Invalid version number. It should be in the format major.minor.patch (e.g., 1.0.0)'),
        file: Yup.mixed()
            .required('File is required')
            .test('fileType', 'Only ZIP files are allowed', value => {
                return value && value[0] && value[0].type === 'application/zip';
            }),
        checksum: Yup.string().required('Checksum is required')
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, setValue, reset, formState, setError } = useForm(formOptions);
    const { errors, isSubmitting } = formState;
    const [loading, setLoading] = useState(true);
   
    useEffect(() => {
        // Fectching organisation data for dropdown

        const fetchOta = async () => {
            if (isEditMode) {
                try {
                    const response = await axios.get(`/ota/get-ota-details/${id}`);
                    if (response.data.success) {
                        const fields = ['sw_version', 's3_filepath','id'];
                        fields.forEach(field => setValue(field, response.data.result[field]));
                        
                    }
                } catch (err) {
                    notifyError(err.message);
                }
            }
        };

        fetchOta();
        
    },[id, isEditMode, setValue]);

    const onSubmit = async (data) => {
        const payload = new FormData();

        payload.append('sw_version', data.sw_version);
        payload.append('file', data.file[0]);
        payload.append('checksum', data.checksum);


        var url = '/ota/create-ota';
        var method = 'post';

        if (isEditMode) {
            url = `/ota/update-ota/${id}`;
            method = 'put';
        }

        axios({
            method: method,
            url: url,
            data: payload, 
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(function(response) {
            if(response.data.success === true){
                
                if (isEditMode) {
                    notifySuccess('OTA Updated Sucsessfully');
                }else{
                    notifySuccess('OTA Added Sucsessfully');
                }
                navigate('/ota')
            }
        })
        .catch(function (error){
            error.response.data.details.map(error =>{
                error.map(err =>{
                    setError(err['field'], {
                        type: err['field'],
                        message:err['message']
                    });
                })
            })

            notifyError('Something went wrong');
        });      
    }

  return (
    <div>   
         <form onSubmit={handleSubmit(onSubmit)}>
            <div className="preview-component intro-y box mt-5">
                <div className="flex flex-col items-center border-b border-slate-200/60 p-5 dark:border-darkmode-400 sm:flex-row">
                    <h2 className="mr-auto text-base font-medium">
                        Add OTA Info
                    </h2>
                </div>

                <div className="p-5" >
                <p className="text-left">Note : * Denotes manadatory information</p>
                    <br />
                    <div className='mt-5 grid grid-cols-12 gap-6'>
                        
                        <div className='col-span-12 lg:col-span-8'>
                            <div className="preview relative [&.hide]:overflow-hidden [&.hide]:h-0">
                                <div data-tw-merge="" className="block sm:flex items-center group form-inline">
                                    <label data-tw-merge="" htmlFor="horizontal-form-1" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-20">
                                        Title *
                                    </label>
                                    <input  id="horizontal-form-1" name="title" type="text" {...register('title')} placeholder="Enter Title" className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10" />
                                </div>
                                <div className="text-danger text-right mt-2">{errors.title?.message}</div>
                            </div>
                        </div>

                        <div className='intro-y col-span-12 lg:col-span-8'>
                            <div className="preview relative [&.hide]:overflow-hidden [&.hide]:h-0">
                                <div data-tw-merge="" className="block sm:flex items-center group form-inline">
                                    <label data-tw-merge="" htmlFor="horizontal-form-1" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-20">
                                        Version*
                                    </label>
                                   
                                    <input  id="horizontal-form-1" name='sw_version' type="text" {...register("sw_version")} placeholder="Enter Version" className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10" />
                                </div>
                                <div className="text-danger text-right mt-2">{errors.sw_version?.message}</div>
                            </div>
                        </div>

                        <div className='intro-y col-span-12 lg:col-span-8'>
                            <div className="preview relative [&.hide]:overflow-hidden [&.hide]:h-0">
                                <div data-tw-merge="" className="block sm:flex items-center group form-inline">
                                    <label data-tw-merge="" htmlFor="horizontal-form-1" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-20">
                                        File name *
                                    </label>

                                    <input id="multiple_files" type="file" {...register("file")} accept=".zip" className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" />

                                </div>
                                <div className="text-danger text-right mt-2">{errors.file?.message}</div>
                            </div>
                        </div>
                        <div className='intro-y col-span-12 lg:col-span-8'>
                            <div className="preview relative [&.hide]:overflow-hidden [&.hide]:h-0">
                                <div data-tw-merge="" className="block sm:flex items-center group form-inline">
                                    <label data-tw-merge="" htmlFor="horizontal-form-1" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-20">
                                        Release Tags
                                    </label>
                                    <input  id="horizontal-form-1" name="release_tags" type="text" placeholder="Enter Release tags" className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10" />
                                </div>
                                
                            </div>
                        </div>
                        
                        <div className='intro-y col-span-12 lg:col-span-8'>
                            <div className="preview relative [&.hide]:overflow-hidden [&.hide]:h-0">
                                <div data-tw-merge="" className="block sm:flex items-center group form-inline">
                                    <label data-tw-merge="" htmlFor="horizontal-form-1" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-20">
                                        Checksum
                                    </label>
                                    <input  id="horizontal-form-1" name="checksum" type="text" {...register('checksum')} placeholder="Enter Checksum" className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10" />
                                </div>
                                <div className="text-danger text-right mt-2">{errors.checksum?.message}</div>
                            </div>
                        </div>
                        
                    </div>
                    <div className="mt-5 text-right">
                        <Link to="/ota"  type="button" className="transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&amp;:hover:not(:disabled)]:bg-opacity-90 [&amp;:hover:not(:disabled)]:border-opacity-90 [&amp;:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed border-secondary text-slate-500 dark:border-darkmode-100/40 dark:text-slate-300 [&amp;:hover:not(:disabled)]:bg-secondary/20 [&amp;:hover:not(:disabled)]:dark:bg-darkmode-100/10 mr-1 w-24 mr-2">Cancel</Link>
                        <button disabled={isSubmitting} className="transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&amp;:hover:not(:disabled)]:bg-opacity-90 [&amp;:hover:not(:disabled)]:border-opacity-90 [&amp;:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-primary border-primary text-white dark:border-primary w-24">
                        {isSubmitting && <span className="spinner-border spinner-border-sm me-1"></span>}
                        {isEditMode ? 'Update' : 'Save'}</button>
                    </div>
                </div>
            </div>
        </form>
        
    </div>
  )
}

export default Create
