import React, { useEffect, useState, useRef } from "react";
import TomSelect from "tom-select";
import axios from "axios";

// Debounce function
const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
        if (timeoutId) clearTimeout(timeoutId);
        timeoutId = setTimeout(() => func(...args), delay);
    };
};

const DynamicSearchDropdown = ({ onSelect, selectedData }) => {
    
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectInstance, setSelectInstance] = useState(null);
    const selectRef = useRef(null);

    // Debounced API call
    const fetchOptions = debounce(async (query, callback) => {
        if (query.length === 0) {
            callback([]);
            return;
        }

        try {
            const response = await axios.get("/employee/list-employee", {
                params: {
                    emp_search: true,
                    page_size: -1,
                    search: query,
                },
            });
            callback(response.data); // Pass the fetched data to TomSelect
        } catch (error) {
            console.error("Failed to load employee options", error);
            callback([]);
        }
    }, 300); // Adjust the delay as needed

    useEffect(() => {
        const select = new TomSelect("#search-dropdown", {
            valueField: "id",
            labelField: "name",
            searchField: ["name", "email", "employee_id"],
            options: options,
            maxOptions: 500,
            create: false,
            placeholder: "Search and Select an employee...",
            onChange: (value) => {
                handleOptionChange(value);
                if (onSelect) onSelect(value); // Pass the selected value to the parent
            },
            load: (query, callback) => {
                fetchOptions(query, callback); // Use the debounced function here
            },
            render: {
                option: function (data, escape) {
                    return `
            <div>
              <span class="font-semibold">${escape(data.full_name)}</span>
              <br>
              <span class="text-gray-500">${escape(data.email)}</span>
              <br>
              <span class="text-gray-400 text-sm">Employee ID: ${escape(data.emp_id)}</span>
            </div>
          `;
                },
                item: function (data, escape) {
                    return `
            <div>
              <span class="font-semibold">${escape(data.full_name)}</span>
              <br>
              <span class="text-gray-500">${escape(data.email)}</span>
              <br>
              <span class="text-gray-400 text-sm">Employee ID: ${escape(data.emp_id)}</span>
            </div>
          `;
                },
            },
        });

        setSelectInstance(select);

        return () => {
            select.destroy();
        };
    }, [options]);

    useEffect(() => {
        if (selectInstance && selectedData) {
            const normalizedData = {
                id: selectedData.id,
                full_name: selectedData.full_name || `${selectedData.first_name} ${selectedData.last_name}`,
                email: selectedData.email,
                emp_id: selectedData.emp_id,
            };
            
            setTimeout(() => {
                selectInstance.addOption(normalizedData); 
                selectInstance.setValue(normalizedData.id); 
            }, 100); 
        }
    }, [selectedData, selectInstance]);

    const handleOptionChange = (value) => {
        const selected = options.find((option) => option.id === value);
        setSelectedOption(selected);
    };

    return (
        <div className="w-full disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md py-2 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 group-[.form-inline]:flex-1 mt-2 sm:mr-2 mt-2 sm:mr-2'">
            <select ref={selectRef} id="search-dropdown" className='disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md py-2 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 group-[.form-inline]:flex-1 mt-2 sm:mr-2 mt-2 sm:mr-2'></select>
            
            {selectedOption && (
                <div className='disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md py-2 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 group-[.form-inline]:flex-1 mt-2 sm:mr-2 mt-2 sm:mr-2'>
                    <p><strong>Name:</strong> {selectedOption.full_name}</p>
                    <p><strong>Email:</strong> {selectedOption.email}</p>
                    <p><strong>Employee ID:</strong> {selectedOption.emp_id}</p>
                </div>
                
            )}
        </div>
    );
};

export default DynamicSearchDropdown;
