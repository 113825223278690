import { createSlice } from '@reduxjs/toolkit';
import defaultProfile from '../assets/images/default-profile.jpg';

const initialState = {
    url: defaultProfile
}

export const profileImageSlice = createSlice({
    name: 'profileImage',
    initialState,
    reducers: {
        setProfileImage: (state, action) => {
            state.url = action.payload;
        },
        resetProfileImage: () => initialState
    }
});

export const { setProfileImage, resetProfileImage } = profileImageSlice.actions;

export default profileImageSlice.reducer;