import React, {useEffect, useState} from 'react';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { notifyError,notifySuccess } from '../../helpers';
import axios from 'axios';
import { useNavigate, useParams } from "react-router-dom";
import { Link, useLocation } from 'react-router-dom';
import { Country, State, City } from 'country-state-city';
import Select from 'react-select';

function Create() {
    const navigate = useNavigate();
    const { id } = useParams(); // Get the terminal ID from the URL params
    const isEditMode = !!id; // Determine if the form is in edit mode

    var pathname = useLocation().pathname.split('/');
    pathname = pathname[1];
    
    const validationSchema = (isEditMode) => Yup.object().shape({
        email: Yup.string().required('Email is required').email(),
        name: Yup.string().required('Organisation name is required'),
        country: Yup.string().required('Country is required'),
        city: Yup.string().required('City is required'),
        state: Yup.string().required('State is required'),
        address:Yup.string().required('Address is Required'),
        contact: Yup.string().required('Contact number is required').matches(/^[0-9]{10}$/, 'Please enter a valid 10-digit Contact Number'),
        status:Yup.string().required('Status  field Required'),
        admin_contact: (isEditMode || pathname === 'sub-accounts')
            ? Yup.string()
            : Yup.string().required('Admin contact number is required').matches(/^[0-9]{10}$/, 'Please enter a valid 10-digit Contact Number'),
        admin_email:(isEditMode || pathname === 'sub-accounts')
            ? Yup.string()
            : Yup.string().required('Admin Email is required').email(),
        first_name: (isEditMode || pathname === 'sub-accounts')
            ? Yup.string()
            : Yup.string().required('Admin First name is required'),
        last_name: (isEditMode || pathname === 'sub-accounts')
            ? Yup.string()
            : Yup.string().required('Admin Last name is required'),
        user_name: (isEditMode || pathname === 'sub-accounts')
            ? Yup.string()
            : Yup.string().required('Username is required'),
    });

    // const formOptions = { resolver: yupResolver(validationSchema(isEditMode)) };
    // const { register, handleSubmit, setValue, formState } = useForm(formOptions);

    const { control, handleSubmit, register, isSubmitting, setError, watch, setValue, formState: { errors } } = useForm({
        defaultValues: {
            country: null,
            state: null,
            city: null,
        },
        resolver: yupResolver(validationSchema(isEditMode)),
    });
    // const { errors, isSubmitting } = formState;

    // start country code
    
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);

    const selectedCountry = watch('country');
    const selectedState = watch('state');
    const [initialData, setInitialData] = useState({});

    useEffect(() => {
        const allCountries = Country.getAllCountries().map((country) => ({
            value: country.isoCode,
            label: country.name,
        }));
        
        setCountries(allCountries);

        if (initialData) {
            const countryData = allCountries.find(c => c.label === initialData.country);
            if (countryData) {
                setValue('country', countryData.value);
            }
        }

    }, [initialData,setValue]);

    useEffect(() => {
        if (selectedCountry) {
            const countryIsoCode = selectedCountry;
            const allStates = State.getStatesOfCountry(countryIsoCode).map((state) => ({
                value: state.isoCode,
                label: state.name,
            }));
            setStates(allStates);
           
            if (initialData) {
                const stateData = allStates.find(s => s.label === initialData.state);
                if (stateData) {
                    setValue('state', stateData.value);
                }
            }

            setValue('city', null);   // Reset city value when country changes
            setCities([]);            // Clear cities when country changes
        } else {
            setStates([]);
            setValue('state', null);  // Reset state value when country is cleared
            setValue('city', null);   // Reset city value when country is cleared
            setCities([]);            // Clear cities when country is cleared
        }
    }, [selectedCountry, setValue]);

    useEffect(() => {
        if (selectedState && selectedCountry) {
            const stateIsoCode = selectedState;
            const countryIsoCode = selectedCountry;
            const allCities = City.getCitiesOfState(countryIsoCode, stateIsoCode).map((city) => ({
                value: city.name,
                label: city.name,
            }));
            setCities(allCities);
            if (initialData) {
                const cityData = allCities.find(c => c.label === initialData.city);
                if (cityData) {
                    setValue('city', cityData.value);
                }
            }
        } else {
            setCities([]);
            setValue('city', null);   // Reset city value when state is cleared
        }
    }, [selectedState, selectedCountry, setValue]);

    // end country code

    useEffect(() => {
        fetchOrganization()
    },[]);


    const fetchOrganization = async () => {
        if (isEditMode) {
            try {
                const response = await axios.get(`/organization/get-organization-detail/${id}`);
                if (response.data.success) {
                    const fields = ['name', 'email', 'city','country','state', 'address', 'contact', 'status','admin_contact','admin_contact','last_name','last_name','user_name'];
                    fields.forEach(field => setValue(field, response.data.result[field]));
                    setInitialData({
                        country: response.data.result['country'],  
                        state: response.data.result['state'],    
                        city: response.data.result['city'],
                    })

                }
            } catch (err) {
                notifyError(err.message);
            }
        }
    };

    const onSubmit = async (data) => {
        const payload = { ...data };

        const selectedCountryLabel = countries.find(c => c.value === data.country)?.label;
        const selectedStateLabel = states.find(s => s.value === data.state)?.label;
        const selectedCityLabel = cities.find(c => c.value === data.city)?.label;

        payload.country = selectedCountryLabel;
        payload.state = selectedStateLabel;
        payload.city = selectedCityLabel;
        
        var url = '/organization/create-organization';
        var method = 'post';
        if (isEditMode) {
            url = `/organization/update-organization/${id}`;
            method = 'put';
        }

        axios({
            method: method,
            url: url,
            data: payload,  
        })
        .then(function(response) {
            if(response.data.success === true){
                if (isEditMode) {
                    notifySuccess((pathname === 'accounts' ? 'Accounts Updated Sucsessfully' : 'Sub Accounts Updated Sucsessfully'));
                }else{
                    notifySuccess((pathname === 'accounts' ? 'Accounts Created Sucsessfully' : 'Sub Accounts Created Sucsessfully'));
                }

                if(pathname === 'accounts'){
                    navigate('/accounts');
                }else{
                    navigate('/sub-accounts');
                }
                
            }else{
                notifyError(response.data.message);
            }
        })
        .catch(function (error){
            error.response.data.details.forEach(innerArray => {
                innerArray.forEach(err => {
                    setError(err['field'], {
                        type: err['field'],
                        message: err['message']
                    });
                });
            });
            notifyError('Something Went wrong');
        });      
    }

  return (
    <div>   
         <form onSubmit={handleSubmit(onSubmit)}>
            <div className="preview-component intro-y box mt-5">
                <div className="flex flex-col items-center border-b border-slate-200/60 p-5 dark:border-darkmode-400 sm:flex-row">
                    <h2 className="mr-auto text-base font-medium">
                        {( pathname == 'sub-accounts' ? 'Add Sub Accounts Info' : 'Add Accounts Info')}
                    </h2>
                </div>

                <div className="p-5" >
                    <p className="text-left">Note : * Denotes manadatory information</p>
                    <br />
                    <div className='mt-5 grid grid-cols-12 gap-6'>
                        
                        <div className='col-span-12 lg:col-span-6'>
                            <div className="preview relative [&.hide]:overflow-hidden [&.hide]:h-0">
                                <div data-tw-merge="" className="block sm:flex items-center group form-inline">
                                    <label data-tw-merge="" htmlFor="horizontal-form-1" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-20">
                                        Name *
                                    </label>
                                    <input  id="horizontal-form-1" name="name" type="text" {...register('name')} placeholder="Enter organisation name" className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10" />
                                </div>
                                <div className="text-danger text-right mt-2">{errors.name?.message}</div>
                            </div>
                        </div>
                        <div className='intro-y col-span-12 lg:col-span-6'>
                            <div className="preview relative [&.hide]:overflow-hidden [&.hide]:h-0">
                                <div data-tw-merge="" className="block sm:flex items-center group form-inline">
                                    <label data-tw-merge="" htmlFor="horizontal-form-1" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-20">
                                        Email *
                                    </label>
                                    <input  id="horizontal-form-1" name="email" {...register('email')} type="text" placeholder="Enter organisation email" className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10" />
                                </div>
                                <div className="text-danger text-right mt-2">{errors.email?.message}</div>
                            </div>
                        </div>
                        <div className='intro-y col-span-12 lg:col-span-6'>
                            <div className="preview relative [&.hide]:overflow-hidden [&.hide]:h-0">
                                <div data-tw-merge="" className="block sm:flex items-center group form-inline">
                                    <label data-tw-merge="" htmlFor="horizontal-form-1" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-20">
                                        Country *
                                    </label>
                                    <Controller
                                        name="country"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                id="country"
                                                {...field}
                                                options={countries}
                                                placeholder="Select Country"
                                                className='disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 group-[.form-inline]:flex-1 mt-2 sm:mr-2 mt-2 sm:mr-2'
                                                isSearchable={true}
                                                onChange={(option) => {
                                                    field.onChange(option ? option.value : null);
                                                    setValue('country', option ? option.value : null);
                                                }}
                                                value={countries.find(option => option.value === field.value)}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="text-danger text-right mt-2">{errors.country?.message}</div>
                            </div>
                        </div>
                        <div className='intro-y col-span-12 lg:col-span-6'>
                            <div className="preview relative [&.hide]:overflow-hidden [&.hide]:h-0">
                                <div data-tw-merge="" className="block sm:flex items-center group form-inline">
                                    <label data-tw-merge="" htmlFor="horizontal-form-1" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-20">
                                        State *
                                    </label>
                                    <Controller
                                        name="state"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                id="state"
                                                {...field}
                                                options={states}
                                                placeholder="Select State"
                                                className='disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 group-[.form-inline]:flex-1 mt-2 sm:mr-2 mt-2 sm:mr-2'
                                                isSearchable={true}
                                                onChange={(option) => {
                                                    field.onChange(option ? option.value : null);
                                                    setValue('state', option ? option.value : null);
                                                }}
                                                value={states.find(option => option.value === field.value)}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="text-danger text-right mt-2">{errors.state?.message}</div>
                            </div>
                        </div>
                        <div className='intro-y col-span-12 lg:col-span-6'>
                            <div className="preview relative [&.hide]:overflow-hidden [&.hide]:h-0">
                                <div data-tw-merge="" className="block sm:flex items-center group form-inline">
                                    <label data-tw-merge="" htmlFor="horizontal-form-1" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-20">
                                        City *
                                    </label>
                                    <Controller
                                        name="city"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                id="city"
                                                {...field}
                                                options={cities}
                                                placeholder="Select City"
                                                className='disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 group-[.form-inline]:flex-1 mt-2 sm:mr-2 mt-2 sm:mr-2'
                                                isSearchable={true}
                                                onChange={(option) => {
                                                    field.onChange(option ? option.value : null);
                                                    setValue('city', option ? option.value : null);
                                                }}
                                                value={cities.find(option => option.value === field.value)}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="text-danger text-right mt-2">{errors.city?.message}</div>
                            </div>
                        </div>
                        <div className='intro-y col-span-12 lg:col-span-6'>
                            <div className="preview relative [&.hide]:overflow-hidden [&.hide]:h-0">
                                <div data-tw-merge="" className="block sm:flex items-center group form-inline">
                                    <label data-tw-merge="" htmlFor="horizontal-form-1" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-20">
                                        Addess *
                                    </label>
                                    <textarea id="validation-form-6" name="address" placeholder="Enter Address" {...register('address')} className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&amp;:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10 form-control"></textarea>
                                </div>
                                <div className="text-danger text-right mt-2">{errors.address?.message}</div>
                            </div>
                        </div>
                        <div className='intro-y col-span-12 lg:col-span-6'>
                            <div className="preview relative [&.hide]:overflow-hidden [&.hide]:h-0">
                                <div data-tw-merge="" className="block sm:flex items-center group form-inline">
                                    <label data-tw-merge="" htmlFor="horizontal-form-1" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-20">
                                        Contact No.*
                                    </label>
                                   
                                    <input  id="horizontal-form-1" name='contact' type="text" {...register("contact")} placeholder="Enter Contact Number" className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10" />
                                </div>
                                <div className="text-danger text-right mt-2">{errors.contact?.message}</div>
                            </div>
                        </div>
                        <div className='intro-y col-span-12 lg:col-span-6'>
                            <div className="preview relative [&.hide]:overflow-hidden [&.hide]:h-0">
                                <div data-tw-merge="" className="block sm:flex items-center group form-inline">
                                    <label data-tw-merge="" htmlFor="horizontal-form-1" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-20">
                                        Status *
                                    </label>
                                    <select id='status' name='status' {...register('status')} data-tw-merge aria-label="Default select example" {...register('status')} className="disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 group-[.form-inline]:flex-1 mt-2 sm:mr-2 mt-2 sm:mr-2">
                                        <option value="">Select Status</option>
                                        <option value="1">Active</option>
                                        <option value="2">Inactive</option>
                                    </select>
                                </div>
                                <div className="text-danger text-right mt-2">{errors.status?.message}</div>
                            </div>
                        </div>
                    </div>
                    
                </div>

                {(pathname === 'accounts' && isEditMode === false ) && (
                <div className="flex flex-col items-center border-b border-slate-200/60 p-5 dark:border-darkmode-400 sm:flex-row">
                    <h2 className="mr-auto text-base font-medium">
                        Add Admin Info
                    </h2>
                </div>
                )}
                <div className="p-5" >
                    {(pathname === 'accounts' && isEditMode === false ) && (
                        <div className='mt-5 grid grid-cols-12 gap-6'>
                            <div className='col-span-12 lg:col-span-6'>
                                <div className="preview relative [&.hide]:overflow-hidden [&.hide]:h-0">
                                    <div data-tw-merge="" className="block sm:flex items-center group form-inline">
                                        <label data-tw-merge="" htmlFor="horizontal-form-1" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-20">
                                            First Name*
                                        </label>
                                        <input  id="horizontal-form-1" name="first_name" {...register('first_name')} type="text" placeholder="Enter Admin First Name" className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10" />
                                    </div>
                                    <div className="text-danger text-right mt-2">{errors.first_name?.message}</div>
                                </div>
                            </div>
                            <div className='col-span-12 lg:col-span-6'>
                                <div className="preview relative [&.hide]:overflow-hidden [&.hide]:h-0">
                                    <div data-tw-merge="" className="block sm:flex items-center group form-inline">
                                        <label data-tw-merge="" htmlFor="horizontal-form-1" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-20">
                                            Last Name*
                                        </label>
                                        <input  id="horizontal-form-1" name="last_name" {...register('last_name')} type="text" placeholder="Enter Admin Last Name" className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10" />
                                    </div>
                                    <div className="text-danger text-right mt-2">{errors.last_name?.message}</div>
                                </div>
                            </div>
                            <div className='intro-y col-span-12 lg:col-span-6'>
                                <div className="preview relative [&.hide]:overflow-hidden [&.hide]:h-0">
                                    <div data-tw-merge="" className="block sm:flex items-center group form-inline">
                                        <label data-tw-merge="" htmlFor="horizontal-form-1" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-20">
                                            Email*
                                        </label>
                                        <input  id="horizontal-form-1" name="admin_email" type="text" {...register('admin_email')} placeholder="Enter Admin email" className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10" />
                                    </div>
                                    <div className="text-danger text-right mt-2">{errors.admin_email?.message}</div>
                                </div>
                            </div>
                            <div className='intro-y col-span-12 lg:col-span-6'>
                                <div className="preview relative [&.hide]:overflow-hidden [&.hide]:h-0">
                                    <div data-tw-merge="" className="block sm:flex items-center group form-inline">
                                        <label data-tw-merge="" htmlFor="horizontal-form-1" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-20">
                                            Contact No.*
                                        </label>
                                        <input  id="horizontal-form-1" name='admin_contact' {...register('admin_contact')} type="text" placeholder="Enter Contact Number" className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10" />
                                    </div>
                                    <div className="text-danger text-right mt-2">{errors.admin_contact?.message}</div>
                                </div>
                            </div>
                            <div className='intro-y col-span-12 lg:col-span-6'>
                                <div className="preview relative [&.hide]:overflow-hidden [&.hide]:h-0">
                                    <div data-tw-merge="" className="block sm:flex items-center group form-inline">
                                        <label data-tw-merge="" htmlFor="horizontal-form-1" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-20">
                                            Username *
                                        </label>
                                        <input  id="horizontal-form-1" name='user_name' {...register('user_name')} type="text" placeholder="Enter User Name" className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10" />
                                    </div>
                                    <div className="text-danger text-right mt-2">{errors.user_name?.message}</div>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="mt-5 text-right">
                    
                        <Link to={pathname=='sub-accounts' ? '/sub-accounts' : '/accounts'}  type="button" className="transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&amp;:hover:not(:disabled)]:bg-opacity-90 [&amp;:hover:not(:disabled)]:border-opacity-90 [&amp;:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed border-secondary text-slate-500 dark:border-darkmode-100/40 dark:text-slate-300 [&amp;:hover:not(:disabled)]:bg-secondary/20 [&amp;:hover:not(:disabled)]:dark:bg-darkmode-100/10 mr-1 w-24 mr-2">Cancel</Link>
                        
                        <button disabled={isSubmitting} className="transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&amp;:hover:not(:disabled)]:bg-opacity-90 [&amp;:hover:not(:disabled)]:border-opacity-90 [&amp;:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-primary border-primary text-white dark:border-primary w-24">
                        {isSubmitting && <span className="spinner-border spinner-border-sm me-1"></span>}
                        Save</button>
                    </div>
                </div>
                
            </div>
        </form>
        
    </div>
  )
}

export default Create
