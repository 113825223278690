import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Dropzone, FileItem } from "@dropzone-ui/react";
import {Download} from 'lucide-react';
import { notifySuccess, notifyError } from '../../helpers';
import axios from 'axios';

const Import = () => {
    const [files, setFiles] = useState([]);
    const [error,setError] = useState([]);
    const navigate = useNavigate();

    const updateFiles = (incommingFiles) => {   
        
        const file = incommingFiles[0];
        setError();

        if (!ALLOWED_FILE_TYPES.includes(file.file.type)) {
            notifyError("Only CSV and XLSX files are allowed.");
            setError('Only CSV and XLSX files are allowed.');
            setFiles([]);
            return;
        }

        setError(null);
        setFiles(incommingFiles);
    };
    const removeFile = (id) => {
        setFiles(files.filter((x) => x.id !== id));
    };

    const ALLOWED_FILE_TYPES = ["text/csv", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];

    const handleFileUpload = async () => {
        if (files.length === 0) {
            notifyError("Please select a file to upload.");
            setError('Please select a file to upload');
            return;
        }

        const formData = new FormData();
        formData.append('file', files[0].file); // assuming only one file is uploaded


        try {
            axios({
                method: 'post',
                url: '/employee/uploadcsv',
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data:formData
            })
            .then(function(response) {
                if(response.data.success === true){
                    notifySuccess('File uploaded successfully!')
                    navigate('/users');
                }else{
                    if(response.data.details){
                        notifyError(response.data.details[0].message);
                    }
                    notifyError(response.data.message);
                }
            })
            .catch(function (error){
                let error_msg = error.response.data.details[0][0].message +": The '"+error.response.data.details[0][0].field+"' column contains a duplicate value "+ error.response.data.details[0][0].data;
                notifyError(error_msg);
                
            });      
            
        } catch (err) {
            notifyError(err.message);
        }
    };

    return (
        <div>

            <div className="preview-component intro-y box mt-5">
                <div className="flex flex-col items-center border-b border-slate-200/60 p-5 dark:border-darkmode-400 sm:flex-row">
                    <h2 className="mr-auto text-base font-medium">
                        Import Employee
                    </h2>
                </div>

                <div className="p-5" >

                    <div className='mt-5 grid grid-cols-12 gap-6'>
                        <div className='intro-y col-span-12 lg:col-span-6 px-9 py-3'>
                            <div className="block font-semibold text-primary">Step 1: Download Template</div>
                            <br />
                            <div className="font-normal">Click the button below to downlaod a template file to use for your bulk
                                form field import based. Once you fill out the file, return here and proceed step 2.
                            </div>
                            <br />
                            <br />
                            <div className='text-center mt-10'>
                                <a href='/assets/employee.csv' download data-tw-merge className="transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed text-lg py-1.5 px-4 bg-primary border-primary text-white dark:border-primary mb-2 mr-1 mb-2 mr-1">
                                <Download color="#FFF" className='mr-2' size={20}/> Download Template
                                </a>
                            </div>
                        </div>
                        <div className='intro-y col-span-12 lg:col-span-6 px-9 py-3 border-t border-dashed border-black border-opacity-10 md:border-l md:border-r md:border-t-0'>
                            <div className="block font-semibold text-primary">Step 2: Import Data</div>
                            <br />
                            <div className="font-normal">
                                Click the button below to select your completed CSV file
                            </div>
                            <br />
                            <br />
                            {/* <Dropzone onFilesSelected={handleFilesSelected} /> */}

                            <Dropzone
                                style={{ minWidth: "300px", border: "2px dashed #1E3A8A", fontSize: '15px' }}
                                label="Drag 'n' drop some files here, or click to select files"
                                onChange={updateFiles}
                                value={files}
                                maxFiles={1}
                            >
                                {files.length > 0 &&
                                    files.map((file) => (
                                        <FileItem {...file} onDelete={removeFile} key={file.id} info />
                                    ))}
                            </Dropzone>
                            <div className="text-danger text-right mt-2">{error}</div>
                            <br/>
                            <div  className='mt-5 flex import-data-description'>
                                <ul>
                                    <li>
                                        <div><strong>first_name</strong></div>
                                        <span>First name of the employee. Ex. John </span>
                                    </li>
                                    <li>
                                        <div><strong>last_name</strong></div>
                                        <span>Last name of the employee. Ex. Doe </span>
                                    </li>
                                    <li>
                                        <div><strong>emp_id</strong></div>
                                        <span>Employee Id of the employee. Ex. Emp001 </span>
                                    </li>
                                    <li>
                                        <div><strong>email</strong></div>
                                        <span>Email Id of the employee. Ex. john@gmail.com </span>
                                    </li>
                                    <li>
                                        <div><strong>contact</strong></div>
                                        <span>Mobile number of the employee. Ex. 1234567890 </span>
                                    </li>
                                    <li>
                                        <div><strong>rf_id</strong></div>
                                        <span>RFID of the employee. Ex. RF001 </span>
                                    </li>
                                    
                                </ul>
                            </div>
                            
                        </div>

                    </div>
                    <div className="mt-5 text-right">
                        <Link to="/users"  type="button" className="transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&amp;:hover:not(:disabled)]:bg-opacity-90 [&amp;:hover:not(:disabled)]:border-opacity-90 [&amp;:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed border-secondary text-slate-500 dark:border-darkmode-100/40 dark:text-slate-300 [&amp;:hover:not(:disabled)]:bg-secondary/20 [&amp;:hover:not(:disabled)]:dark:bg-darkmode-100/10 mr-1 w-24 mr-2">Cancel</Link>
                        <button onClick={handleFileUpload} className="transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&amp;:hover:not(:disabled)]:bg-opacity-90 [&amp;:hover:not(:disabled)]:border-opacity-90 [&amp;:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-primary border-primary text-white dark:border-primary w-24">
                            Import
                        </button>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Import
