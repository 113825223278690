import './App.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { history } from './helpers';
import useHistory from './hooks/useHistory';
import { useAuthToken } from './hooks/useAuthToken';
import AppRoutes from './routes/routes';

function App() {
	history.navigate = useNavigate();
    history.location = useLocation();
	useHistory();
	useAuthToken();
	return (
		<div className="App">
			<AppRoutes />
		</div>
	);
}

export default App;
