export  const TERMINAL_STATUS = {
    1 : 'Active',
    2 : 'Inactive',
    3 : 'Repair',
    4 : 'Unassigned'
}

export const ORGANIZATION_STATUS ={
    1: 'Active',
    2: 'Inactive'
}

export const OSE_STATUS ={
    1: 'Active',
    2: 'Inactive'
}

export const ASSETS_STATUS ={
    1: 'Created',
    2: 'Unassigned Dropped',
    3: 'Assigned',
    4: 'Collected',
    5: 'Cancelled',
    6: 'Custom'
}

export const ASSETS_TYPE = {
    1 : 'New Assets',
    2 : 'Return Assets',
    3 : 'Break Fix'
}

export const ASSETS_RETURN_TYPE = {
    6 : 'Return Created',
    7 : 'Return Unassigned Dropped',
    8 : 'Return Collected',
    9 : 'Return Cancelled'
}

export const All_ASSETS_STATUS = {
    1: 'Created',
    2: 'Unassigned Dropped',
    3: 'Assigned',
    4: 'Collected',
    5: 'Cancelled',
    6 : 'Return Created',
    7 : 'Return Unassigned Dropped',
    8 : 'Return Collected',
    9 : 'Return Cancelled'
}

export const BIN_TYPE = {
    1 : 'Small',
    2 : 'Medium',
    3 : 'Large' 
}

export const DOOR_STATUS = {
    1 : 'Open',
    2 : 'Close',
    3 : 'Not Close',
    4 : 'Not Open',
}
