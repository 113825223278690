export  const permissions = {
    //terminals
    smartDevices : {
        superadmin :{
            allowedAction : ['list','edit','view-locker','upgrade','show','add','download-certificate']
        },
        admin :{
            allowedAction : ['list','view-locker','show','assign-device']
        },
        sde :{
            allowedAction : ['list','view-locker','show']
        }
    },
    // accounts
    organization : {
        superadmin :{
            allowedAction : ['list','edit','show','add','export','list-admin']
        },
        admin :{
            allowedAction : ['list','edit','show','export','list-admin']
        },
        sde :{
            allowedAction : ['list','show','export']
        }
    },
    subOrganization : {
        // superadmin :{
        //     allowedAction : ['list','edit','show','add','export']
        // },
        admin :{
            allowedAction : ['list','edit','show','add','export']
        },
        // sde :{
        //     allowedAction : ['list','edit','show','add','export']
        // }
    },
    ose : {
        admin :{
            allowedAction : ['list','edit','delete','export','add','import','show']
        },
        sde :{
            allowedAction : ['list','edit','delete','export','add','import','show']
        }
    },
    sde : {
        admin :{
            allowedAction : ['list','edit','delete','export','add','send-invitation','auto-login']
        },
        sde:{
            allowedAction : ['list','edit','delete','export','add']
        }
        
    },
    organizationAdmin : {
        superadmin :{
            allowedAction : ['list','edit','delete','export','add','send-invitation']
        },
        admin :{
            allowedAction : ['list','edit','delete','export','add','auto-login']
        },
        
    },
    ota : {
        superadmin :{
            allowedAction : ['list','edit','delete','add'],
        },
    },
    employee : {
        admin :{
            allowedAction : ['list','edit','delete','add','import','export'],
        },
        sde:{
            allowedAction : ['list','edit','delete','add','import','export'],
        }
    },
    assets : {
        admin :{
            allowedAction : ['list','edit','delete','add','import','export','assign','show','audit'],
        },
        sde:{
            allowedAction : ['list','edit','delete','add','import','export','assign','show','audit'],
        }

    },
    dashboard : {
        superadmin :{
            allowedAction : ['list']
        },
        admin :{
            allowedAction : ['list','ose-link','employee-link']
        },

        sde :{
            allowedAction : ['list','ose-link','employee-link']
        }
    },

    profile : {
        superadmin :{
            allowedAction : ['edit-organization']
        },
        admin :{
            allowedAction : ['edit-organization']
        }
    }
        
}
