import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { history } from '../helpers';

const useHistory = () => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        history.navigate = navigate;
        history.location = location;
    }, [navigate, location]);
};

export default useHistory;