import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import axios from 'axios';
import { selectAuthToken } from '../store';

export const useAuthToken = () => {
    const token = useSelector(selectAuthToken);

    useEffect(() => {
        if (token) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        } else {
            delete axios.defaults.headers.common['Authorization'];
        }
    }, [token]);
};