import React, { useEffect, useState, useRef } from 'react';
import { ChevronsLeft, ChevronLeft, ChevronRight, ChevronsRight ,CircleArrowLeft } from 'lucide-react';
import axios from 'axios';
import { notifyError, notifySuccess } from '../../helpers';
import { Link, useParams } from 'react-router-dom';
import { formatDateTime } from '../../utils/DateUtils';

const AuditTrail = () => {

    const [AuditData, setData] = useState([]);
    const [allAuditData, setAllAuditData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');

    const {id}  = useParams();

    useEffect(() => {
        fetchData();
    },[itemsPerPage]);

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
        fetchData('search',event.target.value);
        
    };


    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const totalPages = allAuditData.totalPages;

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            let page = currentPage - 1;
            setCurrentPage(page);
            fetchData('pagination',page);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            let page = currentPage + 1;
            setCurrentPage(page);
            fetchData('pagination',page);
        }
    };

    const handleFirstPage = () => {
        setCurrentPage(1);
        fetchData('pagination',1);
    };

    const handleLastPage = () => {
        setCurrentPage(totalPages);
        fetchData('pagination',totalPages);
    };

    const handleItemsPerPageChange = (event) => {
        setItemsPerPage(parseInt(event.target.value));
        setCurrentPage(1);
        fetchData('pagination-page',event.target.value);
    };

    const fetchData = async (mode,status) => {

        var url = `/asset/list-asset-request/${id}`;

        if(mode == 'pagination'){
            url = url + '?page='+status+'&page_size='+itemsPerPage;
        }else{
            url = url + '?page='+currentPage+'&page_size='+itemsPerPage;
        }
        
        if (mode === 'status' && status !== undefined) {
            url = url + '&status=' + status;
        } else if (mode === 'search') {
            url = url + '&search=' + searchTerm;
        } 

        try {
            const response = await axios.get(url);
            if(response.data.success === true){
                setData(response.data.data.asset_request);
                setAllAuditData(response.data.data);
            }
            
        } catch (err) {
            // notifyError(err.message);
        }
        
    };

    return (
        <div>
            <div className="flex mt-10 items-center px-5 py-5 sm:py-3">
                <h2 className="mr-auto  text-base font-medium">Audit Report {allAuditData.asset_info ? 'of '+allAuditData.asset_info.asset_id : ''}</h2>
                
                <Link to="/inventory" className="transition duration-200 border inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-primary border-primary text-white dark:border-primary mr-2 shadow-md">
                    <CircleArrowLeft className='w-4 h-4 mr-2' size={20} color="#fff" />
                    Back to Inventory
                </Link>
            </div>
            <div className="mt-5 grid grid-cols-12 gap-6">
            
                <div className="col-span-12 overflow-auto  2xl:overflow-visible">
                    <table data-tw-merge="" className="w-full text-left -mt-2 border-separate border-spacing-y-[10px]">
                        <thead data-tw-merge="" className="">
                            <tr data-tw-merge="" className="">
                                <th className="font-medium px-5 py-3 dark:border-darkmode-300 border-b-0 whitespace-nowrap text-uppar">
                                    <div className='flex justify-space-between'>Event Type</div>
                                </th>

                                <th className="font-medium px-5 py-3 dark:border-darkmode-300 border-b-0 whitespace-nowrap text-uppar">
                                    <div className='flex justify-space-between'>User</div>
                                </th>

                                <th className="font-medium px-5 py-3 dark:border-darkmode-300 border-b-0 whitespace-nowrap text-uppar">
                                    <div className='flex justify-space-between'>Device</div>
                                </th>

                                <th className="font-medium px-5 py-3 dark:border-darkmode-300 border-b-0 whitespace-nowrap text-uppar">
                                    <div className='flex justify-space-between'>Locker Id</div>
                                </th>

                                <th className="font-medium px-5 py-3 dark:border-darkmode-300 border-b-0 whitespace-nowrap text-uppar">
                                    <div className='flex justify-space-between'>Created At</div>
                                </th>
                            </tr>
                        </thead>
                        <tbody className=''>
                            {AuditData.map((audit,index) =>
                                <tr data-tw-merge="" className="" key={`audit_${index}`}>
                                    
                                    <td data-tw-merge="" className="px-5 py-3 border-b dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600 capitilize">
                                        { audit.event_name }
                                    </td>
                                    <td data-tw-merge="" className="px-5 py-3 border-b dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600 capitilize">
                                        {audit.employee == null ? '-' :
                                            <div>
                                                <div className='font-medium whitespace-nowrap'>{audit.employee.full_name}</div>
                                                <div class="text-slate-500 text-xs whitespace-nowrap mt-0.5">{audit.employee.email}</div>
                                                <div class="text-slate-500 text-xs whitespace-nowrap mt-0.5">{audit.employee.contact}</div>
                                            </div>
                                        }
                                    </td>
                                    <td data-tw-merge="" className="px-5 py-3 border-b dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600 capitilize">
                                        {audit.terminus == null ? '-' : audit.terminus.term_name}

                                    </td>

                                    <td data-tw-merge="" className="px-5 py-3 border-b dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600 capitilize">
                                        {audit.bin_id == null ? '-' : audit.bin_id}

                                    </td>
                                    <td data-tw-merge="" className="px-5 py-3 border-b dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600">
                                        {audit.createdAt == null ? '-' : formatDateTime(audit.createdAt)}
                                    </td>
                                  
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

                <div className=" col-span-12 flex flex-wrap items-center sm:flex-row sm:flex-nowrap">
                    <nav className="w-full sm:mr-auto sm:w-auto">
                        <ul className="flex w-full mr-0 sm:mr-auto sm:w-auto">
                            <li className="flex-1 sm:flex-initial">
                                <button
                                    onClick={handleFirstPage}
                                    disabled={currentPage === 1}
                                    className="transition duration-200 border items-center justify-center py-2 rounded-md cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed min-w-0 sm:min-w-[40px] shadow-none font-normal flex border-transparent text-slate-800 sm:mr-2 dark:text-slate-300 px-1 sm:px-3"
                                >
                                    <ChevronsLeft className="w-4 h-4" />
                                </button>
                            </li>
                            <li className="flex-1 sm:flex-initial">
                                <button
                                    onClick={handlePreviousPage}
                                    disabled={currentPage === 1}
                                    className="transition duration-200 border items-center justify-center py-2 rounded-md cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed min-w-0 sm:min-w-[40px] shadow-none font-normal flex border-transparent text-slate-800 sm:mr-2 dark:text-slate-300 px-1 sm:px-3"
                                >
                                    <ChevronLeft className="w-4 h-4" />
                                </button>
                            </li>
                            <li data-tw-merge="" className="page-item">
                                <span className="transition duration-200 border items-center justify-center py-2 rounded-md cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed min-w-0 sm:min-w-[40px] shadow-none font-normal flex border-transparent text-slate-800 sm:mr-2 dark:text-slate-300 px-1 sm:px-3">{currentPage}</span>
                            </li>
                            <li className="flex-1 sm:flex-initial">
                                <button
                                    onClick={handleNextPage}
                                    disabled={currentPage === totalPages}
                                    className="transition duration-200 border items-center justify-center py-2 rounded-md cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed min-w-0 sm:min-w-[40px] shadow-none font-normal flex border-transparent text-slate-800 sm:mr-2 dark:text-slate-300 px-1 sm:px-3"
                                >
                                    <ChevronRight className="w-4 h-4" />
                                </button>
                            </li>
                            <li className="flex-1 sm:flex-initial">
                                <button
                                    onClick={handleLastPage}
                                    disabled={currentPage === totalPages}
                                    className="transition duration-200 border items-center justify-center py-2 rounded-md cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed min-w-0 sm:min-w-[40px] shadow-none font-normal flex border-transparent text-slate-800 sm:mr-2 dark:text-slate-300 px-1 sm:px-3"
                                >
                                    <ChevronsRight className="w-4 h-4" />
                                </button>
                            </li>
                        </ul>
                    </nav>
                    <div className="text-slate-500 mr-2">
                        Showing {indexOfFirstItem + 1} to {Math.min(indexOfLastItem, AuditData.length)} of {allAuditData.totalCount} entries
                    </div>
                    <select
                            value={itemsPerPage}
                            onChange={handleItemsPerPageChange}
                            className="disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 transition duration-200 ease-in-out text-sm border-slate-200 shadow-sm rounded-md py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 group-[.form-inline]:flex-1 !box mt-3 w-20 sm:mt-0"
                        >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                </div>

            </div>

            
        </div>
    )
}

export default AuditTrail
