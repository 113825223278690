import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {Settings, LogOut, CircleUserRound} from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../../store/auth.slice'; 

import { selectUserRole } from '../../store';

const Dropdown = (props) => {

    const [isVisible, setIsVisible] = useState(false);
    const buttonRef = useRef(null);
    const [isLoggedIn, setIsLoggedIn] = useState(true);
    const role = useSelector(selectUserRole);
	
    const onHideShowClick = () => {
        setIsVisible(!isVisible);
    };

    const dispatch = useDispatch();

    const handleLogout = () => {
        setIsLoggedIn(false);
        dispatch(authActions.logout());
    };

    const handleDocumentClick = (e) => {
        if (buttonRef.current && buttonRef.current.contains(e.target)) {
            return;
        }
        setIsVisible(false);
    };

    useEffect(() => {
    
        if (isVisible) {
            document.addEventListener('click', handleDocumentClick);
        } else {
            document.removeEventListener('click', handleDocumentClick);
        }
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, [isVisible]);


    return (
        <div className="dropdown relative">
            <button
                ref={buttonRef}
                onClick={onHideShowClick}
                className="cursor-pointer image-fit zoom-in intro-x block h-8 w-8 overflow-hidden rounded-full shadow-lg"
            >
                <img src={props.logoImage} alt="profile-image" />
            </button>

            <div
                style={{
                    display: isVisible ? 'block' : 'none',
                    width: '224px',
                    position: 'absolute',
                    inset: '-33px 0px auto -142px',
                    margin: '0px',
                    transform: 'translate(-54px, 70px)',
                }}
                className="dropdown-menu absolute z-[9999] hidden"
            >
                <div className="dropdown-content rounded-md border-transparent p-2 shadow-[0px_3px_10px_#00000017] dark:border-transparent dark:bg-darkmode-600 mt-px w-56 bg-theme-1 text-white">
                    <div className="p-2 font-medium font-normal">
                        <div className="font-medium">{ props.userInfo.first_name +' '+ props.userInfo.last_name }</div>
                        <span>({props.userInfo.email})</span>
                        <div className="mt-0.5 text-xs text-white/70 dark:text-slate-500">
                            {role == 'superadmin' ? 'Super Admin' : (role == 'admin' ? 'Admin': (role == 'sde' ? 'SDE' : ''))}
                        </div>
                    </div>
                    <div className="h-px my-2 -mx-2 bg-slate-200/60 dark:bg-darkmode-400 bg-white/[0.08]"></div>
                    <Link
                        to="/change-password"
                        className="cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md hover:bg-slate-200/60 dark:bg-darkmode-600 dark:hover:bg-darkmode-400 dropdown-item hover:bg-white/5"
                    >
                        <Settings color="#ffffff" className="stroke-1.5 mr-2 h-4 w-4" />
                        Change Password
                    </Link>
                    <Link
                        to="/profile"
                        className="cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md hover:bg-slate-200/60 dark:bg-darkmode-600 dark:hover:bg-darkmode-400 dropdown-item hover:bg-white/5"
                    >
                        <CircleUserRound  color="#ffffff" className="stroke-1.5 mr-2 h-4 w-4" />
                        Profile
                    </Link>
                    <Link
                        onClick={handleLogout}
                        className="cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md hover:bg-slate-200/60 dark:bg-darkmode-600 dark:hover:bg-darkmode-400 dropdown-item hover:bg-white/5"
                    >
                        <LogOut size={20} color="#ffffff" className="stroke-1.5 mr-2 h-4 w-4" />
                        Logout
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Dropdown;