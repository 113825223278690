import React, {useState, useEffect, useContext} from 'react'
import {X} from "lucide-react";
import Select, { components } from 'react-select';
import { Link } from 'react-router-dom';
import axios from 'axios';
import {notifyError, notifySuccess} from '../../helpers';
import { DataContext } from '../../context/DataContext';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const ModelComponenet = ({ show,closeModal,mode}) => {

    // Custom Option component to display detailed option labels in the dropdown
    const CustomOption = (props) => (
        <components.Option {...props}>
            <div>
                <div className="font-bold">{props.data.label}</div>
                <div className="text-sm text-gray-500">{props.data.email}</div>
                <div className="text-sm text-gray-500">{props.data.phone}</div>
            </div>
        </components.Option>
    );

    const CustomSingleValue = (props) => {
        return (
            <components.SingleValue {...props}>
                <div>
                    <div className="font-bold">{props.data.label}</div>
                    <div className="text-sm text-gray-500">{props.data.email}</div>
                    <div className="text-sm text-gray-500">{props.data.phone}</div>
                </div>
            </components.SingleValue>
        );
    };

    const [selectedOption, setSelectedOption] = useState(null);
    const [employeeData, setEmployeeData] = useState(null);
    const ids = useContext(DataContext);

    const handleChange = (selectedOption) => {
        setSelectedOption(selectedOption);
    };

    useEffect(() => {
        fetchEmployeeData();
    },[]);

    useEffect(() => {
        
        
    
    }, []);

    const fetchEmployeeData = async() =>{
        
        try {
            const response = await axios.get('/employee/list-employee');
            if (response.data.success === true) {
                var employeeDetails = response.data.data;

                var transformedEmployeeData = employeeDetails.map(emp =>({
                    value : emp.id,
                    label : emp.first_name+' '+ emp.last_name,
                    email : emp.email,
                    phone : emp.contact
                }));
                setEmployeeData(transformedEmployeeData);
            }

        } catch (err) {
            notifyError(err.message);
        }
        
    }

    const showSwal = () => {
        withReactContent(Swal).fire({
            title: "Are you sure",
            text: "You want to Proceed?",
            icon: "warning",
            showCancelButton: true,
            customClass: {
                popup: 'swal-popup', // Apply your custom styling class
            },
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Yes",
        }).then((result) => {
            if (result.isConfirmed) {
                assignEmployee()
            } 
        });
    }

    
    const assignEmployee = () => {
        var asset = ids;

        const payload = { 
            'emp_id' : selectedOption.value,
            'asset_id' : asset
        };
    
        axios({
            method: 'PUT',
            url: '/asset/assign-asset',
            data: payload, 
        })
        .then(function(response) {
            if(response.data.success === true){
                notifySuccess('Assets Assigned Successfully');
                closeModal();
                window.location.reload(); 
            }
        })
        .catch(function (error){
            notifyError(error.message);
        });     
    }


    return (
        
        <div aria-hidden="false" className={`modal group bg-black/60 transition-[visibility,opacity] w-screen h-screen fixed left-0 top-0 ${ show ? 'visible opacity-100 duration-[0s,0.4s]' : 'invisible opacity-0 delay-[0.2s,0s]'} overflow-y-auto`} style={{ paddingLeft: '0px', marginTop: '0px', marginLeft: '0px', zIndex: '10000' }}>
            <div className="mt-16 w-[90%] mx-auto bg-white relative rounded-md shadow-md transition-[margin-top,transform] duration-[0.4s,0.3s] -mt-16 group-[.show]:mt-16 group-[.modal-static]:scale-[1.05] dark:bg-darkmode-600 sm:w-[600px] lg:w-[600px]  text-center ease-in-out">

                <div className="flex flex-col items-center p-5 border-b sm:flex-row border-slate-200/60 dark:border-darkmode-400">
                    <h2 className="mr-auto text-base font-medium">Assign Asset to Employee</h2>
                    <div className="flex items-center w-full mt-3 sm:w-auto sm:ml-auto sm:mt-0">
                        <button onClick={closeModal}>
                        <X size={30} color="#475569" strokeWidth={1.5} />
                        </button>
                    </div>
                </div>            

                <div className='mt-5 grid grid-cols-12 gap-6'>

                        <div className='col-span-12 lg:col-span-12'>
                            <div className="preview relative [&.hide]:overflow-hidden [&.hide]:h-0">
                                <div data-tw-merge="" className="block sm:flex items-center group form-inline">
                                    <label data-tw-merge="" htmlFor="horizontal-form-1" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm: sm:w-52 items-center p-2">
                                        Select Employee
                                    </label>
                                    
                                        <Select
                                            id="actorSelect"
                                            value={selectedOption}
                                            onChange={handleChange}
                                            options={employeeData}
                                            components={{ Option: CustomOption, SingleValue: CustomSingleValue }}
                                            placeholder="Select or Search an Employee"
                                            classNamePrefix="react-select"
                                            isSearchable
                                            menuPosition="fixed"
                                            className='w-full text-left mr-5'
                                        />
                                </div>
                                <br/>
                                <br />
                                <div className="mt-5 text-right m-5">
                                    <Link onClick={closeModal} type="button" className="transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&amp;:hover:not(:disabled)]:bg-opacity-90 [&amp;:hover:not(:disabled)]:border-opacity-90 [&amp;:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed border-secondary text-slate-500 dark:border-darkmode-100/40 dark:text-slate-300 [&amp;:hover:not(:disabled)]:bg-secondary/20 [&amp;:hover:not(:disabled)]:dark:bg-darkmode-100/10 mr-1 w-24 mr-5">Cancel</Link>
                                    <Link onClick={() => showSwal()} className="transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&amp;:hover:not(:disabled)]:bg-opacity-90 [&amp;:hover:not(:disabled)]:border-opacity-90 [&amp;:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-primary border-primary text-white dark:border-primary w-24">
                                    Assign</Link>
                                </div>
                                
                            </div>
                        </div>
                </div>
            </div>
        </div>
    )
}

export default ModelComponenet
