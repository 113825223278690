// DoughnutChart.js
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';

import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const DoughnutChart = ({ graphData, type }) => {

// const graphData = {
//     active: 100,
//     inactive: 230,
//     repair: 20,
//     unassigned: 110,
// };

const valuesArray = Object.values(graphData);
const keyArray = Object.keys(graphData);

const data = {
    labels: keyArray,
    datasets: [
        {
            label: type,
            data: Object.values(graphData),
            backgroundColor: [
                'rgba(30, 59, 138,1)',
                'rgba(152,186,213,1)',
                'rgba(198,211,227,1)',
                'rgba(48,70,116,1)',
                'rgba(178,203,222,1)',
                'rgba(216,225,232,1)',
            ],
            borderColor: [
                'rgba(30, 59, 138, 1)',
                'rgba(152,186,213,1)',
                'rgba(198,211,227,1)',
                'rgba(48,70,116, 1)',
                'rgba(178,203,222, 1)',
                'rgba(216,225,232, 1)',
            ],
            borderWidth: 1,
        },
    ],
};

const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: type,
        },
    },
};


    return (
        <div>
            <Doughnut data={data} options={options}  />
        </div>
    );
};

export default DoughnutChart;
