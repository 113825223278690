import React, { Fragment, useEffect, useRef } from 'react';
import TomSelect from 'tom-select';
import 'tom-select/dist/css/tom-select.default.css';
import '../../assets/css/tom-select.css';

const MultiSelectDropdown = ({ options, placeholder, onChange, selectedValues }) => {
    const selectRef = useRef(null);
    const tomSelectRef = useRef(null);

    useEffect(() => {
        if (selectRef.current) {
            // Initialize TomSelect instance
            tomSelectRef.current = new TomSelect(selectRef.current, {
                plugins: ['remove_button'],
                create: false,
                placeholder: placeholder,
                sortField: {
                    field: 'text',
                    direction: 'asc',
                },
                onChange: (values) => {
                    onChange(values);
                },
            });

            // Set initial selected values if provided
            if (selectedValues && selectedValues.length > 0) {
                tomSelectRef.current.setValue(selectedValues);
            }
        }

        // Cleanup TomSelect instance on component unmount
        return () => {
            if (tomSelectRef.current) {
                tomSelectRef.current.destroy();
                tomSelectRef.current = null;
            }
        };
    }, [placeholder, onChange]);

    useEffect(() => {
        // Update selected values when selectedValues prop changes
        if (tomSelectRef.current) {
            tomSelectRef.current.addOption(options); // Ensure options are updated
            if (selectedValues && selectedValues.length > 0) {
                tomSelectRef.current.setValue(selectedValues);
            }
        }
    }, [options, selectedValues]);

    return (
        <Fragment>
            {options && (
                <select
                    ref={selectRef}
                    multiple
                    className="tom-select w-full tomselected border-slate-200 rounded-md"
                >
                    {options.map((option, index) => (
                        <option key={index} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
            )}
        </Fragment>
    );
};

export default MultiSelectDropdown;
