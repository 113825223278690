import React, {useEffect, useState} from 'react'
import logo from '../../assets/images/logo.svg'
import illustartionImage from '../../assets/images/illustration.svg'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import axios from 'axios';
import { notifyError,notifySuccess } from '../../helpers';
import {Link , useParams, useNavigate} from 'react-router-dom';

const ResetPassword = () => {

    const navigate = useNavigate();
    // form validation rules 
    const validationSchema = Yup.object().shape({
        new_password: Yup.string()
            .required('New Password field is required')
            .min(8, 'Password must be at least 8 characters long')
            .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
            .matches(/\d/, 'Password must contain at least one digit')
            .matches(/[@$!%*?&]/, 'Password must contain at least one symbol'),
        confirm_new_password: Yup.string().oneOf([Yup.ref('new_password'), null], 'Confirm password must match with new password field').required('Confirm new Password field is required'),
    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    const { token } = useParams();

    // get functions to build form with useForm() hook
    const { register, handleSubmit, formState, setError } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    const onSubmit = async (data) => {
        const payload = { ...data };
        payload.token = token
        
        await axios({
            method: 'post',
            url: '/auth/reset-password',
            data: payload,  
        })
        .then(function(response) {
            if(response.data.success === true){
                notifySuccess('Password reset successfully');

                navigate('/login')
                
            }else{
                notifyError(response.data.message);
            }
        })
        .catch(function (error){
            error.response.data.error.map(err =>{
                setError(err['field'], {
                    type: err['field'],
                    message:err['message']
                });
            })
            notifyError(error.message);
        });      
    }

	return (
		<div className="p-3 sm:px-8 relative h-screen lg:overflow-hidden bg-primary xl:bg-white dark:bg-darkmode-800 xl:dark:bg-darkmode-600 before:hidden before:xl:block before:content-[''] before:w-[57%] before:-mt-[28%] before:-mb-[16%] before:-ml-[13%] before:absolute before:inset-y-0 beforPe:left-0 before:transform before:rotate-[-4.5deg] before:bg-primary/20 before:rounded-[100%] before:dark:bg-darkmode-400 after:hidden after:xl:block after:content-[''] after:w-[57%] after:-mt-[20%] after:-mb-[13%] after:-ml-[13%] after:absolute after:inset-y-0 after:left-0 after:transform after:rotate-[-4.5deg] after:bg-primary after:rounded-[100%] after:dark:bg-darkmode-700">
			<div className="container relative z-10 sm:px-10">
				<div className="block grid-cols-2 gap-4 xl:grid">
					
					<div className="hidden min-h-screen flex-col xl:flex">
						<div className="-intro-x flex items-center pt-5">
						<img className="w-6" src={logo} alt="Smart Locker" />
						<span className="ml-3 text-lg text-white"> Smart Locker </span>
						</div>
						<div className="my-auto">
							<img className="-intro-x -mt-16 w-1/2" src={illustartionImage} alt="" />
							<div className="-intro-x mt-10 text-4xl font-medium leading-tight text-white">
								A few more clicks to <br />
								sign in to your account.
							</div>
							
						</div>
					</div>
					
					<div className="my-10 flex h-screen py-5 xl:my-0 xl:h-auto xl:py-0">

                        
                        <div className="mx-auto my-auto w-full rounded-md bg-white px-5 py-8 shadow-md dark:bg-darkmode-600 sm:w-3/4 sm:px-8 lg:w-2/4 xl:ml-20 xl:w-auto xl:bg-transparent xl:p-0 xl:shadow-none">
                            <h2 className="intro-x text-center text-2xl font-bold xl:text-left xl:text-3xl">
                                Reset Password
                            </h2><br />
                            
                            <p>The password should have 8 characters</p>
                            
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <br />

                                <div className="mt-3">
                                    <div>
                                        <label className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right">
                                            New Password
                                        </label>
                                        <input id="change-password-form-2" type="password" {...register('new_password')} placeholder="Enter Password" className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&amp;:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10" />
                                    </div>
                                    <div className="text-danger text-right mt-2">{errors.new_password?.message}</div>
                                </div>

                                <div className="mt-3">
                                    <div>
                                        <label className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right">
                                            Confirm New Password
                                        </label>
                                        <input id="change-password-form-3" type="password" {...register('confirm_new_password')} placeholder="Enter Password" className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&amp;:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10" />
                                    </div>
                                    <div className="text-danger text-right mt-2">{errors.confirm_new_password?.message}</div>
                                </div>
                                        
                                <div className="intro-x mt-5 text-center xl:mt-8 xl:text-left">
                                    <button type='submit' disabled={isSubmitting} data-tw-merge="" className="transition duration-200 border shadow-sm inline-flex items-center justify-center rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-primary border-primary text-white dark:border-primary w-full px-4 py-3 align-top xl:mr-3">
                                        {isSubmitting && <span className="spinner-border spinner-border-sm me-1"></span>}
                                        Reset Password
                                    </button>
                                </div>

                                <div className='mt-5 text-center'>
                                    <Link to="/login">Back to Login</Link>
                                </div>
                            </form>
                        </div>
                        

                        
					</div>

                    
	
				</div>
			</div>
		</div>
	)
}

export default ResetPassword
