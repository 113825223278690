import React, { useState, useRef, useEffect } from 'react';
import { usePopper } from 'react-popper';
import { createPortal } from 'react-dom';
import axios from 'axios';
import { SquareCheckBig, Ellipsis, Eye, Download, FileLock2, Cpu} from 'lucide-react';
import { Link } from 'react-router-dom';

const ActionDropdown = ({terminal,isAccess,handleDownloadCertificate,openModal,showSwal}) => {
    const [show, setShow] = useState(false);
    const [dropdownElement, setDropdownElement] = useState(null);
    const buttonRef = useRef(null);
    const { styles, attributes } = usePopper(buttonRef.current, dropdownElement, {
        placement: 'bottom-end',
    });
    
    const handleToggle = () => {
        setShow(!show);
    };
    
    const handleDocumentClick = (e) => {
        if (buttonRef.current && buttonRef.current.contains(e.target)) {
            return;
        }
        if (dropdownElement && dropdownElement.contains(e.target)) {
            return;
        }
        setShow(false);
    };

    useEffect(() => {
        if (show) {
            document.addEventListener('click', handleDocumentClick);
        } else {
            document.removeEventListener('click', handleDocumentClick);
        }
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, [show]);


    
    return (
        <div data-tw-merge data-tw-placement="bottom-end" className="dropdown relative">
            
            <button
                ref={buttonRef}
                onClick={handleToggle}
                aria-expanded={show}
                className="transition duration-200"
            >
                <span className="flex h-5 w-5 items-center justify-center">
                    <Ellipsis color="#475569" />
                </span>
            </button>
            {show && createPortal(
                <div
                    ref={setDropdownElement}
                    data-transition
                    data-selector=".show"
                    data-enter="transition-all ease-linear duration-150"
                    data-enter-from="absolute !mt-5 invisible opacity-0 translate-y-1"
                    data-enter-to="!mt-1 visible opacity-100 translate-y-0"
                    data-leave="transition-all ease-linear duration-150"
                    data-leave-from="!mt-1 visible opacity-100 translate-y-0"
                    data-leave-to="absolute !mt-5 invisible opacity-0 translate-y-1"
                    className="dropdown-menu absolute z-[9999]"
                    style={styles.popper}
                    {...attributes.popper}
                >
                    <div data-tw-merge="" className="dropdown-content rounded-md border-transparent bg-white p-2 shadow-[0px_3px_10px_#00000017] dark:border-transparent dark:bg-darkmode-600 w-55 action-dropdown">
                        {isAccess.edit && (
                            <Link to={'/smart-device/edit/' + terminal.id} className="cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md hover:bg-slate-200/60 dark:bg-darkmode-600 dark:hover:bg-darkmode-400 dropdown-item">
                                <SquareCheckBig size={15} color="#475569" className='mr-2' /> Edit
                            </Link>
                        )}

                        <Link to={'/smart-device/show/'+ terminal.id } className="cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md hover:bg-slate-200/60 dark:bg-darkmode-600 dark:hover:bg-darkmode-400 dropdown-item">
                            <Eye size={15} color="#475569" className='mr-2' /> Show
                        </Link>


                        <Link onClick={() => openModal(terminal,'show')} className="cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md hover:bg-slate-200/60 dark:bg-darkmode-600 dark:hover:bg-darkmode-400 dropdown-item">
                            <FileLock2 size={15} color="#475569" className='mr-2' /> View Locker
                        </Link>

                        {isAccess.assignTerminal && (
                            <Link onClick={() => openModal(terminal,'assign')} className="cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md hover:bg-slate-200/60 dark:bg-darkmode-600 dark:hover:bg-darkmode-400 dropdown-item">
                                <Cpu size={15} color="#475569" className='mr-2' /> Assign Terminal
                            </Link>
                        )}
                        
                        {isAccess.upgrade && (
                            <Link onClick={() => showSwal(terminal)} className="cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md hover:bg-slate-200/60 dark:bg-darkmode-600 dark:hover:bg-darkmode-400 dropdown-item">
                                <Cpu size={15} color="#475569" className='mr-2' /> Upgrade
                            </Link>
                        )}

                        {isAccess.downloadCertificate && (
                            <Link onClick={() => handleDownloadCertificate(terminal)}  className="cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md hover:bg-slate-200/60 dark:bg-darkmode-600 dark:hover:bg-darkmode-400 dropdown-item">
                                <Download size={15} color="#475569" className='mr-2' /> Download Certificate
                            </Link>
                        )} 
                    </div>
                </div>,
                document.body
            )}
            
        </div>
    );
};

export default ActionDropdown;
