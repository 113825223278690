import React from 'react'
import {Bar} from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const HorizontalBarChart = ({ graphData, type }) => {

    const valuesArray = Object.values(graphData);
    const keyArray = Object.keys(graphData);

    const data = {
        labels: keyArray,
        datasets: [
            {
                label: type,
                backgroundColor: "#1e3b8a",
                borderColor: "rgb(255, 99, 132)",
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                hoverBorderColor: 'rgba(255,99,132,1)',
                data: valuesArray,
            },
        ],
    };

    const options = {
        indexAxis: 'y', // This makes the bar chart horizontal
        scales: {
            x: {
                beginAtZero: true,
            },
        },
    };


    return (
        <div>
            <Bar data={data}  options={options} />
        </div>
    );
}

export default HorizontalBarChart
