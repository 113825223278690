import React, { useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import { format, startOfMonth, endOfMonth } from 'date-fns';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {CalendarRange} from 'lucide-react';

const DateRangePickerComponent = ({ onDateRangeChange }) => {
    

    const initialRange = {
        startDate: startOfMonth(new Date()),
        endDate: endOfMonth(new Date()),
        key: 'selection'
    };

    const [state, setState] = useState([initialRange]);
    const [isPickerVisible, setPickerVisible] = useState(false);

    const handleSelect = (ranges) => {
        if (ranges.selection) {
            setState([ranges.selection]);
        }
    };

    const handleApply = () => {
        if (state) {
            onDateRangeChange(state);
            setPickerVisible(false);
        }
    }

    const handleReset = () => {
        setState([initialRange]); // Reset to initial range
        onDateRangeChange([initialRange]); // Optionally notify the parent component of the reset
        setPickerVisible(false);
    };

    const startDate = state[0]?.startDate ? format(state[0].startDate, 'yyyy-MM-dd') : 'Start Date';
    const endDate = state[0]?.endDate ? format(state[0].endDate, 'yyyy-MM-dd') : 'End Date';

    const buttonText = state[0]?.startDate && state[0]?.endDate 
        ? `${startDate} to ${endDate}`
        : 'Select date range';

    return (
        <div className="date-range-picker-wrapper">
            <button onClick={() => setPickerVisible(!isPickerVisible)} 
                className="transition duration-200 border inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed text-primary border-primary text-white dark:border-primary mr-2 shadow-md">
                
                <CalendarRange className="stroke-1.5 w-5 h-5 mr-2" /> {buttonText}
            </button>
            {isPickerVisible && (
                <div className="date-range-picker">
                    
                    <DateRangePicker
                        onChange={handleSelect}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={1}
                        ranges={state}
                        direction="vertical"
                        scroll={{ enabled: true }}
                        rangeColors={['#1e3a8a']}
                        retainEndDateOnClick={false}
                    />
                    <div className='flex text-right mt-2'> 
                        <button onClick={() => handleApply()} className="transition duration-200 border shadow-sm inline-flex items-center justify-center rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed text-xs py-1.5 px-2 bg-primary border-primary text-white dark:border-primary w-24 mb-2 mr-2">
                            Apply
                        </button>
                        <button onClick={handleReset} className="transition duration-200 border shadow-sm inline-flex items-center justify-center rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed text-xs py-1.5 px-2 bg-secondary/70 border-secondary/70 text-slate-500 dark:border-darkmode-400 dark:bg-darkmode-400 dark:text-slate-300 [&:hover:not(:disabled)]:bg-slate-100 [&:hover:not(:disabled)]:border-slate-100 [&:hover:not(:disabled)]:dark:border-darkmode-300/80 [&:hover:not(:disabled)]:dark:bg-darkmode-300/80 w-24 mb-2 mr-2">
                            Reset
                        </button>
                        <button onClick={() => setPickerVisible(false)} className="transition duration-200 border shadow-sm inline-flex items-center justify-center rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed text-xs py-1.5 px-2 bg-secondary/70 border-secondary/70 text-slate-500 dark:border-darkmode-400 dark:bg-darkmode-400 dark:text-slate-300 [&:hover:not(:disabled)]:bg-slate-100 [&:hover:not(:disabled)]:border-slate-100 [&:hover:not(:disabled)]:dark:border-darkmode-300/80 [&:hover:not(:disabled)]:dark:bg-darkmode-300/80 w-24 mb-2 mr-2">
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DateRangePickerComponent;