import { useSelector } from 'react-redux';
import { selectUserRole } from '../store';
import { permissions } from '../utils/Permission';

/**
 * Custom hook to check if the user has a specific permission.
 * @param {string} resource - The resource to check.
 * @param {string} action - The action to check.
 * @returns {boolean} - True if the user has the permission, false otherwise.
 */
export const usePermission = (resource, action) => {
  const role = useSelector(selectUserRole);

  // Assuming permissions is defined as an object mapping resources to roles and allowed actions
  const allowedAction = permissions[resource]?.[role]?.allowedAction;
  
  return allowedAction ? allowedAction.includes(action) : false;
};