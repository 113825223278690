import React, { useState } from 'react'
import { Menu } from 'lucide-react';
import logoImage from '../../assets/images/logo.svg';
import { Link } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import { usePermission } from '../../hooks/usePermission';
import { Home, SquareTerminal, Building, Users, UserCog, CassetteTape,Cpu, CircleX } from 'lucide-react';
// import 'simplebar-react/dist/simplebar.min.css';

function MobileMenu() {

    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <div className={`${menuOpen ? 'mobile-menu--active' : ''} mobile-menu group top-0 inset-x-0 fixed bg-theme-1/90 z-[60] border-b border-white/[0.08] dark:bg-darkmode-800/90 md:hidden before:content-[''] before:w-full before:h-screen before:z-10 before:fixed before:inset-x-0 before:bg-black/90 before:transition-opacity before:duration-200 before:ease-in-out before:invisible before:opacity-0 [&.mobile-menu--active]:before:visible [&.mobile-menu--active]:before:opacity-100`}>
            
            <div className="h-[70px] px-3 sm:px-8 flex items-center">
                <Link to={'/'} className="mr-auto flex">
                    <img className="w-6" src={logoImage} alt="Midone - Tailwind Admin Dashboard Template" />
                </Link>

                {/* <Link to={'/'} className="mobile-menu-toggler">

                    <Menu color="#ffffff" />
                </Link> */}

            <button onClick={toggleMenu} className="mobile-menu-toggler">
                <Menu color="#ffffff" />
            </button>

            </div>

            <SimpleBar className={`h-screen z-20 top-0 left-0 w-[270px] ${menuOpen ? 'ml-0 [&[data-simplebar]]:fixed [&_.simplebar-scrollbar]:before:bg-black/50 group-[.mobile-menu--active]:ml-0 simplebar-scrollable-y' : '-ml-[100%]'} bg-primary transition-all duration-300 ease-in-out dark:bg-darkmode-800 [&[data-simplebar]]:fixed [&_.simplebar-scrollbar]:before:bg-black/50 group-[.mobile-menu--active]:ml-0 simplebar-scrollable-y`}>
                
            <button href="#" onClick={toggleMenu} className="fixed top-0 right-0 mt-4 mr-4 transition-opacity duration-200 ease-in-out invisible opacity-0 group-[.mobile-menu--active]:visible group-[.mobile-menu--active]:opacity-100">
                <CircleX color="#ffffff" size={35}/>
            </button>
                <ul className="py-2">

                    <li>
                        <Link to={'/'} className="menu menu--active">
                            <div className="menu__icon">
                                <Home size={20} />
                            </div>
                            <div className="menu__title">
                                Home
                                <div className="menu__sub-icon transform rotate-180">
                                    <i data-tw-merge="" data-lucide="chevron-down" className="stroke-1.5 w-5 h-5"></i>
                                </div>
                            </div>
                        </Link>
                    </li>
                    
                    {usePermission('smartDevices', 'list') && (
                        <li>
                            <Link to='smart-device' className="menu">
                                <div className="menu__icon">
                                    <SquareTerminal size={20} />
                                </div>
                                <div className="menu__title">
                                    Terminal
                                </div>
                            </Link>
                        </li>
                    )}

                    {usePermission('organization', 'list') && (
                        <li>
                            <Link to={'accounts'} className="menu">
                                <div className="menu__icon">
                                    <Building size={20} />
                                </div>
                                <div className="menu__title">
                                    Organisation
                                </div>
                            </Link>
                        </li>
                    )}

                    {usePermission('subOrganization', 'list') && (
                        <li>
                            <Link to={'sub-accounts'} className="menu">
                                <div className="menu__icon">
                                    <Building size={20} />
                                </div>
                                <div className="menu__title">
                                Sub-Accounts
                                </div>
                            </Link>
                        </li>
                    )}

                    {usePermission('ose', 'list') && (
                        <li>
                            <Link to={'onsite-engineer'} className="menu">
                                <div className="menu__icon">
                                    <UserCog size={20} />
                                </div>
                                <div className="menu__title">
                                    Onsite Engineers
                                </div>
                            </Link>
                        </li>
                    )}

                    {usePermission('employee', 'list') && (
                        <li>
                            <Link to={'onsite-engineer'} className="menu">
                                <div className="menu__icon">
                                    <Users size={20} />
                                </div>
                                <div className="menu__title">
                                    Users
                                </div>
                            </Link>
                        </li>
                    )}

                    {usePermission('assets', 'list') && (
                        <li>
                            <Link to={'inventory'} className="menu">
                                <div className="menu__icon">
                                    <CassetteTape size={20} />
                                </div>
                                <div className="menu__title">
                                    Inventory
                                </div>
                            </Link>
                        </li>
                    )}

                    {usePermission('ota', 'list') && (
                        <li>
                            <Link to={'ota'} className="menu">
                                <div className="menu__icon">
                                    <Cpu size={20} />
                                </div>
                                <div className="menu__title">
                                    OTA Firmware
                                </div>
                            </Link>
                        </li>
                    )}
                    

                </ul>
            </SimpleBar>
        </div>
    )
}

export default MobileMenu

